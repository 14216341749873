import React, { useMemo } from 'react';
import { Badge } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

import { Button } from 'common/materials';
import SupportModal from 'common/components/TopNavigation/SupportModal';

import { SearchCriteria, Tabs, TicketCard, TicketDetailModal, TicketTable } from './components';
import useTicketViewModel from './Ticket.viewmodel';

export const TABS = [
    {
        label: 'All',
        value: 'all',
        color: 'bg-primary-50 text-primary-600'
    },
    {
        label: 'Processing',
        value: 'กำลังดำเนินการ',
        color: 'bg-blue-50 text-blue-600'
    },
    {
        label: 'Cancel',
        value: 'ถูกยกเลิก',
        color: 'bg-red-50 text-red-600'
    },
    {
        label: 'Finish',
        value: 'ปิดงานแล้ว',
        color: 'bg-green-50 text-green-600'
    }
] as const;

function TicketView() {
    const {
        email,
        filter,
        dateRange,
        displayStyle,
        tasks = [],
        filteredTasks,
        search,
        showSupportModal,
        selectedTask,
        selectedTaskDetail,
        showTaskDetailModal,
        setFilter,
        setDateRange,
        setSearch,
        setDisplayStyle,
        handleClickTicketDetail,
        handleCloseTicketDetailModal,
        handleClickCloseTask,
        handleClickCancelTask,
        setShowSupportModal,
        onClickReply
    } = useTicketViewModel();

    const statusCounts = useMemo(
        () =>
            tasks.reduce((accumulate, task) => {
                return accumulate.set(
                    task.activityOverviewStatus,
                    (accumulate.get(task.activityOverviewStatus) ?? 0) + 1
                );
            }, new Map<string, number>()),
        [tasks]
    );

    const tabs = useMemo(
        () =>
            [
                {
                    label: `All (${tasks.length})`,
                    value: 'all'
                },
                {
                    label: `Processing (${statusCounts.get('กำลังดำเนินการ') ?? 0})`,
                    value: 'กำลังดำเนินการ'
                },
                {
                    label: `Cancel (${statusCounts.get('ถูกยกเลิก') ?? 0})`,
                    value: 'ถูกยกเลิก'
                },
                {
                    label: `Finish (${statusCounts.get('ปิดงานแล้ว') ?? 0})`,
                    value: 'ปิดงานแล้ว'
                }
            ] as const,
        [tasks, statusCounts]
    );

    return (
        <React.Fragment>
            <div className="flex flex-1 flex-col gap-4">
                <div className="flex">
                    <p className="text-2xl font-bold">TASK TICKET</p>
                    <Button
                        className="ml-auto flex flex-row items-center gap-2 rounded-[6px] bg-primary-900 px-4 text-white"
                        onClick={setShowSupportModal.on}
                    >
                        <FaPlus size={10} />
                        <span className="text-sm">New Ticket</span>
                    </Button>
                </div>
                <div className="flex items-center justify-between">
                    <Badge
                        variant="subtle"
                        colorScheme="primary"
                        className="w-fit rounded-md px-2 py-1 text-xs"
                    >
                        {email}
                    </Badge>
                </div>

                <Tabs
                    tabs={tabs}
                    value={filter}
                    onClick={setFilter}
                />

                <SearchCriteria
                    dateRange={dateRange}
                    keyword={search}
                    viewMode={displayStyle}
                    onDateRangeChange={setDateRange}
                    onKeywordChange={setSearch}
                    onViewModeChange={setDisplayStyle}
                    className="border-b pb-5"
                />

                {displayStyle === 'grid' ? (
                    <div className="grid grid-cols-1 gap-4 overflow-hidden sm:grid-cols-2 2xl:grid-cols-3">
                        {filteredTasks && filteredTasks.length > 0 ? (
                            filteredTasks.map((ticket, index) => (
                                <TicketCard
                                    key={index}
                                    ticket={ticket}
                                    onClickTicketDetail={handleClickTicketDetail}
                                />
                            ))
                        ) : (
                            <div className="col-span-2 text-center">No ticket...</div>
                        )}
                    </div>
                ) : (
                    <TicketTable
                        tasks={filteredTasks}
                        onClickTicketDetail={handleClickTicketDetail}
                    />
                )}
            </div>

            {/* Create ticket modal */}
            <SupportModal
                header="Create Ticket"
                isOpen={showSupportModal}
                onClose={setShowSupportModal.off}
            />

            <TicketDetailModal
                isOpen={showTaskDetailModal}
                task={selectedTask}
                taskActivity={selectedTaskDetail}
                onClose={handleCloseTicketDetailModal}
                onClickReply={onClickReply}
                onClickCloseTask={handleClickCloseTask}
                onClickCancelTask={handleClickCancelTask}
            />
        </React.Fragment>
    );
}

export default TicketView;
