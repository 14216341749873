import * as React from 'react';
import { Row, Col, Button, Table,Spinner } from 'reactstrap';

import * as UserCompanyModel from '../../models/user/UserCompany'
import * as UserCompanyStore from '../../store/user/UserCompany'

import * as UserCompanyMasterModel from '../../models/user/UserCompanyMaster'
import * as UserCompanyMasterStore from '../../store/user/UserCompanyMaster'

import Swal from 'sweetalert2'
import colors from '../asset/default/Colors'

import { connect } from "react-redux";
import { ApplicationState } from '../../store';
import { parseJwt } from '../../modules/common';

type store = UserCompanyModel.AddUserCompanyState & typeof UserCompanyStore.actionCreators
type storeCompanyMaster = UserCompanyMasterModel.UserCompanyMasterState & typeof UserCompanyMasterStore.actionCreators

class UserDashboardComponentAdmin extends React.Component<any, { isGetUserCompanyMaster: boolean, userId: string }, store & storeCompanyMaster> {
    constructor(props: any) {
        super(props);

        this.state = {
            isGetUserCompanyMaster: true,
            userId: ""
        }
    }

    componentDidMount() {
        const { isGetUserCompanyMaster } = this.state
        var userId = "";
        var ls: any = localStorage.getItem("SSO_AUTH");
        if (ls) {
            var jls: any = JSON.parse(ls)
            var accessToken = jls.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);

            if (extra.user_id) {
                userId = extra.user_id
            } else if (extra.username) {
                userId = extra.username
            } else if (extra.citizen_id) {
                userId = extra.citizen_id
            }

            this.setState({ userId: userId })
        }

        if (isGetUserCompanyMaster) {
            this.props.requestGetUserCompanyMaster(isGetUserCompanyMaster, 'GET', userId);
            this.setState({
                isGetUserCompanyMaster: false
            })
        }
    }

    componentDidUpdate(nextProps, nextState) {
        const { AddUserCompany, EditUserCompany } = this.props

        if (AddUserCompany.isLoadAddUserCompany && AddUserCompany.statusAddUserCompany == 200) {
            Swal.fire({
                title: 'Success!',
                icon: 'success',
                text: 'รอการอนุมัติ',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddUserCompany(true, 'CLEAR', '', '')
                    this.refreshData()
                }
            })
        } else if (AddUserCompany.isLoadAddUserCompany && AddUserCompany.statusAddUserCompany != 200) {
            Swal.fire({
                title: 'Error!',
                text: AddUserCompany.messageAddUserCompany,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestAddUserCompany(true, 'CLEAR', '', '')
                    this.refreshData()
                }
            })
        }

        if (EditUserCompany.isLoadEditUserCompany && EditUserCompany.statusEditUserCompany == 200) {
            Swal.fire({
                title: 'Success!',
                icon: 'success',
                text: 'รอการอนุมัติ',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEditUserCompany(true, 'CLEAR', '', '')
                    this.refreshData()
                }
            })
        } else if (EditUserCompany.isLoadEditUserCompany && EditUserCompany.statusEditUserCompany != 200) {
            Swal.fire({
                title: 'Error!',
                text: EditUserCompany.messageEditUserCompany,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then((result) => {
                if (result.value) {
                    this.props.requestEditUserCompany(true, 'CLEAR', '', '')
                    this.refreshData()
                }
            })
        }
    }

    requestCompany(sid: string, type: string) {
        Swal.fire({
            title: 'ขอเข้า Company หรือไม่ ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                var data = [{
                    sid: sid,
                    citizen_id: this.state.userId
                }]

                if (type == "rejected") {
                    this.props.requestEditUserCompany(true, 'PUT', sid, this.state.userId, "pending")
                } else {
                    this.props.requestAddUserCompany(true, 'POST', data)
                }
            }
        })
    }

    refreshData() {
        this.setState({
            isGetUserCompanyMaster: true
        })
        this.props.requestGetUserCompanyMaster(true, 'GET', this.state.userId);
    }

    render() {
        const { isGetUserCompanyMaster } = this.state
        const { isLoadGetUserCompanyMaster, statusGetUserCompanyMaster, responseGetUserCompanyMaster } = this.props
        return (
            <div>
                <Row>
                    <Col>
                        <div className='trapezoid-head' style={{ width: '200px' }}>
                            <span>
                                Company Register
                                </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered className="table-hover" size="sm">
                            <thead className="table-active">
                                <tr className="text-center" style={{ width: '100%' }}>
                                    <th style={{ width: '5%' }}></th>
                                    <th style={{ width: '50%' }}>Company Name</th>
                                    <th style={{ width: '10%' }}>Status</th>
                                    <th style={{ width: '10%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!isLoadGetUserCompanyMaster ? <tr><td colSpan={5} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr> : <tr></tr>}
                                {isLoadGetUserCompanyMaster && statusGetUserCompanyMaster != 200 ? <tr><td colSpan={6} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr> : <tr></tr>}
                                {statusGetUserCompanyMaster == 200 && isLoadGetUserCompanyMaster && responseGetUserCompanyMaster['result_list'] != null && responseGetUserCompanyMaster['result_list'].map((item: UserCompanyMasterModel.arrGetUserCompanyMaster, idx: any) => {
                                    return (
                                        <tr key={idx}>
                                            <td className="text-center">{idx + 1}</td>
                                            <td>{item.company_name}</td>
                                            <td className="text-center">
                                                {
                                                    item.status == "pending" &&
                                                    <span style={{ color: colors.statusPending }}>{item.status}</span>
                                                }
                                                {item.status == "active" &&
                                                    <span style={{ color: colors.statusActive }}>{item.status}</span>
                                                }
                                                {item.status == "inactive" &&
                                                    <span style={{ color: colors.statusInactive }}>{item.status}</span>
                                                }
                                                {item.status == "rejected" &&
                                                    <span style={{ color: colors.statusRejected }}>{item.status}</span>
                                                }
                                                {item.status == null &&
                                                    <span>รอการขอเข้า</span>
                                                }
                                            </td>
                                            <td className="text-center">
                                                <Button color={item.status == "active" ? "secondary" : item.status == "inactive" ? "secondary" : item.status == "pending" ? "secondary" : "success"} size="sm" onClick={() => this.requestCompany(item.sid, item.status)} disabled={item.status == "active" ? true : item.status == "pending" ? true : item.status == "inactive" ? true : false}>ขอเข้า</Button>{' '}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        {/*</div>*/}
                    </Col>
                </Row>
            </div>
        );
    }

}

export default connect((state: ApplicationState) => ({ ...state.UserCompany, ...state.UserCompanyMaster, }), ({ ...UserCompanyStore.actionCreators, ...UserCompanyMasterStore.actionCreators }))(UserDashboardComponentAdmin);