import axios from 'axios';

import {
    CompanyApplicationMappingMenuRequest,
    CompanyApplicationMappingMenuResponse
} from '../schemas';

export async function getAll(params: CompanyApplicationMappingMenuRequest) {
    return axios.get<CompanyApplicationMappingMenuResponse>(
        '/v1/company/application_mapping_menu',
        {
            params,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            }
        }
    );
}
