import { useState } from 'react';
import { parseDateToString } from '../../../common/helpers';
import axios from 'axios';
import { CreateTaskResponse } from '../../../models/link/Task';
import { useLoader, useStore } from '../../../common/hooks';
import Swal from 'sweetalert2';

function NewTaskViewModel() {
    const { userCompanyStore } = useStore();
    const loader = useLoader();

    const [model, setModel] = useState<string>('');
    const [filesUpload, setFilesUpload] = useState<File[]>([]);
    const [task, setTask] = useState({
        subject: '',
        detail: '',
        startDate: new Date(),
        endDate: new Date(),
        activityType: 'task'
    });

    const handleChangeTask = <K extends keyof typeof task>(key: K, value: (typeof task)[K]) => {
        setTask(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleChangeFile = (files: FileList | null) => {
        if (!files) {
            return;
        }

        setFilesUpload(prevState => [...prevState, ...Array.from(files)]);
    };

    const handleClickAddTask = async () => {
        try {
            loader.show();

            const [startDateString, startTimeString] = parseDateToString(task.startDate);
            const [endDateString, endTimeString] = parseDateToString(task.endDate);

            const formData = new FormData();
            formData.append('subject', task.subject);
            formData.append('detail', task.detail);
            formData.append('start_date', startDateString);
            formData.append('end_date', endDateString);
            formData.append('start_time', startTimeString);
            formData.append('end_time', endTimeString);
            formData.append('activity_type', task.activityType);

            filesUpload.forEach((file, index) => {
                formData.append(`file-${index + 1}`, file, file.name);
            });

            console.log(formData);

            const data = (
                await axios.post<CreateTaskResponse>('v1/link/task', formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        user_id: userCompanyStore.citizenId
                    }
                })
            ).data;

            if (data.a_object_link) {
                await Swal.fire({
                    title: 'Success!',
                    text: `Create task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).response.data.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
        }
    };

    return {
        task,
        filesUpload,
        model,
        setModel,
        handleChangeTask,
        handleClickAddTask,
        handleChangeFile
    };
}

export default NewTaskViewModel;
