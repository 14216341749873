import { useCallback, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2';

import { extractAccessToken } from 'common/helpers/local-storage';
import { useLoader, useStore } from 'common/hooks';
import { UserInformationResponse } from 'models/citizen/Citizen';
import { isEmailValid } from 'common/helpers';

type AttachFilesType = Record<
    keyof Pick<UserInformationResponse, 'image' | 'image_signature' | 'digital_signature'>,
    File | undefined
>;

function ProfileViewModel() {
    const loader = useLoader();
    const { profileStore } = useStore();
    const { userInformation } = profileStore;

    const [isSCG, setIsSCG] = useState(false);
    const [copyUserInformation, setChangedCopyUserInformation] =
        useState<UserInformationResponse>();

    const [attachFiles, setAttachFiles] = useState<AttachFilesType>({} as AttachFilesType);
    const previewImageBlobUrl = useMemo(
        () => attachFiles.image && URL.createObjectURL(attachFiles.image),
        [attachFiles.image]
    );
    const previewImageSignatureBlobUrl = useMemo(
        () => attachFiles.image_signature && URL.createObjectURL(attachFiles.image_signature),
        [attachFiles.image_signature]
    );

    useEffect(() => {
        fetchData();
    }, []);

    const fetchIsSCG = async () => {
        const data = (await axios.get('/v1/link-sid')).data;
        setIsSCG(data.link_sid === 'SCG');
    };

    const fetchCitizen = async () => {
        try {
            loader.show();

            console.log(
                'citizen ID/username',
                extractAccessToken().citizen_id,
                extractAccessToken().username
            );

            const userInformationResponse = await profileStore.fetchCitizen();

            setChangedCopyUserInformation({ ...userInformationResponse });
        } catch (error) {
            if (error instanceof AxiosError) {
                Swal.fire('Error', error.response?.data.message, 'error');
            }
        } finally {
            loader.hide();
        }
    };

    const fetchData = async () => {
        await Promise.allSettled([fetchIsSCG(), fetchCitizen()]);
    };

    const handleRemoveAttachFile = useCallback(
        (key: keyof AttachFilesType) => {
            setAttachFiles(prevState => ({
                ...prevState,
                [key]: new File([], 'empty-image-profile')
            }));
        },
        [setAttachFiles]
    );

    /** For input element */
    const handleChangeAttachFile = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();

            const files = event.target.files;
            if (!files || files.length === 0) {
                return;
            }

            const key = event.target.name;
            setAttachFiles(prevState => ({
                ...prevState,
                [key]: files[0]
            }));
        },
        [setAttachFiles]
    );

    const handleAttachFile = useCallback(
        (key: keyof AttachFilesType, file: File) => {
            setAttachFiles(prevState => ({
                ...prevState,
                [key]: file
            }));
        },
        [setAttachFiles]
    );

    const handleChangeCopyUserInformation = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.persist();

            const key = event.target.name;
            setChangedCopyUserInformation(prevState => {
                if (!prevState || !(key in prevState)) {
                    return prevState;
                }

                return {
                    ...prevState,
                    [key]: event.target.value
                };
            });
        },
        [copyUserInformation]
    );

    const handleChangeOtpOrNotificationType = useCallback(
        (type: 'otp' | 'notify', value: 'email' | 'line') => {
            setChangedCopyUserInformation(prevState => {
                if (!prevState || !prevState.setting_list) {
                    return prevState;
                }

                const nextState = { ...prevState };
                const setting = nextState.setting_list!.find(setting => setting.key === type);
                if (!setting) {
                    nextState.setting_list!.push({ group: '*', key: type, value });
                } else {
                    setting.value = value;
                }

                return nextState;
            });
        },
        [copyUserInformation]
    );

    const handleSaveChange = useCallback(async () => {
        try {
            loader.show();

            if (!copyUserInformation) {
                throw new Error('เกิดข้อผิดพลาด');
            }

            if (!isEmailValid(copyUserInformation.email ?? '')) {
                throw new Error('รูปแบบอีเมล์ไม่ถูกต้อง');
            }

            const formData = new FormData();
            const userInformationEntries = Object.entries(copyUserInformation) as [
                keyof UserInformationResponse,
                string | null
            ][];

            // Append common information
            for (const [key, value] of userInformationEntries) {
                // Skip, if value is null or empty string
                if (!value) {
                    continue;
                }

                formData.append(key, value);
            }

            formData.delete('setting_list');
            copyUserInformation.setting_list?.forEach((setting, index) => {
                formData.append(`setting_list[${index}].group`, setting.group ?? '');
                formData.append(`setting_list[${index}].key`, setting.key ?? '');
                formData.append(`setting_list[${index}].value`, setting.value ?? '');
            });

            // Append attach files
            for (const key of ['image', 'image_signature', 'digital_signature'] as Array<
                keyof AttachFilesType
            >) {
                const file = attachFiles[key];
                if (file) {
                    formData.append(key, file, file.name);
                }
            }

            await axios.post('/v1/citizen', formData, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            });

            await Swal.fire('Success', 'บันทึกสำเร็จ', 'success');
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Error', error.message, 'error');
            }
        } finally {
            await fetchCitizen();
            loader.hide();
        }
    }, [copyUserInformation, attachFiles, fetchCitizen]);

    return {
        isSCG,
        previewImageBlobUrl,
        previewImageSignatureBlobUrl,
        userInformation,
        copyUserInformation,
        handleRemoveAttachFile,
        handleChangeCopyUserInformation,
        handleChangeOtpOrNotificationType,
        handleChangeAttachFile,
        handleAttachFile,
        handleSaveChange
    };
}

export default ProfileViewModel;
