import { formatDateToDateTimeDisplay } from 'common/helpers/format';
import { Task } from 'models/link/Link';

type Props = {
    ticket: Task;
};

function TicketCard(props: Props) {
    const { ticket } = props;

    return (
        <div className="flex flex-col overflow-hidden rounded border px-4 py-4 shadow-lg">
            <div>
                <p className="text-sm font-medium text-neutral-500">Ticket</p>
                <p className="ml-4 font-bold">{ticket.ticketNO}</p>
            </div>
            <div>
                <p className="text-sm font-medium text-neutral-500">Creator</p>
                <p className="ml-4 font-bold">{ticket.creator}</p>
            </div>

            <p className="mt-4">{ticket.jobdescription}</p>
            {ticket.ticketDetail !== '' && <p>Detail: {ticket.ticketDetail}</p>}

            <div className="mt-6">
                <p className="text-sm font-medium text-neutral-500">Status</p>
                <p className="ml-4 font-bold">
                    {ticket.activityOverviewStatus + ' ' + ticket.taskStatusDesc}
                </p>
            </div>

            <div className="mt-2 flex gap-2">
                <span className="inline-block whitespace-nowrap rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
                    {formatDateToDateTimeDisplay(ticket.checkIn_Date + ticket.checkIn_Time)}
                </span>
                <span className="inline-block whitespace-nowrap rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
                    Work Group : {ticket.projectcategory}
                </span>
            </div>
        </div>
    );
}

export default TicketCard;
