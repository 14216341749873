import React, { useState, useRef, useCallback } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Button, useBoolean } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

import { nameof } from 'common/helpers';

import {
    GeneralInfo,
    OTPType,
    DigitalSignInfo,
    SignType,
    NationInfo,
    AddressInfo,
    WorkingPosition
} from './components';
import useProfileViewModel from './Profile.viewmodel';
import Camera from './components/Camera';

function Profile() {
    const {
        isSCG,
        previewImageBlobUrl,
        previewImageSignatureBlobUrl,
        userInformation,
        copyUserInformation,
        handleRemoveAttachFile,
        handleChangeCopyUserInformation,
        handleChangeOtpOrNotificationType,
        handleChangeAttachFile,
        handleAttachFile,
        handleSaveChange
    } = useProfileViewModel();

    const profileImageInputRef = useRef<HTMLInputElement>(null);
    const [showCameraModal, setShowCameraModal] = useBoolean();

    const handleClickChooseImage = useCallback(() => {
        profileImageInputRef.current?.click();
    }, []);

    const handleClickChooseFile = useCallback(
        (file: File) => {
            handleAttachFile('image', file);
            setShowCameraModal.off();
        },
        [handleAttachFile]
    );

    return (
        <div className="flex flex-col gap-y-2.5 space-y-2 px-3 pb-4">
            <div className="flex flex-row">
                <p className="text-2xl font-bold">Profile</p>

                <div className="ms-auto flex gap-4">
                    <Button
                        size="md"
                        onClick={handleSaveChange}
                        className="bg-primary-900 text-xs text-white hover:bg-primary-700"
                    >
                        Save Change
                    </Button>

                    <Button
                        size="md"
                        className="bg-primary-900 text-xs text-white hover:bg-primary-700"
                    >
                        Change Password
                    </Button>
                </div>
            </div>

            <hr />

            {userInformation && copyUserInformation && (
                <div className="flex flex-col gap-4 xl:flex-row">
                    <div className="flex flex-col space-y-4">
                        <div className="relative h-[150px] w-[150px] overflow-hidden rounded-full border">
                            <FaTimes
                                size={16}
                                className="absolute -right-2 -top-2 box-content cursor-pointer rounded-full bg-red-600 p-0.5 text-white"
                                onClick={() => handleRemoveAttachFile('image')}
                            />
                            <img
                                src={previewImageBlobUrl ?? userInformation.image ?? ''}
                                className="h-full w-full object-cover"
                            />
                        </div>
                        <Menu>
                            <MenuButton
                                as={Button}
                                className="top-15 absolute left-36 m-1 rounded-full bg-white bg-opacity-75 p-2 text-gray-600"
                            >
                                <EditIcon boxSize={6} />
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={handleClickChooseImage}>Choose File</MenuItem>
                                <MenuItem onClick={setShowCameraModal.on}>Camera</MenuItem>
                            </MenuList>
                        </Menu>
                        <input
                            ref={profileImageInputRef}
                            type="file"
                            name={nameof('image', userInformation)}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={handleChangeAttachFile}
                            className="hidden"
                        />
                        <Camera
                            isOpen={showCameraModal}
                            onClose={setShowCameraModal.off}
                            onClickChooseFile={handleClickChooseFile}
                        />

                        <div>
                            <p>{userInformation.employment_id ?? ''}</p>
                        </div>
                    </div>

                    <div className="flex-1 space-y-4">
                        <GeneralInfo // Bound
                            isSCG={isSCG}
                            userInformation={userInformation}
                            copyUserInformation={copyUserInformation}
                            handleChangeCopyUserInformation={handleChangeCopyUserInformation}
                        />
                        <OTPType // Bound
                            userInformation={userInformation}
                            copyUserInformation={copyUserInformation}
                            onChangeOtpOrNotificationType={handleChangeOtpOrNotificationType}
                        />

                        {!isSCG && (
                            <React.Fragment>
                                <DigitalSignInfo
                                    userInformation={userInformation}
                                    copyUserInformation={copyUserInformation}
                                    handleChangeAttachFile={handleChangeAttachFile}
                                    handleChangeCopyUserInformation={
                                        handleChangeCopyUserInformation
                                    }
                                />
                                <SignType
                                    userInformation={userInformation}
                                    copyUserInformation={copyUserInformation}
                                    handleChangeCopyUserInformation={
                                        handleChangeCopyUserInformation
                                    }
                                />
                                <NationInfo // Bound
                                    userInformation={userInformation}
                                    copyUserInformation={copyUserInformation}
                                    handleChangeCopyUserInformation={
                                        handleChangeCopyUserInformation
                                    }
                                />
                                <AddressInfo // Bound
                                    userInformation={userInformation}
                                    copyUserInformation={copyUserInformation}
                                    handleChangeCopyUserInformation={
                                        handleChangeCopyUserInformation
                                    }
                                />
                                <WorkingPosition // Bound
                                    userInformation={userInformation}
                                    copyUserInformation={copyUserInformation}
                                    handleChangeCopyUserInformation={
                                        handleChangeCopyUserInformation
                                    }
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Profile;
