import { FormEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useToast } from '@chakra-ui/react';

import { Button, Input, Label, Select } from 'common/materials';
import { ResponseListWithPaging } from 'models';
import { ApplicationHistory } from 'models/history/ApplicationHistory';
import { Pagination } from 'common/components';

import { ApplicationHistoryTable } from './components';

function ApplicationHistoryLogs() {
    const toast = useToast();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [searchByCitizenId, setSearchByCitizenId] = useState<string>();

    const citizenIdInputRef = useRef<HTMLInputElement>(null);

    const { error, data, isPlaceholderData } = useQuery({
        queryKey: ['app-history', { page, pageSize, searchByCitizenId }],
        queryFn: () =>
            axios.get<ResponseListWithPaging<ApplicationHistory>>('/v1/application-history', {
                params: {
                    page,
                    page_size: pageSize,
                    citizen_id: searchByCitizenId
                }
            }),
        select: response => response.data.result_list,
        placeholderData: keepPreviousData
    });

    // Error handling
    useEffect(() => {
        if (!error) return;

        if (error instanceof AxiosError) {
            toast({
                title: 'Error',
                description: error.response?.data.message || 'ไม่ทราบสาเหตุ'
            });
        } else if (error instanceof Error) {
            toast({
                title: 'Error',
                description: error.message
            });
        }
    }, [error]);

    const handleSubmitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const citizenIdInput = citizenIdInputRef.current;
        if (!citizenIdInput) return;

        setPage(1);
        setSearchByCitizenId(citizenIdInput.value.trim());
    };

    const paginationJSX = (
        <Pagination
            currentPage={page}
            handleClickFirstPage={() => setPage(1)}
            handleClickNextPage={() => data && data.length !== 0 && setPage(page + 1)}
            handleClickPreviousPage={() => page !== 1 && setPage(page - 1)}
            className="my-2 ml-auto px-0"
        />
    );

    return (
        <div className="flex flex-col gap-y-2.5 px-3">
            <p className="text-2xl font-bold">Application History</p>
            <form
                onSubmit={handleSubmitSearch}
                className="flex flex-wrap items-center gap-4 py-2"
            >
                <div className="w-[200px] space-y-2">
                    <Label>Page size</Label>
                    <Select
                        options={[
                            { label: 25, value: 25 },
                            { label: 50, value: 50 },
                            { label: 100, value: 100 }
                        ]}
                        value={{ label: pageSize, value: pageSize }}
                        onChange={newValue => newValue && setPageSize(newValue.value)}
                    />
                </div>
                <div className="w-[200px] space-y-2">
                    <Label>Citizen ID</Label>
                    <Input ref={citizenIdInputRef} />
                </div>

                <Button
                    type="submit"
                    className="self-end bg-primary-900 text-white"
                >
                    Search
                </Button>
            </form>

            {paginationJSX}

            <ApplicationHistoryTable appHistory={data} />

            {paginationJSX}
        </div>
    );
}

export default ApplicationHistoryLogs;
