import { parseResponseError } from 'common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from 'models';
import { RoleRequest } from 'models/role/RoleRequest';
import { MainProgram } from 'models/role/MainProgram';
import {
    ProfileObjectMapping,
    ProfileObjectMappingRequest
} from 'models/role/ProfileObjectMapping';
import {
    DeleteRoleProfileMappingRequestParams,
    GetUserProfileMappingRequestParams,
    RoleProfileMapping
} from 'models/role/RoleProfileMapping';
import { User } from 'models/role/User';
import { UserProfileMapping } from 'models/role/UserProfileMapping';

import {
    addProfileObjectMappingFromRemote,
    addRoleProfileMappingFromRemote,
    deleteRoleProfileMappingFromRemote,
    getMainProgramFromRemote,
    getProfileObjectMappingFromRemote,
    getRoleProfileMappingFromRemote,
    getUserFromRemote,
    getUserProfileMappingFromRemote
} from '../datasources/role.datasource';
import { RoleProfileMappingResponse, SaveUserProfileMappingRequest } from '../schemas';

export async function getMainProgram(
    params: RoleRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<MainProgram>>> {
    try {
        const data = (await getMainProgramFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getProfileObjectMapping(
    params: RoleRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<ProfileObjectMapping>>> {
    try {
        const data = (await getProfileObjectMappingFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getRoleProfileMapping(
    params: RoleRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<RoleProfileMapping>>> {
    try {
        const data = (await getRoleProfileMappingFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getUserProgram(
    params: RoleRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<User>>> {
    try {
        const data = (await getUserFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getUserProfileMapping(
    params: GetUserProfileMappingRequestParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<UserProfileMapping>>> {
    try {
        const data = (await getUserProfileMappingFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addRoleProfileMapping(
    body: SaveUserProfileMappingRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<UserProfileMapping>>> {
    try {
        const data = (await addRoleProfileMappingFromRemote(body)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addProfileObjectMapping(
    body: ProfileObjectMappingRequest
): Promise<DatasourceReturnType<ResponseError, RoleProfileMappingResponse>> {
    try {
        const data = (await addProfileObjectMappingFromRemote(body)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export const editProfileObjectMapping = addProfileObjectMapping;

export async function deleteRoleProfileMapping(
    params: DeleteRoleProfileMappingRequestParams
): Promise<DatasourceReturnType<ResponseError, void>> {
    try {
        await deleteRoleProfileMappingFromRemote(params);

        return [null!, void 0];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
