import Swal from 'sweetalert2';
import { useLoader } from '../../common/hooks';
import { CloseTaskViewProps } from './CloseTask.view';
import { closeTaskActivity } from '../../data/link/respositories/taskRepository';

function CloseTaskViewModel({ onClose, selectedTask }: CloseTaskViewProps) {
    const loader = useLoader();

    const handleClickCloseTaskActivity = async () => {
        try {
            loader.show();

            if (!selectedTask) {
                return;
            }

            // const [error, data] = await closeTaskActivity(selectedTask.key);
            const [error, data] = await closeTaskActivity(selectedTask.key_id);

            if (error) {
                throw error;
            }

            if (data.message) {
                await Swal.fire({
                    title: 'Success!',
                    text: `Close task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();

            onClose && onClose();
        }
    };

    return { handleClickCloseTaskActivity };
}

export default CloseTaskViewModel;
