import 'react-datepicker/dist/react-datepicker.css';

import React, { useRef } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../common/materials';
import { TaskStore } from '../../models/taskStore/Task';

import EditAnnouncementView, { EditAnnouncementHandle } from './EditAnnouncement.view';

type Props = {
    isOpen?: boolean;
    onClose?: () => void;
    onRefresh?: () => void;
    selectedAnnouncement?: TaskStore;
};

function EditAnnouncementModal(props: Props) {
    const { isOpen, onClose, onRefresh, selectedAnnouncement } = props;

    const ref = useRef<EditAnnouncementHandle>(null);

    return (
        <Modal
            fullscreen
            closeOnClickOverlay
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalHeader>Edit Announcement</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <EditAnnouncementView
                    onClose={onClose}
                    onRefresh={onRefresh}
                    ref={ref}
                    selectedAnnouncement={selectedAnnouncement}
                />
            </ModalBody>
            <ModalFooter className="mt-auto flex justify-end gap-4 px-2">
                <Button
                    size="sm"
                    outline
                    className="text-gray-400"
                    onClick={() => onClose && onClose()}
                >
                    Cancel
                </Button>
                <Button
                    size="sm"
                    className="bg-primary-900 text-white"
                    onClick={() => {
                        ref.current?.handleClickSaveEditAnnouncement();
                    }}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default EditAnnouncementModal;
