import React, { ComponentProps } from 'react';

import { TextArea } from '../../../common/materials';
import { diff } from '../../../common/helpers';
import { twMerge } from 'tailwind-merge';

type PropsWithPrimitive<T> = {
    label?: string;
    comparers: [T, T];
};

type PropsWithReference<T> = {
    label?: string;
    comparers: [T, T];
    compareKey: keyof T;
};

type Props<T> = (T extends object ? PropsWithReference<T> : PropsWithPrimitive<T>) &
    ComponentProps<typeof TextArea>;

const diffTwClasses = new Map<ReturnType<typeof diff>, string>([
    ['ADDED', 'text-green-400'],
    ['MODIFIED', 'text-yellow-400'],
    ['REMOVED', 'text-red-400']
]);

function ChangeDetectionTextArea<T>(props: Props<T>) {
    const { label, ...textAreaProps } = props;

    let status: ReturnType<typeof diff>;
    if (typeof props.comparers[0] === 'object') {
        const { comparers, compareKey } = props as unknown as PropsWithReference<T>;
        const [a, b] = comparers;

        status = diff(a[compareKey], b[compareKey]);
    } else {
        const { comparers } = props as unknown as PropsWithPrimitive<T>;
        const [a, b] = comparers;

        status = diff(a, b);
    }

    return (
        <React.Fragment>
            <div className="flex items-baseline gap-2">
                {label && <p className="text-base font-semibold">{label}</p>}
                {status && (
                    <span className={twMerge('text-xs', diffTwClasses.get(status))}>{status}</span>
                )}
            </div>
            <TextArea {...textAreaProps} />
        </React.Fragment>
    );
}

export default ChangeDetectionTextArea;
