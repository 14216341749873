import { useCallback, useEffect, useRef } from 'react';

function useVirtualTableScrollbar() {
    const tableContainerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>();
    const virtualTableContainerRef = useRef<HTMLDivElement>(null);
    const virtualTableRef = useRef<HTMLDivElement>(null);

    // Handle listening scroll x on virtual and original scrollbar
    useEffect(() => {
        const tableContainer = tableContainerRef.current;
        const vTableContainer = virtualTableContainerRef.current;
        if (!tableContainer || !vTableContainer) {
            return;
        }

        const setScrollLeft = (from: HTMLElement, to: HTMLElement) =>
            (to.scrollLeft = from.scrollLeft);

        const handleScrollTableContainer = () => setScrollLeft(tableContainer, vTableContainer);
        tableContainer.addEventListener('scroll', handleScrollTableContainer);

        const handleScrollVTableContainer = () => setScrollLeft(vTableContainer, tableContainer);
        vTableContainer.addEventListener('scroll', handleScrollVTableContainer);

        return () => {
            tableContainer.removeEventListener('scroll', handleScrollTableContainer);
            vTableContainer.removeEventListener('scroll', handleScrollVTableContainer);
        };
    }, []);

    // Handle table width change
    const tableRefCallback = useCallback((table: HTMLTableElement | null) => {
        const vTable = virtualTableRef.current;
        if (!table || !vTable) {
            return;
        }

        tableRef.current = table;

        // Change width on virtual table element everytime when table width change
        // to make virtual scrollbar have correspond width to origin
        vTable.style.width = window.getComputedStyle(table).width;
    }, []);

    return {
        tableContainerRef,
        virtualTableContainerRef,
        virtualTableRef,
        tableRefCallback
    };
}

export default useVirtualTableScrollbar;
