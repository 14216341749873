import React, { ComponentProps, memo, useCallback, useEffect, useRef, useState } from 'react';
import { TableContainer, Button, Badge, Tag, Kbd } from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';

import { UserCompany } from 'models/user/UserCompany';
import { Table, Thead, Tbody, Td, Th, Tr } from 'common/materials';

import { TabLabel } from '../UserVerification.view';
import EditUserModal from './EditUserModal';
import LogsModal from './LogsModal';
import { IoRefreshCircleOutline } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';
import { observer } from 'mobx-react';

const BADGE_STYLES = new Map<string, ComponentProps<typeof Badge>['colorScheme']>([
    ['pending', 'yellow'],
    ['rejected', 'red'],
    ['active', 'green'],
    ['inactive', 'gray']
]);

interface Option {
    readonly label: string;
    readonly value: string;
}

interface Props {
    activeTab: TabLabel;
    email: string;
    users: UserCompany[];
    onEditUserStatus: (
        sid: string,
        citizenId: string,
        status: 'approve' | 'active' | 'rejected' | 'inactive'
    ) => Promise<void>;
    onSubmitEditUser: (user: UserCompany) => void;
    onEditSuccess?: (selectedCompany: Option[], selectedEmails: Option[]) => void;
    userdata: UserCompany[];
}

function UserVerificationTable(props: Props) {
    const { activeTab, users, onEditUserStatus, onSubmitEditUser, onEditSuccess, userdata } = props;

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>();
    const virtualTableContainerRef = useRef<HTMLDivElement>(null);
    const virtualTableRef = useRef<HTMLDivElement>(null);

    const [selectedEditUser, setSelectedEditUser] = useState<UserCompany>();
    const showEditUserModal = !!selectedEditUser;

    const [selectedLogsUser, setSelectedLogsUser] = useState<UserCompany>();
    const showLogsUserModal = !!selectedLogsUser;

    const [selectedEditsUserList, setSelectedEditsUserList] = useState<UserCompany[]>();

    // Handle listening scroll x on virtual and original scrollbar
    useEffect(() => {
        const tableContainer = tableContainerRef.current;
        const vTableContainer = virtualTableContainerRef.current;
        if (!tableContainer || !vTableContainer) {
            return;
        }

        const setScrollLeft = (from: HTMLElement, to: HTMLElement) =>
            (to.scrollLeft = from.scrollLeft);

        const handleScrollTableContainer = () => setScrollLeft(tableContainer, vTableContainer);
        tableContainer.addEventListener('scroll', handleScrollTableContainer);

        const handleScrollVTableContainer = () => setScrollLeft(vTableContainer, tableContainer);
        vTableContainer.addEventListener('scroll', handleScrollVTableContainer);

        return () => {
            tableContainer.removeEventListener('scroll', handleScrollTableContainer);
            vTableContainer.removeEventListener('scroll', handleScrollVTableContainer);
        };
    }, []);

    useEffect(() => {
        setSelectedEditsUserList(userdata);
    }, [userdata])

    // Handle table width change
    const handleTableLayout = (table: HTMLTableElement | null) => {
        const vTable = virtualTableRef.current;
        if (!table || !vTable) {
            return;
        }

        tableRef.current = table;

        // Change width on virtual table element everytime when table width change
        // to make virtual scrollbar have correspond width to origin
        vTable.style.width = window.getComputedStyle(table).width;
    };

    const handleCloseEditUserModal = useCallback(() => {
        setSelectedEditUser(undefined);
    }, []);

    const handleSelectdEditUser = (user: UserCompany) => {
        setSelectedEditsUserList(userdata.filter(value => value.citizen_id === user.citizen_id));

        setSelectedEditUser(user);
    }

    const handleCloseLogsModal = useCallback(() => {
        setSelectedLogsUser(undefined);
    }, []);

    const isOnApprovedTab = activeTab === 'Approved';
    const isOnRejectedTab = activeTab === 'Rejected';
    const isOnPendingTab = activeTab === 'Pending';

    return (
        <React.Fragment>
            <p className="mt-4 text-right text-sm text-primary-900">
                Tips: สามารถกดปุ่ม <Kbd className="text-neutral-950">Shift</Kbd>{' '}
                ค้างและหมุนลูกกลิ้งเมาส์เพื่อเลื่อนตารางในแนวนอนได้
            </p>

            <div
                ref={virtualTableContainerRef}
                className="h-[20px] overflow-x-auto overflow-y-hidden"
            >
                <div
                    className="h-[20px]"
                    ref={virtualTableRef}
                />
            </div>
            <TableContainer
                ref={tableContainerRef}
                className="mt-1"
            >
                <Table ref={handleTableLayout}>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>No</Th>
                            <Th>Company</Th>
                            <Th className="sticky left-0 z-10 bg-gray-200">User</Th>
                            <Th>First Name</Th>
                            <Th>Last Name</Th>
                            <Th>Email</Th>
                            <Th>Use Workflow</Th>
                            <Th>
                                Workflow Email
                            </Th>
                            <Th>Use DMS</Th>
                            <Th>F1 Employee Code</Th>
                            <Th>
                                F1 First Name
                            </Th>
                            <Th>
                                F1 Last Name
                            </Th>
                            <Th>
                                F1 First Name TH
                            </Th>
                            <Th>
                                F1 Last Name TH
                            </Th>
                            <Th>
                                F1 Email
                            </Th>
                            <Th>User Type</Th>
                            {!isOnApprovedTab && <Th>Status</Th>}
                            {!isOnRejectedTab && <Th>Manage User</Th>}
                            <Th>Last Update</Th>
                            {!isOnRejectedTab && <Th>Active User</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users &&
                            users.length > 0 &&
                            users.map((user, index) => (
                                <Tr key={user.citizen_id + user.sid}>
                                    <Td className="space-x-1">
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            colorScheme="primary"
                                            onClick={() => handleSelectdEditUser(user)}
                                        >
                                            แก้ไข
                                        </Button>
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            colorScheme="blue"
                                            onClick={() => setSelectedLogsUser(user)}
                                        >
                                            Log
                                        </Button>
                                    </Td>
                                    <Td>{index + 1}</Td>
                                    <Td>{user.company_name}</Td>
                                    <Td className="sticky left-0 z-10 bg-white">
                                        {user.citizen_id}
                                    </Td>
                                    <Td>{user.first_name}</Td>
                                    <Td>{user.last_name}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>
                                        <Badge
                                            className={twMerge('w-full rounded-md text-center text-white',
                                                user.workflow_status === 'true'
                                                    ? 'bg-primary-700'
                                                    : 'bg-danger-700'
                                            )}
                                        >
                                            {user.workflow_status}
                                        </Badge>
                                    </Td>
                                    <Td>
                                        {user.workflow_email}
                                    </Td>
                                    <Td>
                                        <Badge
                                            className={twMerge('w-full rounded-md text-center text-white',
                                                user.dms_status === 'true'
                                                    ? 'bg-primary-700'
                                                    : 'bg-danger-700'
                                            )}
                                        >
                                            {user.dms_status}
                                        </Badge>
                                    </Td>
                                    <Td>{user.f1_employee_code}</Td>
                                    <Td>
                                        {user.f1_first_name}
                                    </Td>
                                    <Td>
                                        <span>
                                            {user.f1_last_name}
                                        </span>
                                    </Td>
                                    <Td>
                                        {user.f1_first_name_th}
                                    </Td>
                                    <Td>
                                        <span>
                                            {user.f1_last_name_th}
                                        </span>
                                    </Td>
                                    <Td>
                                        {user.f1_email}
                                    </Td>
                                    <Td>{user.type}</Td>
                                    {!isOnApprovedTab && (
                                        <Td className="text-center">
                                            <Badge
                                                variant="subtle"
                                                colorScheme={BADGE_STYLES.get(user.status)}
                                                className="rounded-full px-2 py-1 text-xs"
                                            >
                                                {user.status}
                                            </Badge>
                                        </Td>
                                    )}
                                    {!isOnRejectedTab && (
                                        <Td>
                                            <div className="flex justify-center gap-2">
                                                {!isOnApprovedTab && (
                                                    <Button
                                                        size="sm"
                                                        colorScheme="primary"
                                                        className="bg-primary-900"
                                                        disabled={user.status !== 'pending'}
                                                        onClick={() =>
                                                            onEditUserStatus(
                                                                user.sid,
                                                                user.citizen_id,
                                                                'approve'
                                                            )
                                                        }
                                                    >
                                                        Approve
                                                    </Button>
                                                )}
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    colorScheme="red"
                                                    disabled={user.status !== 'pending'}
                                                    onClick={() =>
                                                        onEditUserStatus(
                                                            user.sid,
                                                            user.citizen_id,
                                                            'rejected'
                                                        )
                                                    }
                                                >
                                                    Reject
                                                </Button>
                                            </div>
                                        </Td>
                                    )}
                                    <Td></Td>
                                    {!isOnRejectedTab && (
                                        <Td>
                                            <div className="flex gap-3">
                                                {!isOnPendingTab && (
                                                    <label className="relative inline-block h-5 w-14">
                                                        <input
                                                            type="checkbox"
                                                            checked={user.status === 'active'}
                                                            onChange={() =>
                                                                onEditUserStatus(
                                                                    user.sid,
                                                                    user.citizen_id,
                                                                    user.status === 'active'
                                                                        ? 'inactive'
                                                                        : 'active'
                                                                )
                                                            }
                                                            className="peer/switch invisible h-0 w-0"
                                                        />
                                                        <span
                                                            className={twMerge(
                                                                'pointer-events:none absolute inset-0 cursor-pointer rounded-full bg-gray-100',
                                                                'before:absolute before:top-1/2 before:h-7 before:w-7 before:-translate-y-1/2 before:rounded-full before:bg-white before:shadow-[1px_3px_7px_-2px_rgba(0,0,0,0.75)] before:content-[""]',
                                                                'transition-color before:transition-[transform,_background-color]',
                                                                'peer-checked/switch:bg-primary-100 peer-checked/switch:before:translate-x-7 peer-checked/switch:before:bg-primary-500'
                                                            )}
                                                        />
                                                    </label>
                                                )}

                                                <Tag
                                                    className={twMerge(
                                                        'flex-1',
                                                        user.status === 'active'
                                                            ? 'bg-primary-50 text-primary-900'
                                                            : 'text-gray-600'
                                                    )}
                                                >
                                                    <span className="flex-1 text-center">
                                                        {user.status === 'pending'
                                                            ? 'Inactive'
                                                            : user.status[0].toUpperCase() +
                                                            user.status.slice(1)}
                                                    </span>
                                                </Tag>
                                            </div>
                                        </Td>
                                    )}
                                </Tr>
                            ))}

                        {props.users.length === 0 && (
                            <Tr>
                                <Td
                                    colSpan={13}
                                    className="text-center"
                                >
                                    No data
                                </Td>
                            </Tr>
                        )}
                    </Tbody>
                </Table>
            </TableContainer >

            <EditUserModal
                isOpen={showEditUserModal}
                user={selectedEditUser}
                onClose={handleCloseEditUserModal}
                onSubmitEdit={onSubmitEditUser}
                onEditSuccess={onEditSuccess}
                users={selectedEditsUserList!}
            />

            <LogsModal
                isOpen={showLogsUserModal}
                selectedUser={selectedLogsUser}
                onClose={handleCloseLogsModal}
            />
        </React.Fragment >
    );
}

export default observer(UserVerificationTable);
