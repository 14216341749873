import React from 'react';
import { twMerge } from 'tailwind-merge';

function Label(
    props: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>
) {
    const { className } = props;

    return (
        <label
            {...props}
            className={twMerge(
                'inline-block whitespace-nowrap text-sm text-neutral-800',
                className
            )}
        ></label>
    );
}

export default Label;
