import axios from 'axios';

class AxiosInterceptorUtils {
    private static requestInterceptor?: number;

    private constructor() {}

    public static initialInterceptRequest() {
        this.requestInterceptor = axios.interceptors.request.use(async config => {
            const token = localStorage.getItem('SSO_AUTH');
            if (token) {
                config.headers.setAuthorization(`Bearer ${token}`);
            }

            return config;
        });
    }

    public static ejectInterceptRequest() {
        if (!this.requestInterceptor) {
            return;
        }

        axios.interceptors.request.eject(this.requestInterceptor);
        delete this.requestInterceptor;
    }
}

export default AxiosInterceptorUtils;
