import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas';

import { UserInformationResponse } from '../../../models/citizen/Citizen';
import { nameof } from '../../../common/helpers';

import ChangeDetectionInput from './ChangeDetectionInput';

type Props = {
    userInformation: UserInformationResponse;
    copyUserInformation: UserInformationResponse;
    handleChangeAttachFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function DigitalSignInfo(props: Props) {
    const {
        userInformation,
        copyUserInformation,
        handleChangeAttachFile,
        handleChangeCopyUserInformation
    } = props;

    const { image_signature, digital_signature_password } = copyUserInformation;
    const comparers = [userInformation, copyUserInformation] as [
        UserInformationResponse,
        UserInformationResponse
    ];

    // Cool stuff
    const signPadContainerRef = useRef<HTMLDivElement>(null);
    const signRef = useRef<SignatureCanvas | null>(null);
    const [showSignPad, setShowSignPad] = useState(false);

    useEffect(() => {
        const onResize = () => {
            const signPadContainer = signPadContainerRef.current;
            const signPad = signRef.current;
            if (!signPadContainer || !signPad) {
                return;
            }

            signPad.getCanvas().style.display = 'none';

            const canvas = signPad.getCanvas();
            canvas.style.display = 'none';

            const clientRect = signPadContainer.getBoundingClientRect();
            canvas.width = clientRect.width;
            canvas.height = clientRect.height;
            canvas.style.display = 'block';
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    const toggleShowSignPad = () => setShowSignPad(prevState => !prevState);

    return (
        <React.Fragment>
            <p className="text-lg font-medium">Digtial Sign Information</p>
            <hr />

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col space-y-2">
                    <div className="min-h-[200px] max-w-[400px] border-2 border-neutral-300">
                        {image_signature && (
                            <>
                                <img
                                    src={
                                        image_signature.includes('https://s3')
                                            ? image_signature
                                            : 'data:image/png;base64,' + image_signature
                                    }
                                />
                            </>
                        )}
                    </div>

                    {/* <span
                        className="w-fit cursor-pointer text-primary"
                        onClick={toggleShowSignPad}
                    >
                        เปลี่ยน
                    </span> */}
                    <Modal
                        isOpen={showSignPad}
                        size="lg"
                        toggle={toggleShowSignPad}
                    >
                        <ModalHeader toggle={toggleShowSignPad}>Sign Pad</ModalHeader>
                        <ModalBody className="grid grid-cols-1 gap-10 sm:grid-cols-2 2xl:grid-cols-4">
                            <div ref={signPadContainerRef}>
                                <SignatureCanvas
                                    ref={signRef}
                                    clearOnResize
                                    canvasProps={{
                                        className: 'border',
                                        width: 100,
                                        height: 200
                                    }}
                                />
                            </div>
                        </ModalBody>
                    </Modal>

                    <div className="flex flex-1 flex-col">
                        <p>นำเข้าภาพลายเซ็น</p>
                        <input
                            id="file-upload"
                            type="file"
                            name={nameof('image_signature', userInformation)}
                            accept="image/png, image/jpg, image/jpeg"
                            onChange={handleChangeAttachFile}
                        />
                    </div>

                    <div className="flex flex-1 flex-col">
                        <p>นำเข้า Personal CA</p>
                        <input
                            id="file-upload"
                            type="file"
                            name={nameof('digital_signature', userInformation)}
                            onChange={handleChangeAttachFile}
                        />
                    </div>

                    <div className="flex flex-1 flex-col">
                        <ChangeDetectionInput
                            comparers={comparers}
                            compareKey="digital_signature_password"
                            label="Personal CA Password"
                            name={nameof('digital_signature_password', userInformation)}
                            placeholder="Personal CA Password"
                            value={digital_signature_password ?? ''}
                            onChange={handleChangeCopyUserInformation}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DigitalSignInfo;
