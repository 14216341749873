import React from 'react';
import { twMerge } from 'tailwind-merge';

function TextArea(
    props: React.DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    >
) {
    const { className, ...textAreaProps } = props;

    return (
        <textarea
            {...textAreaProps}
            className={twMerge(
                'inline-block w-full rounded-[4px] border border-neutral-300 px-3 py-1.5 text-sm outline-neutral-300 transition-[outline-color] duration-200',
                'focus:outline focus:outline-1 focus:outline-orange-500',
                className
            )}
        />
    );
}

export default TextArea;
