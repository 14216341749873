import { parseResponseError } from '../../../common/helpers';
import {
    DatasourceReturnType,
    DatasourceReturnTypeAsync,
    ResponseError,
    ResponseList
} from '../../../models';
import { UserCompany } from '../../../models/user/UserCompany';
import {
    getAll,
    getAllV2,
    requestAddInvite,
    requestAddUser,
    requestAddUsers,
    updateUsersStatus
} from '../datasources/userCompany.datasource';
import {
    UserCompanyRequest,
    UpdateUserCompanyMappingRequest,
    AddUserRequest,
    IAddInviteRequest
} from '../schemas';

export async function getUserInformation(
    params: UserCompanyRequest
): Promise<
    DatasourceReturnType<ResponseError, ResponseList<UserCompany>>
> {
    try {
        const data = (await getAll(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getAllUserInformation(
    params: UserCompanyRequest
): Promise<
    DatasourceReturnType<ResponseError, ResponseList<UserCompany>>
> {
    try {
        const data = (await getAllV2(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addUser(user: AddUserRequest): Promise<DatasourceReturnType<ResponseError, undefined>> {
    try {
        await requestAddUser(user)

        return [null!, undefined];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addUsers(users: AddUserRequest[]): Promise<DatasourceReturnType<ResponseError, undefined>> {
    try {
        await requestAddUsers(users)

        return [null!, undefined];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function addInvite(users: IAddInviteRequest[]): Promise<DatasourceReturnType<ResponseError, String>> {
    try {
        const repones = (await requestAddInvite(users)).data
        return [null!, repones];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function editUsersStatus(
    params: UpdateUserCompanyMappingRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await updateUsersStatus(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
