import { makeAutoObservable, runInAction } from 'mobx';

import { getCurrentAnnouncement } from '../../data/taskStore/repositories/taskRepository';
import { TaskStore as TaskStoreType } from '../../models/taskStore/Task';
import RootStore from '../Root.store';

class TaskStore {
    private _taskScheduleList: TaskStoreType[] = [];

    public get taskScheduleList() {
        return this._taskScheduleList;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchTasksSchedule(datetime_now: string, only_today: boolean) {
        const [error, responseTaskScheduleList] = await getCurrentAnnouncement({
            datetime_now,
            only_today
        });

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._taskScheduleList = responseTaskScheduleList;
        });
    }
}

export default TaskStore;
