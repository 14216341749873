import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as Models from '../../models/authentication/Authentication';

interface RequestAuthenticationAction {
    type: 'REQUEST_AUTHENTITION';
    requestBody: Models.RequestAunthentication;
}

interface ReceiveAuthenticationAction {
    type: 'RECEIVE_AUTHENTITION';
    requestBody: Models.RequestAunthentication;
    response: Models.ResponseAuthentication;
    responseCode: number,
    errormessage?: string,
    mode: string
}

interface SetModeAction {
    type: 'SET_MODE';
    mode: string
}

type KnownAction = RequestAuthenticationAction | ReceiveAuthenticationAction | SetModeAction;

export const actionCreators = {
    requestAuthentication: (requestBody: Models.RequestAunthentication): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();


        if (appState && appState.authentication && requestBody !== appState.authentication.requestBody) {

            //var res: Models.ResponseAuthentication = { user_id: "1234", uuid: "5678", first_name: "test", last_name: "test" };

            //console.log("start call api");
            //localStorage.setItem('SNA_AUTH', JSON.stringify(res));

            //dispatch({ type: 'RECEIVE_AUTHENTITION', requestBody: requestBody, response: res, responseCode: 200 });

            fetch(`/v1/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            })
                .then(response => {

                    if (!response.ok) {
                        throw new Error(JSON.stringify(response.json() as Promise<Models.ResponseMessage>));
                    }

                    return response.json() as Promise<Models.ResponseAuthentication>
                })
                .then(data => {
                    localStorage.setItem('SSO_AUTH', JSON.stringify(data));
                    dispatch({ type: 'RECEIVE_AUTHENTITION', requestBody: requestBody, response: data, responseCode: 200, mode: '' });

                })
                .catch((error) => {

                    dispatch({ type: 'RECEIVE_AUTHENTITION', requestBody: requestBody, response: error, responseCode: 400, errormessage: "Authetication fail", mode: '' });

                });

            dispatch({ type: 'REQUEST_AUTHENTITION', requestBody: requestBody });
        }
    },
    setMode: (mode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication && mode !== appState.authentication.mode) {
            dispatch({ type: 'SET_MODE', mode: mode });
        }
    }
}

const unloadedState: Models.AuthenticationState = { isLoading: false, mode: '' };

export const reducer: Reducer<Models.AuthenticationState> = (state: Models.AuthenticationState | undefined, incomingAction: Action): Models.AuthenticationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_AUTHENTITION':
            return {
                requestBody: action.requestBody,
                response: state.response,
                isLoading: true,
                mode: state.mode
            };
        case 'RECEIVE_AUTHENTITION':
            if (action.requestBody === state.requestBody) {
                return {
                    requestBody: action.requestBody,
                    response: action.response,
                    isLoading: false,
                    mode: state.mode
                };
            }
        case 'SET_MODE':
            return {
                requestBody: state.requestBody,
                response: state.response,
                isLoading: false,
                mode: action.mode
            };

            break;
    }

    return state;
};