import { useCallback, useState } from 'react';

function useDisclosure(initialValue: boolean = false) {
    const [isOpen, setIsOpen] = useState(initialValue);

    const onOpen = useCallback(() => setIsOpen(true), []);
    const onClose = useCallback(() => setIsOpen(false), []);
    const onToggle = useCallback((() => {
        let value = initialValue;

        return () => {
            value = !value;
            setIsOpen(value);
        };
    })(), []);

    return {
        isOpen,
        onOpen,
        onClose,
        onToggle
    };
}

export default useDisclosure;