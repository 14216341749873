import React from 'react';
import { useWindowSize } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps
} from '@chakra-ui/react';
import { ImArrowDown, ImArrowRight } from 'react-icons/im';

import { Step1, Step2, Step3, Step4 } from '../LineOnboarding';

type OwnProps = {
    onClickStartConnect: () => void;
};

type Props = Omit<ModalProps, 'children'> & OwnProps;

function LineConnectPreparationModal(props: Props) {
    const { onClickStartConnect, ...modalProps } = props;

    const { width } = useWindowSize();
    const sm = 640;

    const stepContainerTwClasses =
        'relative flex flex-col border border-neutral-200 rounded-md p-4';
    const stepBaseTwClasses =
        'absolute top-[calc(100%_+_2px)] left-1/2 -translate-x-1/2 translate-y-0';
    const stepEvenTwClasses =
        'sm:hidden 2xl:block 2xl:top-1/2 2xl:translate-x-0 2xl:-translate-y-1/2 2xl:left-[calc(100%_+_6px)]';
    const stepOddTwClasses =
        'sm:top-1/2 sm:translate-x-0 sm:-translate-y-1/2 sm:left-[calc(100%_+_6px)]';

    const ArrowIcon = width > sm ? ImArrowRight : ImArrowDown;

    return (
        <Modal
            {...modalProps}
            size="6xl"
        >
            <ModalOverlay />
            <ModalContent className="max-w-[95vw]">
                <ModalHeader>
                    เพิ่ม FocusOne เป็นเพื่อนเพื่อรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line Chat
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody className="grid grid-cols-1 gap-10 sm:grid-cols-2 2xl:grid-cols-4">
                    <div className={stepContainerTwClasses}>
                        <Step1 />

                        <ArrowIcon
                            size={32}
                            color="#F7980A"
                            className={twMerge(stepBaseTwClasses, stepOddTwClasses)}
                        />
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step2 />

                        <ArrowIcon
                            size={32}
                            color="#F7980A"
                            className={twMerge(stepBaseTwClasses, stepEvenTwClasses)}
                        />
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step3 />

                        <ArrowIcon
                            size={32}
                            color="#F7980A"
                            className={twMerge(stepBaseTwClasses, stepOddTwClasses)}
                        />
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step4 />
                    </div>
                </ModalBody>
                <ModalFooter className="justify-center">
                    <Button
                        size="lg"
                        className="bg-line-green-400 hover:bg-line-green-500 text-white"
                        onClick={onClickStartConnect}
                    >
                        เริ่มต้น Connect!
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default LineConnectPreparationModal;
