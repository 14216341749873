import React, { forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import { MdDelete } from 'react-icons/md';
import { PiUserCirclePlusFill } from 'react-icons/pi';
import { AiFillPlayCircle, AiOutlineCloudUpload, AiOutlineFile } from 'react-icons/ai';

import { Input, Label } from 'common/materials';
import { QuillEditor } from 'common/components';
import { Option } from 'models/taskGroup/TaskGroup';

import NormalModeViewModel from './NormalMode.viewmodel';

type NormalModeHandle = {
    // handleClickAddTask: () => Promise<void>;
    getNormalFormData: () => Promise<FormData | undefined>;
};

type NormalModeViewProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

const NormalModeView = forwardRef<NormalModeHandle, NormalModeViewProps>((props, ref) => {
    const {
        profileInputRef,
        profileUpload,
        setProfileUpload,
        handleChangeProfile,
        task,
        handleChangeTask,
        taskGroupList,
        selectedTaskGroup,
        setSelectedTaskGroup,
        model,
        setModel,
        fileInputRef,
        filesUpload,
        handleDeleteFile,
        handleChangeFile,
        // handleClickAddTask,
        getNormalFormData
    } = NormalModeViewModel(props);

    useImperativeHandle(ref, () => {
        return {
            // handleClickAddTaska,
            getNormalFormData
        };
    });

    return (
        <React.Fragment>
            <div className="flex flex-row items-center gap-2">
                <div className="flex justify-end">
                    {profileUpload ? (
                        <div className="relative p-2">
                            <MdDelete
                                className="absolute -right-1 -top-0 z-20 h-[20px] w-[20px] cursor-pointer text-red-600 hover:animate-bounce"
                                onClick={() => setProfileUpload(undefined)}
                            />
                            <div
                                key={profileUpload.name}
                                className="flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full border bg-white"
                            >
                                {profileUpload.name.includes('.jpg') ||
                                profileUpload.name.includes('.jpeg') ||
                                profileUpload.name.includes('.png') ? (
                                    <img
                                        src={URL.createObjectURL(profileUpload)}
                                        alt="image"
                                        className="h-full w-full object-cover"
                                    />
                                ) : undefined}
                            </div>
                        </div>
                    ) : (
                        <PiUserCirclePlusFill
                            className="cursor-pointer "
                            onClick={() => profileInputRef.current?.click()}
                            style={{ width: '60px', height: '60px', color: '#A1A1A1' }}
                        />
                    )}
                </div>
                <input
                    ref={profileInputRef}
                    type="file"
                    onChange={event => handleChangeProfile(event.target.files)}
                    className="hidden"
                />

                <div className="flex flex-col">
                    <Label>Created By</Label>
                    <Input
                        value={task.createdBy}
                        onChange={event => handleChangeTask('createdBy', event.target.value)}
                    />
                </div>
            </div>

            <div className="mt-2 flex flex-col">
                <Label>Subject</Label>
                <Input
                    value={task.subject}
                    onChange={event => handleChangeTask('subject', event.target.value)}
                />
            </div>

            <div className="relative z-50 mt-2 flex flex-col">
                <Label>Group</Label>
                <Select
                    isClearable
                    className="w-[300px] "
                    onChange={selectOption => {
                        setSelectedTaskGroup(selectOption);
                    }}
                    options={taskGroupList}
                    value={selectedTaskGroup}
                />
            </div>

            {/* <div className="mt-2 flex gap-2">
               <div className="flex flex-1 flex-col">
                   <Label>Start Date Time</Label>
                   <DatePicker
                       selected={task.startDate}
                       onChange={date => handleChangeTask('startDate', date ?? new Date())}
                       showTimeSelect
                       timeFormat="HH:mm"
                       timeIntervals={5}
                       timeCaption="time"
                       dateFormat="dd/MM/yyyy HH:mm"
                       customInput={<Input />}
                   />
               </div>
               <div className="flex flex-1 flex-col">
                   <Label>End Date Time</Label>
                   <DatePicker
                       selected={task.endDate}
                       onChange={date => handleChangeTask('endDate', date ?? new Date())}
                       showTimeSelect
                       timeFormat="HH:mm"
                       timeIntervals={5}
                       timeCaption="time"
                       dateFormat="dd/MM/yyyy HH:mm"
                       customInput={<Input />}
                   />
               </div>
            </div> */}
            <div className="mt-2 flex flex-1 flex-col">
                <Label>
                    Description
                    <span className="ml-2 text-xs text-danger-900">
                        *การแนบรูปในเนื้อหาจะไม่แสดงผลในหน้าแรก
                    </span>
                </Label>
                <QuillEditor
                    value={model}
                    onChange={setModel}
                    editorClassName="[&>.ql-container]:min-h-[85px]"
                />
            </div>

            <div className="mt-2 flex gap-2">
                <div className="flex-1">
                    <Label>
                        Attachment
                        <span className="ml-2 text-xs text-danger-900">
                            *การแนบรูปที่นี่จะถูกแสดงผลในหน้าแรก
                        </span>
                    </Label>
                    <div
                        className="flex h-8 cursor-pointer items-center justify-center gap-2 rounded-md border border-neutral-300"
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <AiOutlineCloudUpload size={18} />
                        <span className="text-sm text-neutral-500">
                            Drop file to attach or <span className="text-[#E55C00]">browse</span>
                        </span>
                    </div>
                </div>
                <div className="flex-1">
                    <Label>Assignee</Label>
                    <Input />
                </div>
            </div>

            <div className="mt-3 flex flex-wrap gap-2">
                {filesUpload.map((file, index) => (
                    <div className="relative">
                        <MdDelete
                            className="absolute -right-2 -top-2 z-20 h-[20px] w-[20px] cursor-pointer text-red-600 hover:animate-bounce"
                            onClick={() => handleDeleteFile(index)}
                        />
                        <div
                            key={file.name}
                            className="flex h-[140px] w-[140px] items-center justify-center overflow-hidden rounded-sm border bg-white"
                        >
                            {file.name.includes('.jpg') ||
                            file.name.includes('.jpeg') ||
                            file.name.includes('.png') ? (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="image"
                                    className="h-full w-full object-cover"
                                />
                            ) : file.name.includes('.mp4') ? (
                                // <Player
                                //     playsInline
                                //     autoPlay
                                //     muted
                                //     fluid={false}
                                //     src={URL.createObjectURL(file)}
                                // />
                                <div className="relative">
                                    <video
                                        autoPlay
                                        loop
                                        muted
                                    >
                                        <source
                                            src={URL.createObjectURL(file)}
                                            type="video/mp4"
                                        />
                                    </video>
                                    <AiFillPlayCircle className="absolute left-1/2 top-1/2 z-20 h-[32px] w-[32px] -translate-x-1/2 -translate-y-1/2 text-white opacity-80" />
                                </div>
                            ) : (
                                <React.Fragment>
                                    <AiOutlineFile size={36} />
                                    <span className="text-center">{file.name}</span>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                ))}

                <input
                    ref={fileInputRef}
                    type="file"
                    onChange={event => handleChangeFile(event.target.files)}
                    className="hidden"
                />
            </div>
        </React.Fragment>
    );
});

export default NormalModeView;
