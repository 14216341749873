/**
 * @description This component is only use in `Assign role modal` only.
 */

import React, { useMemo } from 'react';
import { Tooltip } from '@chakra-ui/react';
import {
    AiOutlineDoubleLeft,
    AiOutlineDoubleRight,
    AiOutlineLeft,
    AiOutlineRight
} from 'react-icons/ai';

type Props = {
    onMoveSelectedToRight: () => void;
    onMoveToRightAll: () => void;
    onMoveSelectedToLeft: () => void;
    onMoveToLeftAll: () => void;
};

function MoveInteractionBar(props: Props) {
    const { onMoveSelectedToRight, onMoveToRightAll, onMoveSelectedToLeft, onMoveToLeftAll } =
        props;

    const interactionButtons = useMemo(
        () => [
            {
                label: 'Move selected to right',
                onClick: onMoveSelectedToRight,
                Icon: AiOutlineRight
            },
            {
                label: 'Move selected to left',
                onClick: onMoveSelectedToLeft,
                Icon: AiOutlineLeft
            },
            {
                label: 'Move all left to right',
                onClick: onMoveToRightAll,
                Icon: AiOutlineDoubleRight
            },
            {
                label: 'Move all right to left',
                onClick: onMoveToLeftAll,
                Icon: AiOutlineDoubleLeft
            }
        ],
        [onMoveSelectedToRight, onMoveSelectedToLeft, onMoveToRightAll, onMoveToLeftAll]
    );

    return (
        <div className="flex flex-col items-center justify-center gap-3">
            {interactionButtons.map(button => (
                <Tooltip
                    key={button.label}
                    hasArrow
                    arrowSize={15}
                    openDelay={300}
                    closeDelay={300}
                    label={button.label}
                >
                    <button
                        onClick={button.onClick}
                        className="h-12 w-12 rounded-md border border-primary-900 text-primary-900"
                    >
                        <button.Icon size={18} />
                    </button>
                </Tooltip>
            ))}
        </div>
    );
}

export default MoveInteractionBar;
