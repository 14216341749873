import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import * as Models from '../../models/company/Company';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/company/CompanyManage';


type Store = Models.CompanyState & typeof Stores.actionCreators;

class ModalRegisterCompanyComponent extends React.PureComponent<any, { pageLoad: boolean, isOpen: boolean, companyName: string, email: string, username: string, password: string, isSave: boolean, classSpinner: string, version: number }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            pageLoad: true,
            isOpen: false,
            companyName: "",
            email: "",
            username: "",
            password: "",
            isSave: false,
            classSpinner: "d-none",
            version: 0
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.SaveClick = this.SaveClick.bind(this);
        this.handleChangeCompanyName = this.handleChangeCompanyName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    private toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen,
            classSpinner: "d-none",
            companyName: "",
            email: "",
            username: "",
            password: ""
        });

        if (!this.state.isOpen) {
            this.props.afterCloseModalRegister();
        }
    }

    private handleChangeCompanyName(event: any) {
        this.setState({ companyName: event.target.value });
    }

    private handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
    }

    private handleChangeUsername(event: any) {
        this.setState({ username: event.target.value });
    }

    private handleChangePassword(event: any) {
        this.setState({ password: event.target.value });
    }

    private SaveClick() {
        this.setState({
            isSave: true,
            classSpinner: "d-inline-block"
        });

        this.props.requestFunction(this.state.companyName, this.state.email, this.state.username, this.state.password, "POST", this.state.version);
    }

    render() {

        if (this.props.isLoading && this.state.isSave && this.props.version > this.state.version) {
            this.toggleModal();

            this.props.BindData();

            this.setState({
                isSave: false,
                classSpinner: "d-none",
                version: this.props.version
            });
        }

        return (
            <div>
                <Button color="primary" onClick={this.toggleModal}>Register Company</Button>
                <Modal isOpen={this.state.isOpen} >
                    <ModalHeader toggle={this.toggleModal}>Register Company</ModalHeader>
                    <ModalBody>
                        <label htmlFor="txtcompanyName"><b>Company Name</b></label>
                        <input type="text" id="txtcompanyName" className="form-control" value={this.state.companyName} onChange={this.handleChangeCompanyName}></input>
                        <label htmlFor="txtEmail"><b>E-mail</b></label>
                        <input type="text" id="txtEmail" className="form-control" value={this.state.email} onChange={this.handleChangeEmail}></input>
                        <label htmlFor="txtUsername"><b>Username</b></label>
                        <input type="text" id="txtUsername" className="form-control" value={this.state.username} onChange={this.handleChangeUsername}></input>
                        <label htmlFor="txtPassword"><b>Password</b></label>
                        <input type="password" id="txtPassword" className="form-control" value={this.state.password} onChange={this.handleChangePassword}></input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.SaveClick} >
                            <Spinner color="primary" className={this.state.classSpinner} style={{ width: '20px', height: '20px' }} />
                            {' '}Save
                        </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.applicationManage,
    Stores.actionCreators
)(ModalRegisterCompanyComponent);
