import React, { useEffect, useRef, useState } from 'react';
import { PiUserCircleFill } from 'react-icons/pi';

import { Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'common/materials';
import { FileInfo, TaskCommnet, TaskStore } from 'models/taskStore/Task';
import { BsReplyAll } from 'react-icons/bs';
import { extractAccessToken, parseDateToString } from 'common/helpers';
import { BiEditAlt } from 'react-icons/bi';
import { MdDeleteOutline } from 'react-icons/md';
import { formatDateToDateTimeDisplay } from 'common/helpers/format';

type Props = {
    scrollToComment?: boolean;
    selectedTask?: TaskStore;
    profilePic?: FileInfo;
    selectedImages: string[];
    keyId?: string;
    comments?: TaskCommnet[];
    submitComment: (keyId: string, message: string) => void;
    handleEventComment: (type: string, commentId: string, keyId: string, message?: string) => void;
    removeSelectedTask: () => void;
};

function PostDetailModal(props: Props) {
    const {
        scrollToComment,
        selectedTask,
        profilePic,
        selectedImages,
        keyId,
        comments,
        removeSelectedTask,
        submitComment,
        handleEventComment
    } = props;

    const commentElementRef = useRef<HTMLParagraphElement>(null);

    const [message, setMessage] = useState<string>();
    const [typeSend, setTypeSend] = useState<string>('create');
    const [taskCommentId, setTaskCommentId] = useState<string>();
    const accessToken = extractAccessToken();

    const handleSubmitComment = () => {
        submitComment(keyId ?? '', message!);
    };

    const onClickEventComment = (type: string, commentId: string) => {
        handleEventComment(type, commentId, keyId ?? '', message);
    };

    useEffect(() => {
        if (!scrollToComment) {
            return;
        }

        commentElementRef.current?.scrollIntoView();
    }, [scrollToComment]);

    return (
        <Modal
            fullscreen
            closeOnClickOverlay
            isOpen={Boolean(selectedTask)}
            onClose={removeSelectedTask}
            // className="max-w-2xl"
        >
            {selectedTask && (
                <React.Fragment>
                    <ModalHeader>{selectedTask.subject}</ModalHeader>
                    <ModalBody className="h-[100%] overflow-y-auto border">
                        <div className="flex items-center justify-start gap-2 border-b py-3">
                            {profilePic ? (
                                <img
                                    key={profilePic.file_name}
                                    src={profilePic.file_path}
                                    className="h-[50px] w-[50px] rounded-full"
                                />
                            ) : (
                                <PiUserCircleFill
                                    style={{
                                        width: '60px',
                                        height: '60px',
                                        color: '#A1A1A1',
                                        background: '#FFFFFF'
                                    }}
                                />
                            )}
                            <div className="flex flex-col">
                                <Label>Created By</Label>
                                <Label>{selectedTask.created_by}</Label>
                            </div>
                        </div>
                        {/* Images */}
                        <div className="mt-4 flex items-center justify-center gap-2 overflow-x-auto">
                            {selectedImages &&
                                selectedImages.length > 0 &&
                                selectedImages.map((imageUri, index) => {
                                    if (imageUri.includes('.mp4')) {
                                        return (
                                            <div
                                                key={index}
                                                className="relative"
                                            >
                                                <video
                                                    className="h-[30vh/] w-[30vw]"
                                                    // autoPlay
                                                    muted
                                                    controls
                                                >
                                                    <source
                                                        src={imageUri}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                                {/* <AiFillPlayCircle className="absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 text-white opacity-80" /> */}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <img
                                                key={index}
                                                src={imageUri}
                                                alt={`Image-${index}`}
                                                className="h-[30vh] w-[30vw] rounded-md object-contain"
                                            />
                                        );
                                    }
                                })}
                        </div>

                        {/* Detail Content */}
                        <p className="mt-6 whitespace-pre-wrap break-words text-base">
                            <div dangerouslySetInnerHTML={{ __html: selectedTask.description }} />
                        </p>

                        {/* Comment section */}
                        <p
                            ref={commentElementRef}
                            className="border-t pt-3 text-base font-semibold"
                        >
                            Comment
                        </p>
                        <div className="my-2 space-y-3">
                            {comments &&
                                comments.map((value, index) => {
                                    const [dateString, timeString] = parseDateToString(
                                        new Date(value.created_on ?? '')
                                    );

                                    return (
                                        <React.Fragment key={index}>
                                            {value.ref_comment_id === '' && (
                                                <div className="relative w-fit rounded-md border bg-slate-200 p-2">
                                                    <div className="flex flex-row items-baseline gap-3">
                                                        <span className="text-base font-medium">
                                                            {value.created_by}
                                                        </span>
                                                        <span className="text-xs">
                                                            {formatDateToDateTimeDisplay(
                                                                dateString + timeString
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-row">
                                                        <span className="text-[14px]">
                                                            {value.message}
                                                        </span>
                                                        <div className="absolute bottom-0 right-0 flex translate-y-1/2 items-center justify-center gap-2 rounded-full border bg-slate-400 px-1 py-0.5 text-center">
                                                            <BsReplyAll
                                                                size={16}
                                                                className="cursor-pointer transition-colors hover:text-white"
                                                                onClick={() => {
                                                                    setTaskCommentId(
                                                                        value.comment_id!
                                                                    );
                                                                    setMessage(value.created_by!);
                                                                    setTypeSend('reply');
                                                                }}
                                                            />
                                                            {accessToken.username ===
                                                                value.created_by && (
                                                                <React.Fragment>
                                                                    <BiEditAlt
                                                                        size={16}
                                                                        className="cursor-pointer transition-colors hover:text-white"
                                                                        onClick={() => {
                                                                            setTaskCommentId(
                                                                                value.comment_id!
                                                                            );
                                                                            setMessage(
                                                                                value.message!
                                                                            );
                                                                            setTypeSend('edit');
                                                                        }}
                                                                    />
                                                                    <MdDeleteOutline
                                                                        size={16}
                                                                        className="cursor-pointer transition-colors hover:text-white"
                                                                        onClick={() => {
                                                                            onClickEventComment(
                                                                                'delete',
                                                                                value.comment_id!
                                                                            );
                                                                        }}
                                                                    />
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {comments
                                                .filter(
                                                    ref => ref.ref_comment_id === value.comment_id
                                                )
                                                .map((refComment, refIndex) => (
                                                    <div
                                                        key={refIndex}
                                                        className="relative ml-6 w-fit rounded-md border bg-slate-200 p-2"
                                                    >
                                                        <div className="flex flex-row items-baseline gap-3">
                                                            <span className="text-base font-medium">
                                                                {refComment.created_by}
                                                            </span>
                                                            <span className="text-xs">
                                                                {formatDateToDateTimeDisplay(
                                                                    dateString + timeString
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="flex flex-row">
                                                            <span className="text-[14px]">
                                                                {refComment.message}
                                                            </span>
                                                            <div className="absolute bottom-0 right-0 flex translate-y-1/2 items-center justify-center gap-2 rounded-full border bg-slate-400 px-1 py-0.5 text-center">
                                                                <BsReplyAll
                                                                    size={16}
                                                                    className="cursor-pointer transition-colors hover:text-white"
                                                                    onClick={() => {
                                                                        setTaskCommentId(
                                                                            refComment.comment_id!
                                                                        );
                                                                        setMessage(
                                                                            refComment.created_by!
                                                                        );
                                                                        setTypeSend('reply');
                                                                    }}
                                                                />
                                                                {accessToken.username ===
                                                                    refComment.created_by && (
                                                                    <React.Fragment>
                                                                        <BiEditAlt
                                                                            size={16}
                                                                            className="cursor-pointer transition-colors hover:text-white"
                                                                            onClick={() => {
                                                                                setTaskCommentId(
                                                                                    refComment.comment_id!
                                                                                );
                                                                                setMessage(
                                                                                    refComment.message!
                                                                                );
                                                                                setTypeSend('edit');
                                                                            }}
                                                                        />
                                                                        <MdDeleteOutline
                                                                            size={16}
                                                                            className="cursor-pointer transition-colors hover:text-white"
                                                                            onClick={() => {
                                                                                onClickEventComment(
                                                                                    'delete',
                                                                                    refComment.comment_id!
                                                                                );
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                        <div className="flex flex-row items-center justify-center gap-2 pt-2">
                            <Input
                                value={message}
                                className="h-8 w-full rounded-lg border border-black text-[16px]"
                                onChange={e => setMessage(e.target.value)}
                            />
                            <Button
                                outline
                                onClick={() => {
                                    if (typeSend === 'create') {
                                        handleSubmitComment();
                                    } else {
                                        onClickEventComment(typeSend, taskCommentId!);
                                    }
                                }}
                                className="bg-orange-600 text-white"
                            >
                                Send
                            </Button>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="bg-[#E55C00] text-white"
                            onClick={removeSelectedTask}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </React.Fragment>
            )}
        </Modal>
    );
}

export default PostDetailModal;
