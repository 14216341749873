import { makeAutoObservable, runInAction } from 'mobx';
import { getCompanyMaster } from '../../data/company/repositories/companyMasterRepository';
import { CompanyMaster } from '../../models/company/Company';
import RootStore from '../Root.store';

class CompanyMasterStore {
    private _companyMaster: CompanyMaster[] = [];

    public get companyMaster() {
        return this._companyMaster;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchCompanyMasterStore(sid: string, status: 'active' | 'inactive') {
        const [error, responesCompanyMaster] = await getCompanyMaster({
            sid,
            status
        });
        if (error) {
            throw error;
        }

        runInAction(() => {
            this._companyMaster = responesCompanyMaster.result_list;
        });
    }
}

export default CompanyMasterStore;
