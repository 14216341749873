import { parseResponseError } from 'common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from 'models';

import {
    getCompanyPlatformMappingFromRemote
} from '../datasources/companyPlatformMapping.datasource';
import { CompanyPlatformMappingRequest, CompanyPlatformMapping } from '../schemas';

export async function getCompanyPlatformMapping(
    params: CompanyPlatformMappingRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<CompanyPlatformMapping>>> {
    try {
        const data = (await getCompanyPlatformMappingFromRemote(params)).data;
        // console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        // console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}