import React, { forwardRef, useImperativeHandle } from 'react';

import { TaskInbox } from '../../models/link/Task';
import { TaskStore } from '../../models/taskStore/Task';

import useCancelTaskViewModel from './CancelTask.viewmodel';

export type CancelTaskHandle = {
    handleClickCancelTaskActivity: () => Promise<void>;
};

// ? link-task
// export type CancelTaskViewProps = {
//     onClose?: () => void;
//     selectedTask?: TaskInbox;
// };

// ? app-pool-task-store
export type CancelTaskViewProps = {
    onClose?: () => void;
    selectedTask?: TaskStore;
};

const CloseTask = forwardRef<CancelTaskHandle, CancelTaskViewProps>((props, ref) => {
    const { handleClickCancelTaskActivity } = useCancelTaskViewModel(props);
    const { selectedTask } = props;

    useImperativeHandle(ref, () => {
        return {
            handleClickCancelTaskActivity
        };
    });

    return (
        <React.Fragment>
            {selectedTask && (
                <p>
                    หากต้องการ<span className="text-black underline">ยกเลิกงาน</span>{' '}
                    <span className="font-bold text-orange-500">{selectedTask.subject}</span>{' '}
                    กรุณากด Confirm
                </p>
            )}
        </React.Fragment>
    );
});

export default CloseTask;
