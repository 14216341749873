import { useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { createTask } from '../../data/taskStore/repositories/taskRepository';
import { useLoader } from '../../common/hooks';
import { Option } from '../../models/taskGroup/TaskGroup';

type NormalRef = {
    // handleClickAddTask: () => Promise<void>;
    getNormalFormData: () => Promise<FormData | undefined>;
};

type PosterRef = {
    // handleClickAddTask: () => Promise<void>;
    getPosterFormData: () => Promise<FormData | undefined>;
};

type NewTaskViewModelProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

function NewTaskViewModel(props: NewTaskViewModelProps) {
    const { onClose, onRefresh } = props;

    const loader = useLoader();

    const normalRef = useRef<NormalRef>(null);
    const posterRef = useRef<PosterRef>(null);

    const mode = useMemo(() => {
        return ['Normal', 'Poster'];
    }, []);

    const [selectedMode, setSelectedMode] = useState<string>(mode[0]);

    const handleChangeMode = (selectedMode: string): void => {
        setSelectedMode(selectedMode);
    };

    const handleClickAddTask = async () => {
        try {
            loader.show();

            const mergeFormData = new FormData();
            mergeFormData.append('mode', selectedMode);

            const normalFormData = await normalRef.current?.getNormalFormData();

            if (!normalFormData) {
                return;
            }

            for (const [key, value] of normalFormData.entries()) {
                mergeFormData.append(key, value);
            }

            if (selectedMode.toLowerCase() === mode[1].toLowerCase()) {
                const posterFormData = await posterRef.current?.getPosterFormData();

                if (!posterFormData) {
                    return;
                }

                for (const [key, value] of posterFormData.entries()) {
                    mergeFormData.append(key, value);
                }
            }

            // for (const [key, value] of mergeFormData.entries()) {
            //     console.log(`mergeFormData : ${key} : ${value}`);
            // }

            const [error, data] = await createTask(mergeFormData);

            if (error) {
                throw error;
            }

            if (!data) {
                return;
            }

            if (data.result) {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Create task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) {
                    onClose && onClose();
                    onRefresh && onRefresh();
                }
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
        }
    };

    return { normalRef, posterRef, mode, selectedMode, handleChangeMode, handleClickAddTask };
}

export default NewTaskViewModel;
