import React, { useState } from 'react';
import { observer } from 'mobx-react';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CardApplication from './CardApplication';
import { arrGetCompanyApplicationMappingMain } from '../../models/company/CompanyApplicationMapping';

type Props = {
    contentDataCard: arrGetCompanyApplicationMappingMain[];
};

function GridApplication(props: Props) {
    const { contentDataCard } = props;

    // const onDragComplete = result => {
    //     if (!result.destination) return;

    //     const arr = [...dragDropList];

    //     let removedItem = arr.splice(result.source.index, 1)[0];
    //     arr.splice(result.destination.index, 0, removedItem);

    //     setDragDropList(arr);
    // };

    // const [dragDropList, setDragDropList] = useState(contenDataCard);

    return (
        <div className="flex flex-1 flex-row flex-wrap overflow-hidden p-[6px]">
            {contentDataCard.map((cardItem, index) => (
                <CardApplication
                    key={index}
                    dataItem={cardItem}
                />
            ))}
        </div>
        // <DragDropContext onDragEnd={onDragComplete}>
        //     <Droppable droppableId="drag-drop-list" direction="horizontal">
        //         {(provided, snapshot) => (
        //             <div
        //                 className="drag-drop-list-container"
        //                 style={{
        //                     display: 'flex',
        //                     flexWrap: 'wrap',
        //                     overflow: 'hidden'
        //                 }}
        //                 {...provided.droppableProps}
        //                 ref={provided.innerRef}
        //             >
        //                 {dragDropList.map((item, index) => (
        //                     <Draggable
        //                         key={item.application_id}
        //                         draggableId={item.application_name}
        //                         index={index}
        //                     >
        //                         {provided => (
        //                             <div
        //                                 className="item-card"
        //                                 ref={provided.innerRef}
        //                                 {...provided.draggableProps}
        //                                 {...provided.dragHandleProps}
        //                             >
        //                                 <CardApplication
        //                                     obj={item}
        //                                     token={''}
        //                                     mode={undefined}
        //                                 />
        //                             </div>
        //                         )}
        //                     </Draggable>
        //                 ))}
        //                 {provided.placeholder}
        //             </div>
        //         )}
        //     </Droppable>
        // </DragDropContext>
    );
}
export default observer(GridApplication);
