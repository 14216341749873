import { useCallback } from 'react';
import {
    Badge,
    Button,
    Divider,
    IconButton,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    useDisclosure
} from '@chakra-ui/react';
import { Images } from 'assets';

type Props = {
    userName: string;
    userRole: string;
    email: string;
    image: string | undefined;
    onClickNavigateToProfile: () => void;
    onClickLogout: () => void;
    onClickOpenSupportModal: () => void;
};

function ProfilePopover(props: Props) {
    const {
        userName,
        userRole,
        email,
        image,
        onClickNavigateToProfile,
        onClickLogout,
        onClickOpenSupportModal
    } = props;

    const { isOpen, onToggle, onClose } = useDisclosure();

    const onBeforeNavigateToProfile = useCallback(() => {
        onClose();
        onClickNavigateToProfile();
    }, [onClose, onClickNavigateToProfile]);

    return (
        <Popover
            isOpen={isOpen}
            onClose={onClose}
            isLazy
            placement="bottom-end"
        >
            <PopoverTrigger>
                <IconButton
                    size="md"
                    variant="unstyled"
                    aria-label="Help"
                    className="group ml-1"
                    icon={
                        <div className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-neutral-50 shadow-lg">
                            <img
                                src={image ?? Images.USER}
                                alt="User"
                                className="mr-[0.5px] h-[30px] w-[30px] rounded-full object-cover"
                            />
                        </div>
                    }
                    onClick={onToggle}
                />
            </PopoverTrigger>
            <PopoverContent className="w-64">
                <PopoverCloseButton />
                <PopoverBody className="flex flex-col items-center text-sm">
                    <div className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-primary-500 shadow-lg">
                        <img
                            src={image ?? Images.USER}
                            className="h-12 w-12 rounded-full object-cover"
                        />
                    </div>
                    <p className="font-bold">{userName}</p>
                    <Badge
                        colorScheme="primary"
                        className="rounded-full px-2"
                    >
                        {userRole}
                    </Badge>
                    <p className="mt-4">{email}</p>

                    <Button
                        size="sm"
                        className="mt-4 w-3/4 rounded-full bg-primary-900 text-xs text-white hover:bg-primary-700"
                        onClick={onBeforeNavigateToProfile}
                    >
                        Manage Account
                    </Button>
                </PopoverBody>
                <PopoverFooter className="flex border-0 p-0">
                    <Button
                        size="sm"
                        className="flex-1 rounded-none text-xs"
                        onClick={onClickLogout}
                    >
                        Logout
                    </Button>
                    <Divider orientation="vertical" />
                    <Button
                        size="sm"
                        className="flex-1 rounded-none text-xs"
                        onClick={onClickOpenSupportModal}
                    >
                        Support
                    </Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    );
}

export default ProfilePopover;
