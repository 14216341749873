import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Tag,
    TagLabel
} from '@chakra-ui/react';
import Swal from 'sweetalert2';

import { Button, Input } from 'common/materials';
import { useLoader } from 'common/hooks';
import {
    GetUserProfileMappingRequestParams,
    RoleProfileMapping
} from 'models/role/RoleProfileMapping';
import { CompanyMaster } from 'models/company/Company';
import {
    deleteRoleProfileMapping,
    getUserProfileMapping
} from 'data/role/repositories/role.repository';
import { CompanyPlatformMapping } from 'data/company/schemas';
import { ResponseError } from 'models';
import { UserProfileMapping } from 'models/role/UserProfileMapping';
import { getRoleLabel } from './UserRoleMappingModal';

type Props = {
    isOpen: boolean;
    selectedCompany?: CompanyMaster;
    selectedPlatform?: CompanyPlatformMapping;
    selectedRole?: RoleProfileMapping;
    onClose: (deletedRole?: RoleProfileMapping) => void;
};

function DeleteRoleAlertDialog(props: Props) {
    const { isOpen, selectedCompany, selectedPlatform, selectedRole, onClose } = props;

    const loader = useLoader();

    const cancelButtonRef = useRef<HTMLButtonElement>(null);

    const [confirmDeleteRoleId, setConfirmDeleteRoleId] = useState('');
    const disabledConfirmDeleteButton = confirmDeleteRoleId.trim() !== (selectedRole?.roleid ?? '');
    const [userInUsed, setUserInUsed] = useState<UserProfileMapping[]>([]);

    useEffect(() => {
        if (!isOpen || !selectedCompany || !selectedPlatform || !selectedRole) {
            // Clean-up states on close
            setConfirmDeleteRoleId('');
            setUserInUsed([]);

            return;
        }

        fetchUserProfileMapping({
            sid: selectedCompany.sid,
            platform_id: selectedPlatform.platform_id,
            role_id: selectedRole.roleid
        });
    }, [isOpen, selectedCompany, selectedPlatform, selectedRole]);

    const fetchUserProfileMapping = async (params: GetUserProfileMappingRequestParams) => {
        try {
            loader.show();

            const [error, data] = await getUserProfileMapping(params);
            if (error) {
                throw error;
            }

            setUserInUsed(distinctUsers(data.result_list ?? []));
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const distinctUsers = (userProfiles: UserProfileMapping[]) => {
        const seenUsers = new Set<string>();
        const users: UserProfileMapping[] = [];
        for (let index = 0; index < userProfiles.length; index++) {
            if (seenUsers.has(userProfiles[index].userid)) {
                continue;
            }

            seenUsers.add(userProfiles[index].userid);
            users.push(userProfiles[index]);
        }

        return users;
    };

    const handleChangeConfirmDeleteRoleId = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmDeleteRoleId(event.target.value);
        },
        []
    );

    const handleClickConfirmDeleteRole = useCallback(async () => {
        try {
            if (
                disabledConfirmDeleteButton || // Prevent user enabled button from chrome devtool
                !selectedCompany ||
                !selectedPlatform ||
                !selectedRole
            ) {
                return;
            }

            const [error] = await deleteRoleProfileMapping({
                sid: selectedCompany.sid,
                platform_id: selectedPlatform.platform_id,
                role_id: selectedRole.roleid
            });
            if (error) {
                throw error;
            }

            await Swal.fire('Success!', `ลบ Role ${getRoleLabel(selectedRole)} สำเร็จ`, 'success');
            onClose(selectedRole);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    }, [disabledConfirmDeleteButton, selectedCompany, selectedPlatform, selectedRole]);

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelButtonRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                {selectedRole && (
                    <AlertDialogContent>
                        <AlertDialogHeader
                            fontSize="lg"
                            fontWeight="bold"
                        >
                            Delete{' '}
                            <span className="text-primary-900">{getRoleLabel(selectedRole)}</span>{' '}
                            Role?
                        </AlertDialogHeader>
                        <AlertDialogBody className="space-y-4">
                            {userInUsed.length > 0 && (
                                <div className="space-y-2">
                                    <p className="rounded-md font-semibold text-red-600">คำเตือน</p>
                                    <div className="rounded-md bg-primary-50 p-2">
                                        <p className="text-sm font-semibold text-primary-900">
                                            Role นี้ถูกใช้กับผู้ใช้คนใดบ้าง
                                        </p>
                                        <div className="mt-2 flex flex-wrap gap-2">
                                            {userInUsed.map(user => (
                                                <Tag
                                                    key={user.userid}
                                                    className="bg-primary-900 text-white"
                                                >
                                                    <TagLabel>{user.userid}</TagLabel>
                                                </Tag>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="space-y-2">
                                <p className="font-semibold">
                                    หากคุณต้องการลบกรุณาพิมพ์{' '}
                                    <span className="rounded-md bg-neutral-100 px-1">
                                        {selectedRole.roleid}
                                    </span>{' '}
                                    เพื่อยืนยัน
                                </p>
                                <Input
                                    placeholder={selectedRole.roleid}
                                    value={confirmDeleteRoleId}
                                    onChange={handleChangeConfirmDeleteRoleId}
                                />
                            </div>
                        </AlertDialogBody>
                        <AlertDialogFooter className="gap-2">
                            <Button
                                ref={cancelButtonRef}
                                onClick={() => onClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={disabledConfirmDeleteButton}
                                onClick={handleClickConfirmDeleteRole}
                                className="bg-primary-900 text-white disabled:bg-primary-900/50"
                            >
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                )}
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

export default DeleteRoleAlertDialog;
