import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>;

const Table = forwardRef<HTMLTableElement, Props>((props, ref) => {
    const { className, children, ...tableProps } = props;

    return (
        <table
            ref={ref}
            {...tableProps}
            className={twMerge('w-full table-auto border border-zinc-300', className)}
        >
            {children}
        </table>
    );
});

export default Table;
