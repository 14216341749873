import { memo, useMemo } from 'react';
import {
    Badge,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay
} from '@chakra-ui/react';

import { Button } from 'common/materials';
import { Task } from 'models/link/Link';
import { TaskActivity } from 'models/link/Task';
import { ReplyEmailSupport } from 'models/user/UserSupport';

import ParticipantMessage from './ParticipantMessage';
import { ImHammer2 } from 'react-icons/im';
import { IoCloseCircle } from 'react-icons/io5';
import { useLoader } from 'common/hooks';
import React from 'react';

type Props = {
    isOpen: boolean;
    task?: Task;
    taskActivity?: TaskActivity;
    onClose: () => void;
    onClickReply: (reply: ReplyEmailSupport) => void;
    onClickCloseTask: () => void;
    onClickCancelTask: () => void;
};

function TicketDetailModal(props: Props) {
    const {
        isOpen,
        task,
        taskActivity,
        onClose,
        onClickReply,
        onClickCloseTask,
        onClickCancelTask
    } = props;

    const loader = useLoader();

    const copiedActivities = useMemo(
        () => [...(taskActivity?.activity_participant_message ?? [])],
        [taskActivity]
    );
    const initialActivity = useMemo(() => copiedActivities.shift(), [copiedActivities]);
    const latestActivity = useMemo(() => {
        // Find last
        for (let index = copiedActivities.length - 1; index >= 0; index--) {
            const activity = copiedActivities[index];
            if (activity.messageType.toLowerCase().includes('email')) {
                return activity;
            }
        }
    }, [copiedActivities]);

    return (
        <Modal
            size="6xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            {task && taskActivity && (
                <ModalContent className="max-w-[85dvw]">
                    {/* <ModalHeader /> */}
                    <ModalCloseButton className="right-[28px] top-[19px]" />
                    <ModalBody className="space-y-4 py-4">
                        <div className="rounded-md border border-l-[6px] border-primary-900 bg-primary-50 px-2 py-2 text-sm">
                            <span>Ticket No. :</span> <span>{task.ticketNO}</span>
                        </div>

                        <div className="space-y-2">
                            <div>
                                {/* <p className="font-semibold">
                                    <span className="">Work Group</span>{' '}
                                    <span className="text-primary-900">{task.projectname}</span>{' '}
                                    <span>/ {task.projectcategory ?? '*'}</span>
                                </p> */}
                                <div className="flex items-center gap-2">
                                    <Badge className="bg-primary-900 text-sm text-white">
                                        {task.itemTypeName}
                                    </Badge>
                                    <p className="text-lg font-semibold text-primary-900">
                                        {task.jobdescription}
                                    </p>

                                    {!task.activityStatus.includes('checkedout') &&
                                        !task.activityStatus.includes('canceled') && (
                                            <React.Fragment>
                                                <Button
                                                    onClick={onClickCloseTask}
                                                    className="ml-auto gap-2 bg-primary-900 text-white"
                                                >
                                                    <ImHammer2 size={16} />
                                                    ปิดงาน
                                                </Button>
                                                <Button
                                                    outline
                                                    onClick={onClickCancelTask}
                                                    className="gap-2 border-danger-600 text-danger-600"
                                                >
                                                    <IoCloseCircle size={16} />
                                                    ยกเลิกงาน
                                                </Button>
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>

                            {/* <div>
                                <div>
                                    <Progress
                                        value={parseFloat(task.taskStatusPercent)}
                                        colorScheme="primary"
                                        className="max-w-xs rounded-md"
                                    />
                                    <span>{task.taskStatusPercent} %</span>
                                </div>
                                <p>{task.taskStatusDesc}</p>
                            </div> */}
                        </div>

                        {/* <div className="time-stamp">
                            <div>
                                <p className="font-semibold">จาก</p>
                                <p className="px-2">
                                    {formatDateTimeString(task.checkIn_Date, task.checkIn_Time)}
                                </p>
                            </div>

                            <div>
                                <p className="font-semibold">ถึง</p>
                                <p className="px-2">
                                    {formatDateTimeString(task.checkOut_Date, task.checkOut_Time)}
                                </p>
                            </div>
                        </div> */}
                        <div>
                            <p className="font-semibold">Status</p>
                            <p className="px-2">{task.activityOverviewStatus}</p>
                        </div>

                        <div>
                            <p className="font-semibold">Response Person</p>
                            <p className="px-2">{task.leader}</p>
                        </div>

                        <div className="hidden">
                            <p className="font-semibold">Attachments</p>
                            <div className="px-2">
                                {taskActivity.activity_participant_message &&
                                    (() => {
                                        const firstActivity =
                                            taskActivity.activity_participant_message[0];

                                        return (
                                            firstActivity.files &&
                                            firstActivity.files.map(file => (
                                                <img
                                                    key={file.key}
                                                    src={file.url}
                                                    alt=""
                                                    className="w-24"
                                                />
                                            ))
                                        );
                                    })()}
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold">รายละเอียด</p>
                            <div className="mt-1 space-y-2 px-2">
                                {initialActivity && (
                                    <ParticipantMessage
                                        noReplyButton
                                        task={task}
                                        activity={initialActivity}
                                        onClickReply={onClickReply}
                                    />
                                )}
                                {/* {taskActivity.activity_participant_message &&
                                    taskActivity.activity_participant_message
                                        .filter(activity =>
                                            activity.messageType.toLowerCase().includes('email')
                                        )
                                        .map(activity => (
                                            <ParticipantMessage activity={activity} />
                                        ))} */}
                            </div>
                        </div>

                        <div>
                            <p className="font-semibold">แชทเรื่องงาน</p>
                            <div className="mt-1 space-y-2 px-2">
                                {latestActivity && (
                                    <ParticipantMessage
                                        task={task}
                                        activity={latestActivity}
                                        onClickReply={onClickReply}
                                    />
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            outline
                            onClick={onClose}
                            className="border-primary-900 text-primary-900"
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            )}
        </Modal>
    );
}

export default memo(TicketDetailModal);
