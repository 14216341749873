import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../../common/materials';

type Props = {
    isOpen?: boolean;
    onClose?: () => void;
    userEventType?: { type: string; username: string[] } | undefined;
};

function UserEventStatus(props: Props) {
    const { isOpen, onClose, userEventType } = props;

    const [isBlur, setIsBlur] = useState(false);

    return (
        <Modal
            noPortal
            isOpen={isOpen}
            onClose={onClose}
            className={twMerge(
                isBlur ? 'pointer-events-none translate-y-60' : 'translate-y-0',
                'transition-transform'
            )}
        >
            <ModalHeader>{userEventType?.type}</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-x-auto">
                {userEventType?.username.map((value, index) => <span key={index}>{value}</span>)}
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button
                    outline
                    onClick={onClose}
                    className="text-neutral-400"
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default UserEventStatus;
