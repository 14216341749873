import React from 'react';
import { twMerge } from 'tailwind-merge';

function ModalHeader(
    props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
    const { children, className, ...divProps } = props;

    return (
        <div
            {...divProps}
            className={twMerge('flex', className)}
        >
            {typeof children === 'string' ? (
                <p className="text-xl font-semibold">{children}</p>
            ) : (
                children
            )}
        </div>
    );
}

export default ModalHeader;
