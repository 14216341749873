import * as React from 'react';
import { Row, Col, Table, Spinner, ButtonGroup } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap';
import * as UserCompanyModel from '../../models/user/UserCompany';
import * as UserCompanyStore from '../../store/user/UserCompany';
import Swal from 'sweetalert2';
import colors from '../asset/default/Colors';
import { FaCheckCircle, FaTimesCircle, FaPowerOff, FaClock } from 'react-icons/fa';

import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { parseJwt } from '../../modules/common';

type store = UserCompanyModel.AddUserCompanyState & typeof UserCompanyStore.actionCreators;

class UserDashboardComponent extends React.Component<
    any,
    { checkCompany: any; isGetUserCompany: boolean },
    store
> {
    constructor(props: any) {
        super(props);

        this.state = {
            isGetUserCompany: true,
            checkCompany: undefined
        };
    }

    componentDidMount() {
        const { isGetUserCompany } = this.state;
        var ls: any = localStorage.getItem('SSO_AUTH');
        var userId = '';
        if (ls) {
            var jls: any = JSON.parse(ls);
            var accessToken = jls.access_token;
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);

            if (extra.user_id) {
                userId = extra.user_id;
            } else if (extra.username) {
                userId = extra.username;
            } else if (extra.citizen_id) {
                userId = extra.citizen_id;
            }
        }

        /*fetch(`/v1/user/company?citizen_id=` + userId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            },
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                if (!data.ok) {
                    data.json().then(err => {
                    })
                } else {
                    data.json().then(data => {
                        this.setState({
                            checkCompany: data['result_list'][0].sid
                        })
                        if (isGetUserCompany) {
                            this.props.requestGetUserCompany(isGetUserCompany, 'GET', data['result_list'][0].sid);
                            this.setState({
                                isGetUserCompany: false
                            })
                        }
                    })
                }
            })*/
    }

    componentDidUpdate(nextProps, nextState) {
        const { DeleteUserCompany, EditUserCompany } = this.props;
        if (
            DeleteUserCompany.isLoadDeleteUserCompany &&
            DeleteUserCompany.statusDeleteUserCompany == 200
        ) {
            Swal.fire({
                title: 'Success!',
                icon: 'success',
                text: 'Change status success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.props.requestDeleteUserCompany(true, 'CLEAR', '', '');
                    this.refreshData();
                }
            });
        } else if (
            DeleteUserCompany.isLoadDeleteUserCompany &&
            DeleteUserCompany.statusDeleteUserCompany != 200
        ) {
            Swal.fire({
                title: 'Error!',
                text: DeleteUserCompany.messageDeleteUserCompany,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.props.requestDeleteUserCompany(true, 'CLEAR', '', '');
                    this.refreshData();
                }
            });
        }

        if (EditUserCompany.isLoadEditUserCompany && EditUserCompany.statusEditUserCompany == 200) {
            Swal.fire({
                title: 'Success!',
                icon: 'success',
                text: 'Change status success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.props.requestEditUserCompany(true, 'CLEAR', '', '');
                    this.refreshData();
                }
            });
        } else if (
            EditUserCompany.isLoadEditUserCompany &&
            EditUserCompany.statusEditUserCompany != 200
        ) {
            Swal.fire({
                title: 'Error!',
                text: EditUserCompany.messageEditUserCompany,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            }).then(result => {
                if (result.value) {
                    this.props.requestEditUserCompany(true, 'CLEAR', '', '');
                    this.refreshData();
                }
            });
        }
    }

    onchangeSwitch(status: string, sid: string, citizen_id: string) {
        console.log(status + '|' + sid + '|' + citizen_id);
        Swal.fire({
            title: 'Are you sure ?',
            text: 'change status',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            allowOutsideClick: false
        }).then(result => {
            if (result.value) {
                this.props.requestEditUserCompany(true, 'PUT', sid, citizen_id, status);
            } else {
                this.refreshData();
            }
        });
    }

    formatDMY = (dmy: string) => {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(5, 7);
        var day = dmy.substring(8, 10);

        var Datevalue = '';
        if (dmy.length < 11) {
            Datevalue = day + '-' + month + '-' + year;
        } else {
            var hour = dmy.substring(11, 13);
            var second = dmy.substring(14, 16);
            var millssecond = dmy.substring(17, 19);
            Datevalue =
                day + '-' + month + '-' + year + ' ' + hour + ':' + second + ':' + millssecond;
        }

        return Datevalue;
    };

    refreshData() {
        this.setState({
            isGetUserCompany: true
        });
        this.props.requestGetUserCompany(true, 'GET', this.state.checkCompany);
    }

    render() {
        const { isGetUserCompany } = this.state;
        const { GetUserCompany } = this.props;
        return (
            <div>
                <Row>
                    <Col>
                        {/*<div className='trapezoid-head' style={{ width: '200px' }}>*/}
                        {/*    <span>*/}
                        {/*        User Management*/}
                        {/*        </span>*/}
                        {/*</div>*/}

                        <Tabs
                            defaultActiveKey="Usermanagement"
                            id="tab"
                        >
                            <Tab
                                eventKey="Usermanagement"
                                title="User Management"
                            >
                                {/* <WorkflowInboxInformation wfId={this.state.wfId} docNo={this.state.docNo} wfInstanceno={this.state.wfInstanceno} userId={this.state.userId} showmodal={this.state.showModal} blindopenModal={this.blindopenModal.bind(this)} />*/}
                                {/*<WorkflowInboxInformation workflowValue={this.state.workflowValue} showmodal={this.state.showModal} blindopenModal={this.blindopenModal.bind(this)} refreshPageChild={this.refreshPageChild.bind(this)} jsonReportCallback={this.jsonReportCallback.bind(this)} />*/}
                                {/* <iframe src={this.state.src} name="myIframe" height="100%" width="100%" title="Iframe Example"  frameBorder="0" ref="iframe"></iframe>*/}
                                <Row>
                                    <Col>
                                        <Table
                                            bordered
                                            className="table-hover"
                                            size="sm"
                                        >
                                            <thead className="table-active">
                                                <tr
                                                    className="text-center"
                                                    style={{ width: '100%' }}
                                                >
                                                    <th style={{ width: '5%' }}></th>
                                                    <th style={{ width: '35%' }}>Company Name</th>
                                                    <th style={{ width: '10%' }}>Citizen ID</th>
                                                    <th style={{ width: '20%' }}>Requester Time</th>
                                                    <th style={{ width: '15%' }}>Status</th>
                                                    <th style={{ width: '15%' }}>Option</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!GetUserCompany.isLoadGetUserCompany ? (
                                                    <tr>
                                                        <td
                                                            colSpan={6}
                                                            style={{
                                                                background:
                                                                    'rgba(209, 250, 221, 1)',
                                                                color: 'black',
                                                                fontSize: '10pt',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                            />
                                                            &nbsp;&nbsp;Loading...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr></tr>
                                                )}
                                                {GetUserCompany.isLoadGetUserCompany &&
                                                GetUserCompany.statusGetUserCompany != 200 ? (
                                                    <tr>
                                                        <td
                                                            colSpan={6}
                                                            style={{
                                                                background: '#b7b7a4',
                                                                color: 'black',
                                                                fontSize: '10pt',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            &nbsp;&nbsp;No Data...
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr></tr>
                                                )}
                                                {GetUserCompany.statusGetUserCompany == 200 &&
                                                    GetUserCompany.isLoadGetUserCompany &&
                                                    GetUserCompany.responseGetUserCompany[
                                                        'result_list'
                                                    ] != null &&
                                                    GetUserCompany.responseGetUserCompany[
                                                        'result_list'
                                                    ].map((item: any, idx: any) => {
                                                        return (
                                                            <tr
                                                                key={idx}
                                                                style={{ height: '42px' }}
                                                            >
                                                                <td className="text-center">
                                                                    {idx + 1}
                                                                </td>
                                                                <td>{item.company_name}</td>
                                                                <td className="text-center">
                                                                    {item.citizen_id}
                                                                </td>
                                                                <td>
                                                                    {this.formatDMY(
                                                                        item.created_on
                                                                    )}
                                                                </td>
                                                                <td className="text-center">
                                                                    {item.status == 'pending' && (
                                                                        //<span style={{ color: "#f9cf00" }}>{item.status}</span>
                                                                        <span
                                                                            style={{
                                                                                color: colors.statusPending
                                                                            }}
                                                                        >
                                                                            <FaClock />{' '}
                                                                            {item.status}
                                                                        </span>
                                                                    )}
                                                                    {item.status == 'active' && (
                                                                        //<span style={{ color: "green" }}>{item.status}</span>
                                                                        <span
                                                                            style={{
                                                                                color: colors.statusActive
                                                                            }}
                                                                        >
                                                                            <FaCheckCircle />{' '}
                                                                            {item.status}
                                                                        </span>
                                                                    )}
                                                                    {item.status == 'inactive' && (
                                                                        //<span style={{ color: "#c58206" }}>{item.status}</span>
                                                                        <span
                                                                            style={{
                                                                                color: colors.statusInactive
                                                                            }}
                                                                        >
                                                                            <FaPowerOff />{' '}
                                                                            {item.status}
                                                                        </span>
                                                                    )}
                                                                    {item.status == 'rejected' && (
                                                                        //<span style={{ color: "red" }}>{item.status}</span>
                                                                        <span
                                                                            style={{
                                                                                color: colors.statusRejected
                                                                            }}
                                                                        >
                                                                            <FaTimesCircle />{' '}
                                                                            {item.status}
                                                                        </span>
                                                                    )}

                                                                    {/*<BootstrapSwitchButton
                                                    checked={item.status == 'active' ? true : false}
                                                    onlabel='Active'
                                                    offlabel='Pending'
                                                    onChange={(checked: boolean) => {
                                                        this.onchangeSwitch(checked, item.sid, item.citizen_id)
                                                    }}
                                                    size="sm"
                                                    onstyle="primary"
                                                    offstyle="warning"
                                                    width={100}
                                                />*/}
                                                                </td>
                                                                <td className="text-center">
                                                                    <ButtonGroup>
                                                                        {item.status ==
                                                                            'pending' && (
                                                                            <>
                                                                                <button
                                                                                    className="btn btn-success btn-sm"
                                                                                    style={{
                                                                                        width: '100px'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        this.onchangeSwitch(
                                                                                            'active',
                                                                                            item.sid,
                                                                                            item.citizen_id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FaCheckCircle />{' '}
                                                                                    accept
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger btn-sm"
                                                                                    style={{
                                                                                        width: '100px'
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        this.onchangeSwitch(
                                                                                            'rejected',
                                                                                            item.sid,
                                                                                            item.citizen_id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <FaTimesCircle />{' '}
                                                                                    reject
                                                                                </button>
                                                                            </>
                                                                        )}
                                                                        {item.status ==
                                                                            'active' && (
                                                                            <button
                                                                                className="btn btn-primary btn-sm"
                                                                                style={{
                                                                                    width: '100px'
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.onchangeSwitch(
                                                                                        'inactive',
                                                                                        item.sid,
                                                                                        item.citizen_id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaPowerOff />{' '}
                                                                                inactive
                                                                            </button>
                                                                        )}
                                                                        {item.status ==
                                                                            'inactive' && (
                                                                            <button
                                                                                className="btn btn-success btn-sm"
                                                                                style={{
                                                                                    width: '100px'
                                                                                }}
                                                                                onClick={() =>
                                                                                    this.onchangeSwitch(
                                                                                        'active',
                                                                                        item.sid,
                                                                                        item.citizen_id
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaPowerOff />{' '}
                                                                                active
                                                                            </button>
                                                                        )}
                                                                    </ButtonGroup>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                            </tbody>
                                        </Table>
                                        {/*</div>*/}
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab
                                eventKey="WorkflowManagement"
                                title="Workflow User Management"
                            >
                                <Row>
                                    <Col>
                                        <div>
                                            <iframe
                                                src="https://workflow.sonarinno.com/MasterEmployee"
                                                name="myIframe"
                                                height={window.screen.height}
                                                width="100%"
                                                title="Iframe Example"
                                                frameBorder="0"
                                                ref="iframe"
                                            ></iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab
                                eventKey="DmsManagement"
                                title="DMS User Management"
                            >
                                <Row>
                                    <Col>
                                        <div>
                                            <iframe
                                                src="https://dms.sonarinno.com/access-management"
                                                name="myIframe"
                                                height={window.screen.height}
                                                width="100%"
                                                title="Iframe Example"
                                                frameBorder="0"
                                                ref="iframe"
                                            ></iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({ ...state.UserCompany }), {
    ...UserCompanyStore.actionCreators
})(UserDashboardComponent);
