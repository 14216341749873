import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<
    React.TdHTMLAttributes<HTMLTableCellElement>,
    HTMLTableCellElement
>;

function Td(props: Props) {
    const { className, children, ...tdProps } = props;

    return (
        <td
            {...tdProps}
            className={twMerge(
                'border border-zinc-300 px-2.5 py-1.5 text-base text-neutral-800',
                className
            )}
        >
            {children}
        </td>
    );
}

export default Td;
