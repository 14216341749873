/**
 * @description This component is only use in manage role modal.
 */

import { IconButton } from '@chakra-ui/react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { twMerge } from 'tailwind-merge';

import { FaListUl, FaPlus } from 'react-icons/fa6';
import { MdDeleteForever } from 'react-icons/md';

const EMPTY_FUNCTION = () => void 0;

type Props<T> = {
    noAddButton?: boolean;
    noDetailButton?: boolean;
    noDeleteButton?: boolean;
    title?: string;
    roles: T[];
    selectedRoles?: Set<T>;
    className?: string;
    getRoleLabel: (role: T) => string;
    onClickAddRole?: () => void;
    onClickDeleteRole?: (role: T, index: number) => void;
    onSelectRole: (role: T, index: number) => void;
    onClickRoleDetail?: (role: T, index: number) => void;
};

function RoleList<T>(props: Props<T>) {
    const {
        noAddButton,
        noDeleteButton,
        noDetailButton,
        title = '',
        roles,
        selectedRoles,
        className,
        getRoleLabel,
        onClickAddRole,
        onClickDeleteRole,
        onSelectRole,
        onClickRoleDetail
    } = props;

    return (
        <div
            className={twMerge(
                'flex h-[50dvh] flex-1 flex-col rounded-md bg-gray-100 p-3',
                className
            )}
        >
            <div className="flex items-center gap-1">
                {!noAddButton && (
                    <IconButton
                        aria-label="add-role"
                        icon={<FaPlus />}
                        onClick={onClickAddRole}
                        className="bg-white text-primary-900 duration-100 hover:bg-white/5"
                    />
                )}
                <p className="font-semibold">{title}</p>
            </div>

            <div className="flex-1">
                <AutoSizer>
                    {({ width, height }) => (
                        <List
                            className="mt-1 flex flex-1 flex-col gap-1 overflow-y-auto"
                            height={height}
                            width={width}
                            itemCount={roles.length}
                            itemSize={45}
                            itemData={roles}
                            overscanCount={20}
                        >
                            {props => (
                                <Role
                                    {...props}
                                    noDeleteButton={noDeleteButton}
                                    noDetailButton={noDetailButton}
                                    selectedRoles={selectedRoles}
                                    onClickDeleteRole={onClickDeleteRole}
                                    onClickRoleDetail={onClickRoleDetail}
                                    onSelectRole={onSelectRole}
                                    getRoleLabel={getRoleLabel}
                                />
                            )}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
}

type RoleProps<T> = ListChildComponentProps<T> &
    Pick<
        Props<ExtractArrayType<T>>,
        | 'selectedRoles'
        | 'onClickDeleteRole'
        | 'onClickRoleDetail'
        | 'onSelectRole'
        | 'getRoleLabel'
        | 'noDeleteButton'
        | 'noDetailButton'
    >;

function Role<T>(props: RoleProps<T>) {
    const {
        noDeleteButton,
        noDetailButton,
        index,
        style,
        data: role,
        selectedRoles,
        onClickDeleteRole,
        onClickRoleDetail,
        onSelectRole,
        getRoleLabel
    } = props;

    return (
        <div
            style={style}
            className="flex items-center gap-1"
        >
            {!noDeleteButton && (
                <IconButton
                    aria-label="role-detail"
                    icon={<MdDeleteForever size={20} />}
                    onClick={() => onClickDeleteRole && onClickDeleteRole(role[index], index)}
                    className="bg-white text-primary-900 duration-100 hover:bg-white/5"
                />
            )}
            {!noDetailButton && (
                <IconButton
                    aria-label="role-detail"
                    icon={<FaListUl />}
                    onClick={() => onClickRoleDetail && onClickRoleDetail(role[index], index)}
                    className="bg-white text-primary-900 duration-100 hover:bg-white/5"
                />
            )}
            <button
                className="flex flex-1 items-center rounded-md bg-white px-2 py-2 text-left transition-colors duration-100 hover:bg-white/5 active:bg-white/10"
                onClick={() => onSelectRole(role[index], index)}
            >
                <span className="flex-1">{getRoleLabel(role[index]) || '‎'}</span>
                <input
                    readOnly
                    type="checkbox"
                    className="h-4 w-4 cursor-pointer accent-primary-900"
                    checked={selectedRoles && selectedRoles.has(role[index])}
                    onChange={EMPTY_FUNCTION}
                />
            </button>
        </div>
    );
}

export default RoleList;
