import React, { useRef } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../common/materials';
import { TaskInbox } from '../../models/link/Task';
import { TaskStore } from '../../models/taskStore/Task';

import CancelTaskView, { CancelTaskHandle } from './CancelTask.view';

// ? link-task
// type Props = {
//     isOpen?: boolean;
//     onClose?: () => void;
//     selectedTask?: TaskInbox;
// };

// ? app-pool-task-store
type Props = {
    isOpen?: boolean;
    onClose?: () => void;
    selectedTask?: TaskStore;
};

function CancelTaskModal(props: Props) {
    const { isOpen, onClose, selectedTask } = props;

    const ref = useRef<CancelTaskHandle>(null);

    return (
        <Modal
            className="max-w-2xl"
            closeOnClickOverlay
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalHeader>ยืนยันการยกเลิก Information</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                <CancelTaskView
                    onClose={onClose}
                    ref={ref}
                    selectedTask={selectedTask}
                />
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button
                    outline
                    onClick={onClose}
                    className="text-neutral-400"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        ref.current?.handleClickCancelTaskActivity();
                    }}
                    className="bg-[#E55C00] text-white"
                >
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default CancelTaskModal;
