import { Action, Reducer, createStore, combineReducers } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '..';
// models
import * as Models from '../../models/user/UserCompany'

// Load UserCompany
interface LoadUserCompany {
    type: 'LOAD_USERCOMPANY'
}
interface LoadedUserCompany {
    type: 'LOADEDUSERCOMPANY'
    response: Models.resGetUserCompany
    status: number,
    statusMessage: string
}
interface FailLoadedUserCompany {
    type: 'LOADUSERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearLoadedUserCompany {
    type: 'CLEARLOADEDUSERCOMPANY'
}

// Add UserCompany
interface AddUserCompany {
    type: 'ADD_USERCOMPANY'
}
interface AddedUserCompany {
    type: 'ADDEDUSERCOMPANY'
    status: number,
    statusMessage: string
}
interface FailAddedUserCompany {
    type: 'ADDUSERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearAddedUserCompany {
    type: 'CLEARADDUSERCOMPANY'
}

// Delete UserCompany
interface DeleteUserCompany {
    type: 'DELETE_USERCOMPANY'
}
interface DeletedUserCompany {
    type: 'DELETEDUSERCOMPANY'
    status: number,
    statusMessage: string
}
interface FailDeletedUserCompany {
    type: 'DELETEUSERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearDeletedUserCompany {
    type: 'CLEARDELETEUSERCOMPANY'
}

//Search UserCompany
interface SearchUserCompany {
    type: 'SEARCH_USERCOMPANY'
}
interface SearchedUserCompany {
    type: 'SEARCHEDUSERCOMPANY'
    response: Models.resGetUserCompany
    status: number,
    statusMessage: string
}
interface FailSearchedUserCompany {
    type: 'SEARCHUSERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearSearchedUserCompany {
    type: 'CLEARSEARCHUSERCOMPANY'
}

// Edit UserCompany
interface EditUserCompany {
    type: 'EDIT_USERCOMPANY'
}
interface EditedUserCompany {
    type: 'EDITEDUSERCOMPANY'
    response: Models.resGetUserCompany
    status: number,
    statusMessage: string
}
interface FailEditedUserCompany {
    type: 'EDITUSERCOMPANYFAIL'
    status: number,
    statusMessage: any
}
interface ClearEditedUserCompany {
    type: 'CLEAREDITUSERCOMPANY'
}

type KnownAction = LoadedUserCompany | FailLoadedUserCompany | LoadUserCompany | AddUserCompany | AddedUserCompany | FailAddedUserCompany | ClearAddedUserCompany | ClearLoadedUserCompany | DeleteUserCompany | DeletedUserCompany | FailDeletedUserCompany | ClearDeletedUserCompany | SearchUserCompany | SearchedUserCompany | FailSearchedUserCompany | ClearSearchedUserCompany | EditUserCompany | EditedUserCompany | FailEditedUserCompany | ClearEditedUserCompany

export const actionCreators = {
    // GET
    requestGetUserCompany: (check: boolean, method: string, sid: string, page: string, page_size: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(sid)
        const appState = getState();
        if (appState && appState.UserCompany && check && method == 'GET') {
            console.log('call actionCreators : requestGetUserCompany')
            fetch(`/v1/user/company?sid=` + sid, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'LOADUSERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'LOADEDUSERCOMPANY', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'LOAD_USERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARLOADEDUSERCOMPANY' });
        }
    },

    // ADD
    requestAddUserCompany: (check: boolean, method: string, Formbody: Models.reqAddUserCompany): AppThunkAction<KnownAction> => (dispatch, getState) => {
        console.log(Formbody)
        const appState = getState();
        if (appState && appState.UserCompany && check && method == 'POST') {
            console.log('call actionCreators : requestAddUserCompany')
            fetch(`/v1/user/company`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Formbody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'ADDUSERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                            dispatch({ type: 'ADDEDUSERCOMPANY', status: 200, statusMessage: '' });
                    }
                })
            
            dispatch({ type: 'ADD_USERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARADDUSERCOMPANY' });
        }
    },

    // Delete
    requestDeleteUserCompany: (check: boolean, method: string, sid: string, citizen_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserCompany && check && method == 'DELETE') {
            console.log('call actionCreators : requestDeleteUserCompany')
            fetch(`/v1/user/company?sid=` + sid + '&citizen_id=' + citizen_id, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'DELETEUSERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'DELETEDUSERCOMPANY', status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'DELETE_USERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEARDELETEUSERCOMPANY' });
        }
    },

    // EDIT
    requestEditUserCompany: (check: boolean, method: string, sid: string, citizen_id: string, btn_status:string ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserCompany && check && method == 'PUT') {
            console.log('call actionCreators : requestEditUserCompany')
            fetch(`/v1/user/company?sid=` + sid + '&citizen_id=' + citizen_id + '&btn_status=' + btn_status, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Accept': 'application/json'
                },
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            console.log(err.message)
                            dispatch({ type: 'EDITUSERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            console.log(data)
                            dispatch({ type: 'EDITEDUSERCOMPANY', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'EDIT_USERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITUSERCOMPANY' });
        }
    },

    // EDIT V2
    requestEditUserCompanyV2: (check: boolean, method: string, reqBoody: Models.UpdateUserCompanyMappingRequest): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.UserCompany && check && method == 'PUT') {
            fetch(`/v2/user/company`, {
                method: 'PUT',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBoody)
            })
                .then((response) => {
                    return response
                })
                .then((data) => {
                    if (!data.ok) {
                        data.json().then(err => {
                            dispatch({ type: 'EDITUSERCOMPANYFAIL', status: 204, statusMessage: err.message });
                        })
                    } else {
                        data.json().then(data => {
                            dispatch({ type: 'EDITEDUSERCOMPANY', response: data, status: 200, statusMessage: '' });
                        })
                    }
                })

            dispatch({ type: 'EDIT_USERCOMPANY' });
        } else if (method == 'CLEAR') {
            dispatch({ type: 'CLEAREDITUSERCOMPANY' });
        }
    },
}

const unloadedStateGet: Models.GetUserCompanyState = { isLoadGetUserCompany: false, statusGetUserCompany : 0 };
const unloadedStateAdd: Models.AddUserCompanyState = { isLoadAddUserCompany: false, statusAddUserCompany : 0};
const unloadedStateDelete: Models.DeleteUserCompanyState = { isLoadDeleteUserCompany: false, statusDeleteUserCompany : 0 };
const unloadedStateEdit: Models.EditUserCompanyState = { isLoadEditUserCompany: false, statusEditUserCompany : 0 };

const reducerGet: Reducer<Models.GetUserCompanyState> = (state: Models.GetUserCompanyState | undefined, incomingAction: Action): Models.GetUserCompanyState => {
    if (state == undefined) {
        return unloadedStateGet;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'LOAD_USERCOMPANY':
            return {
                isLoadGetUserCompany: false,
                statusGetUserCompany: 0
            }

        case 'LOADEDUSERCOMPANY':
            return {
                isLoadGetUserCompany: true,
                responseGetUserCompany: action.response as any,
                statusGetUserCompany: action.status
            }

        case 'LOADUSERCOMPANYFAIL':
            return {
                isLoadGetUserCompany: true,
                messageGetUserCompany: action.statusMessage,
                statusGetUserCompany: action.status
            }

        case 'CLEARLOADEDUSERCOMPANY':
            return {
                isLoadGetUserCompany: false,
                messageGetUserCompany: "",
                statusGetUserCompany: 0
            }
        default: return state;
    }
}

const reducerAdd: Reducer<Models.AddUserCompanyState> = (state: Models.AddUserCompanyState | undefined, incomingAction: Action): Models.AddUserCompanyState => {
    if (state == undefined) {
        return unloadedStateAdd;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'ADD_USERCOMPANY':
            return {
                isLoadAddUserCompany: false,
                statusAddUserCompany : 0
            }

        case 'ADDEDUSERCOMPANY':
            return {
                isLoadAddUserCompany: true,
                statusAddUserCompany: action.status
            }

        case 'ADDUSERCOMPANYFAIL':
            return {
                isLoadAddUserCompany: true,
                messageAddUserCompany: action.statusMessage,
                statusAddUserCompany: action.status
            }

        case 'CLEARADDUSERCOMPANY':
            return {
                isLoadAddUserCompany: false,
                messageAddUserCompany: '',
                statusAddUserCompany: 0
            }
        default: return state;
    }
}

const reducerDelete: Reducer<Models.DeleteUserCompanyState> = (state: Models.DeleteUserCompanyState | undefined, incomingAction: Action): Models.DeleteUserCompanyState => {
    if (state == undefined) {
        return unloadedStateDelete;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'DELETE_USERCOMPANY':
            return {
                isLoadDeleteUserCompany: false,
                statusDeleteUserCompany: 0
            }

        case 'DELETEDUSERCOMPANY':
            return {
                isLoadDeleteUserCompany: true,
                statusDeleteUserCompany: action.status
            }

        case 'DELETEUSERCOMPANYFAIL':
            return {
                isLoadDeleteUserCompany: true,
                messageDeleteUserCompany: action.statusMessage,
                statusDeleteUserCompany: action.status
            }

        case 'CLEARDELETEUSERCOMPANY':
            return {
                isLoadDeleteUserCompany: false,
                messageDeleteUserCompany: '',
                statusDeleteUserCompany: 0
            }
        default: return state;
    }
}

const reducerEdit: Reducer<Models.EditUserCompanyState> = (state: Models.EditUserCompanyState | undefined, incomingAction: Action): Models.EditUserCompanyState => {
    if (state == undefined) {
        return unloadedStateEdit;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        // GET
        case 'EDIT_USERCOMPANY':
            return {
                isLoadEditUserCompany: false,
                statusEditUserCompany : 0
            }

        case 'EDITEDUSERCOMPANY':
            return {
                isLoadEditUserCompany: true,
                responseEditUserCompany: action.response as any,
                statusEditUserCompany: action.status
            }

        case 'EDITUSERCOMPANYFAIL':
            return {
                isLoadEditUserCompany: true,
                messageEditUserCompany: action.statusMessage,
                statusEditUserCompany: action.status
            }

        case 'CLEAREDITUSERCOMPANY':
            return {
                isLoadEditUserCompany: false,
                messageEditUserCompany: '',
                statusEditUserCompany: 0
            }
        default: return state;
    }
}

export const rootReducer = combineReducers({ GetUserCompany: reducerGet, AddUserCompany: reducerAdd, DeleteUserCompany: reducerDelete, EditUserCompany: reducerEdit });