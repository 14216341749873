import axios from 'axios';
import {
    CreateTaskGroupRequest,
    EditTaskGroupRequest,
    SwapTaskGroupIndexRequest,
    TaskGroupCommonResponse,
    TaskGroupMapping
} from '../schemas';

export async function requestGetTaskGroup(filter: string) {
    return axios.get<TaskGroupMapping[]>('v1/task-group', {
        params: {
            filter
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestCreateTaskGroup(params: CreateTaskGroupRequest) {
    return axios.post<TaskGroupCommonResponse>('v1/task-group/create', null, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestEditTaskGroup(params: EditTaskGroupRequest) {
    return axios.put<TaskGroupCommonResponse>('v1/task-group/edit', null, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestDeleteTaskGroup(group_id: string) {
    return axios.post<TaskGroupCommonResponse>('v1/task-group/delete', null, {
        params: {
            group_id
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestSwapTaskGroupIndex(params: SwapTaskGroupIndexRequest) {
    return axios.post<TaskGroupCommonResponse>('v1/task-group/swap-index', null, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
