import { useEffect } from 'react';

import useStroe from '../../../common/hooks/useStore';

function UserApplicationsViewModel() {
    const { userApplicationStore, subDashboardStore } = useStroe();
    const userApplicationsData = userApplicationStore.userApplications;
    const selectedSystem = subDashboardStore.selectedApplication;
    // const { citizenId } = userCompanyStore;

    useEffect(() => {
        reciveUserAppAllData();
    }, []);

    const selectedApplication = userApplicationsData.filter(applicationData => {
        const userapplicationData = applicationData.group_name === selectedSystem;
        return userapplicationData;
    });

    const reciveUserAppAllData = async () => {
        try {
            await userApplicationStore.fetchUserApplications('');
        } catch {}
    };

    return { selectedApplication };
}

export default UserApplicationsViewModel;
