import { useEffect, useState } from 'react';
import axios from 'axios';

function ClientSystemDownloadSCGViewModel() {
    const [isUAT, setIsUAT] = useState(true);
    const downloadFGUIUrl = isUAT
        ? 'https://scgo365.sharepoint.com/:u:/r/sites/RollOutMafuangtoSLSNSLTS/Shared Documents/General/010_Business Assessment/01_As-Is Process/FGUI/SLSN_SLTS_MIG_64Bits.exe?csf=1&web=1&e=8YwoQY&isSPOFile=1&xsdata=MDV8MDJ8fDUyM2E2N2E0NWViMzQxZDM5YTYyMDhkYzE1NzZiZWEzfDVkYjhiZjBlODU5MjRlZDA4MmIyYTZkNGQ3NzkzM2Q0fDB8MHw2Mzg0MDg4NDY5Mjg0ODQ5NjJ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPak0wTXpnd05EZ3dMVFl4TmpFdE5HTmpZUzA1T1RrMkxUUmpaR1EzTVRKaU5UVmpaVjlpWkdRMVpXWTBOQzFsWXpkaUxUUm1aVGd0WW1Ga1lpMWtZMkppTlRWbU16aGtOekpBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN3TlRJNE56ZzVNakkwTmc9PXw4MDZmZDdmNWJmZDk0OTQ3OWE2MjA4ZGMxNTc2YmVhM3xhN2RlODMwZmJkM2Q0ZDQwYmVkYjQ1NTU2OTUzNGJjMQ%3D%3D&sdata=RTVqY0t3VThVUlZFTkx6aHorVFJ5VmpFSTVqbGRkNkEySmdBdzJGYUpWdz0%3D&ovuser=5db8bf0e-8592-4ed0-82b2-a6d4d77933d4%2Cangkana.n%40a-gape.com'
        : 'https://scgo365.sharepoint.com/:f:/s/F1PRODSupport/Eu4hQR_T-qhOjttMikJ_YuQBE_nYkzwWKWj8STtmWiRt_Q';
    const downloadFGUIManualUrl = isUAT
        ? 'https://fgui.s3.ap-southeast-1.amazonaws.com/%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B8%B1%E0%B9%89%E0%B8%87_FocusOne_ERP_UAT.pdf'
        : 'https://fgui.s3.ap-southeast-1.amazonaws.com/%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B8%B1%E0%B9%89%E0%B8%87_FocusOne_ERP_PRD.pdf';

    useEffect(() => {
        fetchIsUAT();
    }, []);

    const fetchIsUAT = async () => {
        const data = (await axios.get('/v1/app-env')).data;

        setIsUAT(data.app_env === 'UAT');
    };

    return {
        isUAT,
        downloadFGUIUrl,
        downloadFGUIManualUrl
    };
}

export default ClientSystemDownloadSCGViewModel;
