import React from 'react';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
>;

function Tr(props: Props) {
    const { children, ...trProps } = props;

    return <tr {...trProps}>{children}</tr>;
}

export default Tr;
