import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useBoolean
} from '@chakra-ui/react';
import { FaReply } from 'react-icons/fa6';

import { ActivityParticipantMessage, EmailContent } from 'models/link/Task';

import { ReplyEmailSupport } from 'models/user/UserSupport';
import { QuillEditor } from 'common/components';
import { extractAccessToken } from 'common/helpers';
import { Task } from 'models/link/Link';

type Props = {
    noReplyButton?: boolean;
    task: Task;
    activity: ActivityParticipantMessage;
    onClickReply: (reply: ReplyEmailSupport) => void;
};

function ParticipantMessage(props: Props) {
    const { noReplyButton, task, activity, onClickReply } = props;

    const [showReplyModal, setShowReplyModal] = useBoolean();

    const email = useMemo(() => {
        if (!activity.data) {
            return {} as EmailContent;
        }

        return JSON.parse(activity.data ?? '') as EmailContent;
    }, [activity.data]);

    const emailFrom = useRef(extractAccessToken().email).current;
    const [emailContent, setEmailContent] = useState(
        () =>
            '<p><br /></p>' +
            '<p>______________________________</p>' +
            '<p><b>From: </b>' +
            email.email_from +
            '</p>' +
            '<p><b>Subject: </b>' +
            email.subject +
            '</p>' +
            '<p><br /></p>' +
            email.content_body
    );

    const [emailCC, setEmailCC] = useState(emailFrom);

    const handleClickReply = useCallback(async () => {
        onClickReply({
            account: {
                sid: 'STD',
                companycode: '1000',
                email: emailFrom
            },
            reply_data: {
                // content_body: emailContent.replace(/<[^>]+>/g, '\n'), // Replace all html tags with new line
                content_body: emailContent,
                email_from: emailFrom,
                mail_to_list: [email.email_from],
                // mail_cc_list: Array.from(new Set([...email.mail_cc_list, emailCC])),
                mail_cc_list: [''],
                object_link: email.object_link,
                ref_email_code: email.ref_email_code,
                subject: email.subject,
                system_id: email.system_id,
                type: ''
            }
        });
    }, [emailFrom, emailContent, email, onClickReply]);

    return (
        <React.Fragment>
            <div className="rounded-md border border-primary-900 bg-primary-50 px-2">
                <div className="py-2">
                    <div dangerouslySetInnerHTML={{ __html: email.content_body }} />

                    {!noReplyButton && (
                        <Button
                            size="sm"
                            onClick={setShowReplyModal.on}
                            className="ml-auto flex items-center gap-2 bg-primary-900 text-white"
                        >
                            <FaReply />
                            <span>Reply</span>
                        </Button>
                    )}
                </div>

                {/* Separator */}
                <div className="border-t border-zinc-300" />

                {activity.files && activity.files.length > 0 && (
                    <div className="py-2">
                        <p className="text-sm">Attachments</p>
                        <div className="flex flex-wrap gap-2">
                            {activity.files &&
                                activity.files.map(file => {
                                    switch (file.type) {
                                        case 'FILE':
                                            return (
                                                <div
                                                    key={file.key}
                                                    className="rounded-md border border-zinc-300 bg-neutral-50 px-2"
                                                >
                                                    <p>
                                                        {file.url.slice(
                                                            file.url.lastIndexOf('/') + 1
                                                        )}
                                                    </p>
                                                </div>
                                            );
                                        case 'IMAGE':
                                            return (
                                                <img
                                                    key={file.key}
                                                    src={file.url}
                                                    alt=""
                                                    className="w-36"
                                                />
                                            );
                                    }
                                })}
                        </div>
                    </div>
                )}
            </div>

            <Modal
                size="6xl"
                isOpen={showReplyModal}
                onClose={setShowReplyModal.off}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Reply <span className="text-primary-900">{task.ticketNO}</span>
                    </ModalHeader>
                    <ModalBody className="space-y-4">
                        {/* <div>
                            <Label>E-mail from</Label>
                            <Input
                                disabled
                                placeholder="E-mail from"
                                value={emailFrom}
                            />
                        </div>

                        <div>
                            <Label>E-mail to</Label>
                            <Input
                                disabled
                                placeholder="E-mail to"
                                value={email.email_from}
                            />
                        </div> */}

                        {/* <div>
                            <Label>E-mail CC</Label>
                            <Input
                                placeholder="E-mail CC"
                                value={emailCC}
                                onChange={event => setEmailCC(event.target.value)}
                            />
                        </div> */}

                        <QuillEditor
                            value={emailContent}
                            onChange={setEmailContent}
                            editorClassName="[&>.ql-container]:min-h-[250px]"
                        />
                    </ModalBody>
                    <ModalFooter className="gap-2">
                        <Button
                            size="sm"
                            className="bg-primary-900 leading-none text-white"
                            onClick={setShowReplyModal.off}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="sm"
                            variant="outline"
                            onClick={handleClickReply}
                            className="border-primary-900 leading-none text-primary-900"
                        >
                            Send
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </React.Fragment>
    );
}

export default memo(ParticipantMessage);
