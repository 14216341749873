import { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import {
    Badge,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    TableContainer
} from '@chakra-ui/react';

import { UserCompany } from 'models/user/UserCompany';
import { UserCompanyMappingLog } from 'models/user/UserCompanyMappingLog';
import { ResponseList } from 'models';
import { Button, Table, Thead, Tbody, Td, Th, Tr } from 'common/materials';
import Swal from 'sweetalert2';

type Props = {
    isOpen: boolean;
    selectedUser?: UserCompany;
    onClose: () => void;
};

const STATUS_MAP = {
    S: 'Success',
    E: 'Error'
} as const;

const BADGE_STYLE_MAP = {
    S: 'green',
    E: 'red'
} as const;

function LogsModal(props: Props) {
    const { isOpen, selectedUser, onClose } = props;

    const [fetching, setFetching] = useState(false);
    const [logs, setLogs] = useState<UserCompanyMappingLog[]>();

    useEffect(() => {
        if (!isOpen || !selectedUser) {
            setLogs(undefined);
            return;
        }

        fetchUserCompanyMappingLogs(selectedUser.sid, selectedUser.citizen_id);
    }, [isOpen]);

    const fetchUserCompanyMappingLogs = async (sid: string, citizenId: string) => {
        try {
            setFetching(true);

            const data = (
                await axios.get<ResponseList<UserCompanyMappingLog>>('/v1/user/company/log', {
                    params: {
                        sid,
                        citizen_id: citizenId
                    }
                })
            ).data;

            setLogs(data.result_list);
        } catch (error) {
            console.log(fetchUserCompanyMappingLogs.name, error);

            if (error instanceof AxiosError) {
                const data = JSON.parse(error.response?.data);

                Swal.fire('Error!', data.message, 'error');
            }
        } finally {
            setFetching(false);
        }
    };

    return (
        <Modal
            size="5xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="flex flex-col">
                    <span className="text-base text-primary-900">{selectedUser?.company_name}</span>
                    <span>
                        <span className="text-primary-900">{selectedUser?.citizen_id}</span> Logs
                    </span>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody className="flex flex-col gap-2">
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Action</Th>
                                    <Th>With</Th>
                                    <Th>Status</Th>
                                    <Th>Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {fetching && (
                                    <Tr>
                                        <Td
                                            colSpan={4}
                                            className="text-center"
                                        >
                                            {<Spinner />}
                                        </Td>
                                    </Tr>
                                )}

                                {logs?.map(log => (
                                    <Tr key={log.id}>
                                        <Td>{log.action}</Td>
                                        <Td>{log.with}</Td>
                                        <Td>
                                            <Badge
                                                variant="subtle"
                                                colorScheme={BADGE_STYLE_MAP[log.status] ?? 'gray'}
                                                className="rounded-full px-2 py-1 text-xs"
                                            >
                                                {STATUS_MAP[log.status] ?? 'Unknown'}
                                            </Badge>
                                        </Td>
                                        <Td>
                                            {log.created_on &&
                                                new Date(log.created_on).toLocaleString('th-TH')}
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter className="gap-4">
                    <Button
                        outline
                        size="sm"
                        className="text-gray-400"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default LogsModal;
