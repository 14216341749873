import React, { createContext, PropsWithChildren, useState } from 'react';
import { UserApplicationMapping } from '../../models/dashboard/SubDashboardComponentV2';

export type UserApplicationContextType = {
    userApplications: UserApplicationMapping[];
    setUserApplication: (payload: UserApplicationMapping[]) => void;
};

export const UserApplicationContext = createContext<UserApplicationContextType>(
    {} as UserApplicationContextType
);

function UserApplicationProvider({ children }: PropsWithChildren<{}>) {
    const [userApplicationMapping, setUserApplicationMapping] = useState<
        UserApplicationMapping[]
    >([]);

    const userApplications = userApplicationMapping;

    const setUserApplication = (payload: UserApplicationMapping[]) => {
        setUserApplicationMapping(payload);
    };

    return (
        <UserApplicationContext.Provider
            value={{ userApplications, setUserApplication }}
        >
            {children}
        </UserApplicationContext.Provider>
    );
}

export default UserApplicationProvider;
