import { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useStore } from 'common/hooks';
import { Button, Input, Label } from 'common/materials';
import { UserCompany } from 'models/user/UserCompany';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onSelectUser: (user: UserCompany) => void;
};

function SelectUserModal(props: Props) {
    const { isOpen, onClose, onSelectUser } = props;

    const { userCompanyStore } = useStore();
    const { userCompanyMapping } = userCompanyStore;

    const [searchCitizenId, setSearchCitizenId] = useState('');
    const deferredSearchCitizenId = useDeferredValue(searchCitizenId);

    const distinctedUserCompanyMapping = useMemo(() => {
        const seenCitizenId = new Set<string>();

        return userCompanyMapping.filter(user => {
            if (seenCitizenId.has(user.citizen_id)) {
                return false;
            } else {
                seenCitizenId.add(user.citizen_id);
                return true;
            }
        });
    }, [userCompanyMapping]);
    const filteredDistinctedUserCompanyMapping = useMemo(() => {
        return distinctedUserCompanyMapping.filter(userCompany => {
            return userCompany.citizen_id
                .toLowerCase()
                .includes(deferredSearchCitizenId.toLowerCase());
        });
    }, [distinctedUserCompanyMapping, deferredSearchCitizenId]);

    const handleChangeSearchCitizenId = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchCitizenId(event.target.value);
        },
        []
    );

    const handleCloseModal = useCallback(() => {
        setSearchCitizenId('');
        onClose();
    }, [onClose]);

    const handleSelectUser = useCallback(
        (user: UserCompany) => {
            onSelectUser(user);
            onClose();
        },
        [onSelectUser, onClose]
    );

    return (
        <Modal
            size="xl"
            isOpen={isOpen}
            onClose={handleCloseModal}
        >
            <ModalOverlay />
            <ModalContent className="h-[75dvh]">
                <ModalHeader>Select User</ModalHeader>
                <ModalCloseButton />
                <ModalBody className="flex flex-col gap-4">
                    <div>
                        <Label>Search user</Label>
                        <Input
                            placeholder="Citizen ID"
                            value={searchCitizenId}
                            onChange={handleChangeSearchCitizenId}
                        />
                    </div>
                    <div className="flex-1">
                        <AutoSizer>
                            {({ width, height }) => (
                                <FixedSizeList
                                    width={width}
                                    height={height}
                                    itemData={filteredDistinctedUserCompanyMapping}
                                    itemCount={filteredDistinctedUserCompanyMapping.length}
                                    itemSize={42}
                                    overscanCount={20}
                                    className="[&_>_div]:divide-y"
                                >
                                    {({ index, style, data: users }) => {
                                        const user = users[index];

                                        return (
                                            <div
                                                style={style}
                                                className="flex items-center justify-between p-2"
                                            >
                                                <span>{user.citizen_id}</span>
                                                <Button
                                                    onClick={() => handleSelectUser(user)}
                                                    className="bg-primary-900 text-white"
                                                >
                                                    Select
                                                </Button>
                                            </div>
                                        );
                                    }}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        size="sm"
                        className="text-gray-400"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default observer(SelectUserModal);
