import React from 'react';

import { IoMdDownload } from 'react-icons/io';

import ClientSystemDownloadSCGViewModel from './ClientSystemDownloadSCG.viewmodel';

function ClientSystemDownloadSCG() {
    const { isUAT, downloadFGUIUrl, downloadFGUIManualUrl } = ClientSystemDownloadSCGViewModel();

    const environmentFlag = isUAT ? 'UAT' : 'PRD';

    return (
        <div className="flex flex-col items-start">
            <div className="flex flex-1 items-center">
                <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black">
                    <span className="text-[20px] font-medium leading-6 text-white">1</span>
                </div>
                <span className="ml-[10px]">Download FGUI เพื่อติดตั้งที่เครื่อง Client</span>
            </div>
            <div className="flex flex-col pl-[50px]">
                <a
                    href={downloadFGUIUrl}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                >
                    <div className="bg-light mt-3 flex items-center gap-2 rounded-lg border-2 p-3 transition-colors hover:border-primary-900 hover:text-primary-900 active:bg-primary-50/30">
                        <IoMdDownload size={20} />
                        <span>กดเพื่อ Download FGUI {environmentFlag} for Windows 64 bit</span>
                    </div>
                </a>
            </div>
            <div className="mt-10 flex flex-col flex-1">
                <div className="flex items-center">
                    <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-black">
                        <span className="text-[20px] font-medium leading-6 text-white">2</span>
                    </div>
                    <span className="ml-[10px]">
                        Download Crystal Reports 13.0 Runtime for Windows เพื่อติดตั้งที่เครื่อง Client
                    </span>
                </div>
                <div className="flex flex-col pl-[50px]">
                    <a
                        href={'https://fgui.s3.ap-southeast-1.amazonaws.com/CRRuntime_64bit_13_0_25.msi'}
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                    >
                        <div className="bg-light mt-3 flex items-center gap-2 rounded-lg border-2 p-3 transition-colors hover:border-primary-900 hover:text-primary-900 active:bg-primary-50/30">
                            <IoMdDownload size={20} />
                            <span>กดเพื่อ Download Crystal Reports 13.0 Runtime for Windows 64 bit</span>
                        </div>
                    </a>
                    <a
                        href={'https://fgui.s3.ap-southeast-1.amazonaws.com/CRRuntime_32bit_13_0_25.msi'}
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                    >
                        <div className="bg-light mt-3 flex items-center gap-2 rounded-lg border-2 p-3 transition-colors hover:border-primary-900 hover:text-primary-900 active:bg-primary-50/30">
                            <IoMdDownload size={20} />
                            <span>กดเพื่อ Download Crystal Reports 13.0 Runtime for Windows 32 bit</span>
                        </div>
                    </a>
                </div>
            </div>

            <div className="mt-[20px] flex flex-col pl-[50px]">
                <div className="w-3/4 border-b border-b-gray-600" />
                <div className="flex flex-col ml-[10px] mt-[10px]">
                    <a
                        href={downloadFGUIManualUrl}
                        target="_blank"
                        className="font-bold text-blue-700 underline"
                    >
                        กดเพื่อดาวโหลดเอกสารคู่มือวิธีการติดตั้ง focusOne Client Application{' '}
                        {environmentFlag}
                    </a>
                    <a
                        href={'https://fgui.s3.ap-southeast-1.amazonaws.com/%E0%B8%84%E0%B8%B9%E0%B9%88%E0%B8%A1%E0%B8%B7%E0%B8%AD_Install_Crystal_Report.pdf'}
                        target="_blank"
                        className="font-bold text-blue-700 underline"
                    >
                        กดเพื่อดาวโหลดเอกสารคู่มือวิธีการติดตั้ง Crystal Report
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ClientSystemDownloadSCG;
