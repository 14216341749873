import { memo, useCallback, useEffect, useRef, useState } from 'react';

import Tab from './Tab';
import { useDynamicRefs } from 'common/hooks';

type OneElementAtLeastArray<T> = readonly [T, ...T[]];

type Option = {
    label: string;
    value: string;
};

type Props<T extends OneElementAtLeastArray<Option>> = {
    /** Tabs with guarantee 1 element at least */
    tabs: T;
    value?: T[number]['value'];
    onClick?: (value: T[number]['value']) => void;
};

function Tabs<T extends OneElementAtLeastArray<Option>>(props: Props<T>) {
    const { tabs, value, onClick } = props;

    const [getRef, setRef] = useDynamicRefs();

    const containerRef = useRef<HTMLDivElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);

    const [selectedValue, setSelectedValue] = useState(value || tabs[0].value);

    // Sync prop with state
    useEffect(() => {
        if (value !== selectedValue) {
            setSelectedValue(value || tabs[0].value);
        }
    }, [value]);

    // Change indicator position when value changes
    useEffect(() => {
        animateIndicator(getRef<HTMLButtonElement>(selectedValue).current);
    }, [tabs, selectedValue]);

    const getRelativePosition = (parentElement: HTMLElement, childElement: HTMLElement) => {
        const parentRect = parentElement.getBoundingClientRect();
        const childRect = childElement.getBoundingClientRect();

        return {
            top: childRect.top - parentRect.top,
            left: childRect.left - parentRect.left,
            width: childRect.width,
            height: childRect.height
        };
    };

    const animateIndicator = (targetTab: HTMLButtonElement | null) => {
        const container = containerRef.current;
        const indicator = indicatorRef.current;
        if (!container || !indicator || !targetTab) return;

        const position = getRelativePosition(containerRef.current, targetTab);
        indicator.style.left = position.left + 'px';
        indicator.style.width = position.width + 'px';
    };

    const handleClickTab = useCallback(
        (value: T[number]['value']) => {
            setSelectedValue(value);
            onClick?.(value);
        },
        [onClick]
    );

    return (
        <div
            ref={containerRef}
            className="relative flex border-b"
        >
            {tabs.map(tab => (
                <Tab
                    key={tab.label}
                    ref={setRef(tab.value)}
                    active={selectedValue === tab.value}
                    label={tab.label}
                    value={tab.value}
                    onClick={handleClickTab}
                />
            ))}

            <div
                ref={indicatorRef}
                className="absolute bottom-0 h-[2px] bg-primary-900 transition-[left,width]"
            />
        </div>
    );
}

export default memo(Tabs) as typeof Tabs;
