import { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

// Video
const Video = Quill.import('formats/video');

class CoustomVideo extends Video {
    static create(value: any) {
        const node = super.create(value);

        const video = document.createElement('video');
        video.setAttribute('controls', 'true');
        video.setAttribute('type', 'video/mp4');
        video.setAttribute('style', 'height: 200px; width: 100%');
        video.setAttribute('src', value);
        node.appendChild(video);

        return node;
    }
}

CoustomVideo.blotName = 'video';
CoustomVideo.className = 'ql-video';
CoustomVideo.tagName = 'DIV';

Quill.register('formats/video', CoustomVideo);
Quill.register('modules/imageResize', ImageResize);

// Divider
const BlockEmbed = Quill.import('blots/block/embed');

class DividerBlot extends BlockEmbed {}
DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';

export const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video'
];
