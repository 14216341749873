
export default {
    statusActive: "#40afcc",
    statusPending: "#e0b078",
    statusInactive: "#e46970",
    statusRejected: "#C43D56",

    btnStatusActive: "#93daec",
    btnStatusPending: "#f1d79d",
    btnStatusInactive: "#e46970",
    btnStatusRejected: "#ff9090"
};