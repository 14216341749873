import React from 'react';
import Select from 'react-select';

import { Button } from '@chakra-ui/react';

import { Tabs } from 'common/materials';
import CancelTaskModal from 'components/CancelTask/CancelTaskModal';
import CloseTaskModal from 'components/CloseTask/CloseTaskModal';
import EditAnnouncementModal from 'components/EditAnnouncement/EditAnnounceModal';
import EditTaskModal from 'components/EditTask/EditTaskModal';
import NewAnnouncementModal from 'components/NewAnnouncement/NewAnnouncementModal';
import NewTaskModal from 'components/NewTask/NewTaskModal';
import ReleaseTaskModal from 'components/ReleaseTask/ReleaseTaskModal';
import TaskGroupModal from 'components/TaskGroup/TaskGroupModal';

import {
    ImageGalleryModal,
    PostActivity,
    UserEventStatus,
    UserCommentEvent,
    PostDetailModal
} from './components';
import FeedTaskViewModel from './FeedTask.viewmodel';

export default function FeedTask() {
    const {
        tabs,
        liked,
        userType,
        taskList,
        taskStack,
        taskGroups,
        profilePic,
        postCommnet,
        selectedTab,
        keyIdComment,
        selectedTask,
        taskGroupList,
        selectedImages,
        showImageIndex,
        userEventStatus,
        showModalNewTask,
        announcementList,
        showEditTaskModal,
        selectedTaskGroup,
        announcementGroups,
        showUserEventModal,
        showTaskGroupModal,
        showCloseTaskModal,
        showCancelTaskModal,
        showUserCommentModal,
        showReleaseTaskModal,
        selectedTaskActivity,
        showNewAnnouncementModal,
        showEditAnnouncementModal,
        scrollToComment,
        // fetchTaskInbox,

        handleEditTask,
        fetchTaskGroup,
        handleReadmore,
        handleChangeTab,
        setSelectedTask,
        handleCloseTask,
        onSubmitComment,
        handleCancelTask,
        handleReleaseTask,
        setSelectedImages,
        setShowImageIndex,
        removeSelectedTask,
        handleEventComment,
        handleEventElement,
        onClickShowUserEvent,
        handleFetchTaskStore,
        handleChangeTaskGroup,
        handleEditAnnouncement,
        onClickShowCommentEvent,
        toggleShowUserEventModal,
        toggleShowTaskGroupModal,
        toggleShowUserCommentModal,
        toggleNewAnnouncementModal,
        toggleShowModalNewTaskPopup,
        setScrollToComment
    } = FeedTaskViewModel();

    const selectedGroups =
        selectedTab.toLowerCase() === 'announcement' ? announcementGroups : taskGroups;
    const taskType = selectedTab.toLowerCase() === 'announcement' ? 'announcement' : 'task';

    return (
        <div className="flex flex-col gap-y-2.5 px-3">
            <p className="text-2xl font-bold">Announcement Board</p>
            <div className="flex flex-wrap items-center gap-y-4 py-2">
                <div className="flex-grow-1 flex items-center gap-4 rounded-sm bg-white">
                    <span className="w-auto text-sm">Group by</span>
                    <Select
                        className="w-[300px]"
                        onChange={handleChangeTaskGroup}
                        options={taskGroupList}
                        value={selectedTaskGroup}
                    />
                    {userType === 'super_admin' && (
                        <Button
                            size="sm"
                            className="rounded-md bg-primary-900 text-xs text-white hover:bg-primary-800"
                            onClick={toggleShowTaskGroupModal}
                        >
                            Manage Group
                        </Button>
                    )}
                </div>

                {userType === 'super_admin' && (
                    <div className="ml-auto flex gap-4">
                        <Button
                            size="sm"
                            className="rounded-md bg-primary-900 text-xs text-white hover:bg-primary-800"
                            onClick={toggleNewAnnouncementModal}
                        >
                            New Announcement
                        </Button>

                        <Button
                            size="sm"
                            className="rounded-md bg-primary-900 text-xs text-white hover:bg-primary-800"
                            onClick={toggleShowModalNewTaskPopup}
                        >
                            New Information Update
                        </Button>
                    </div>
                )}
            </div>

            {userType === 'super_admin' && (
                <div className="flex flex-row">
                    <Tabs
                        values={tabs}
                        defaultSelectedValue={selectedTab}
                        onChangeTab={handleChangeTab}
                    />
                </div>
            )}

            {selectedGroups.length > 0 && (
                <div className="mt-2 flex-row space-y-4">
                    {selectedGroups.map((group, index) => (
                        <React.Fragment key={index}>
                            <div>
                                <p className="text-2xl font-medium">
                                    {group.month} {group.year}
                                </p>
                            </div>

                            <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                                {group.tasks.map(task => (
                                    <PostActivity
                                        key={task.key_id}
                                        taskType={taskType}
                                        task={task}
                                        liked={liked!}
                                        userType={userType}
                                        taskStack={taskStack!}
                                        taskGroupList={
                                            taskType === 'task' ? taskGroupList : undefined
                                        }
                                        onClickShowUserEvent={onClickShowUserEvent}
                                        onClickShowCommentEvent={onClickShowCommentEvent}
                                        onClickReadMore={(task, imageUris, options) => {
                                            if (options?.scrollToComment) {
                                                setTimeout(() => setScrollToComment(true), 50);
                                            }

                                            setSelectedTask(task);
                                            setShowImageIndex(-1);
                                            setSelectedImages(imageUris ?? []);
                                            handleReadmore(task.key_id);
                                        }}
                                        onClickImage={(index, imageUris) => {
                                            setShowImageIndex(index);
                                            setSelectedImages(imageUris);
                                        }}
                                        onClickCloseTask={handleCloseTask}
                                        onClickReleaseTask={handleReleaseTask}
                                        onClickCancelTask={handleCancelTask}
                                        onClickEditTask={task => {
                                            if (task.type.toLocaleLowerCase() === 'task') {
                                                handleEditTask(task);
                                            } else {
                                                handleEditAnnouncement(task);
                                            }
                                        }}
                                        onHandleEventElement={handleEventElement}
                                    />
                                ))}

                                {index === taskGroups.length - 1 && taskList.length > 0 && (
                                    <div className="flex h-[350px] items-center justify-center">
                                        <Button
                                            onClick={() =>
                                                // fetchTaskInbox('all', { nextFeed: true })
                                                handleFetchTaskStore()
                                            }
                                        >
                                            Load more
                                        </Button>
                                    </div>
                                )}

                                {selectedImages.length > 0 && showImageIndex >= 0 && (
                                    <ImageGalleryModal
                                        sources={selectedImages}
                                        initialIndex={showImageIndex}
                                        onClose={() => {
                                            setShowImageIndex(0);
                                            setSelectedImages([]);
                                        }}
                                    />
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}

            <PostDetailModal
                scrollToComment={scrollToComment}
                selectedTask={selectedTask}
                selectedImages={selectedImages}
                profilePic={profilePic}
                keyId={keyIdComment}
                comments={postCommnet}
                submitComment={onSubmitComment}
                handleEventComment={handleEventComment}
                removeSelectedTask={removeSelectedTask}
            />
            <TaskGroupModal
                isOpen={showTaskGroupModal}
                onClose={toggleShowTaskGroupModal}
                onRefresh={() => fetchTaskGroup()}
            />
            <NewTaskModal
                isOpen={showModalNewTask}
                onClose={toggleShowModalNewTaskPopup}
                // onRefresh={() => fetchTaskInbox('all', { nextFeed: false })}
                onRefresh={() => handleFetchTaskStore()}
                taskGroupList={taskGroupList.slice(1)}
            />
            <NewAnnouncementModal
                isOpen={showNewAnnouncementModal}
                onClose={toggleNewAnnouncementModal}
            />
            <CloseTaskModal
                isOpen={showCloseTaskModal}
                onClose={() => handleCloseTask()}
                selectedTask={selectedTaskActivity}
            />
            <ReleaseTaskModal
                isOpen={showReleaseTaskModal}
                onClose={() => handleReleaseTask()}
                onRefresh={() => handleFetchTaskStore()}
                selectedTask={selectedTaskActivity}
            />
            <CancelTaskModal
                isOpen={showCancelTaskModal}
                onClose={() => handleCancelTask()}
                selectedTask={selectedTaskActivity}
            />
            <EditTaskModal
                isOpen={showEditTaskModal}
                onClose={() => handleEditTask()}
                // onRefresh={() => fetchTaskInbox('all', { nextFeed: false })}
                onRefresh={() => handleFetchTaskStore()}
                selectedTask={selectedTaskActivity}
                taskGroupList={taskGroupList.slice(1)}
            />
            <EditAnnouncementModal
                isOpen={showEditAnnouncementModal}
                onClose={() => handleEditAnnouncement()}
                // onRefresh={() => fetchTaskInbox('all', { nextFeed: false })}
                onRefresh={() => handleFetchTaskStore()}
                selectedAnnouncement={selectedTaskActivity}
            />
            <UserEventStatus
                isOpen={showUserEventModal}
                userEventType={userEventStatus}
                onClose={toggleShowUserEventModal}
            />
            <UserCommentEvent
                isOpen={showUserCommentModal}
                onClose={toggleShowUserCommentModal}
                keyId={keyIdComment!}
                comment={postCommnet!}
                submitComment={onSubmitComment}
                handleEventComment={handleEventComment}
            />
        </div>
    );
}
