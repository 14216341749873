import { memo } from 'react';
import { Kbd, TableContainer } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { twMerge } from 'tailwind-merge';

import { Badge, Table, Tbody, Td, Th, Thead, Tr } from 'common/materials';
import { useVirtualTableScrollbar } from 'common/hooks';
import { VirtualTableScrollbar } from 'common/components';
import { ApplicationHistory } from 'models/history/ApplicationHistory';

type Props = {
    appHistory?: ApplicationHistory[];
};

function ApplicationHistoryTable(props: Props) {
    const { appHistory } = props;

    const { tableContainerRef, virtualTableContainerRef, virtualTableRef, tableRefCallback } =
        useVirtualTableScrollbar();

    return (
        <div>
            <p className="text-right text-sm text-primary-900">
                Tips: สามารถกดปุ่ม <Kbd className="text-neutral-950">Shift</Kbd>{' '}
                ค้างและหมุนลูกกลิ้งเมาส์เพื่อเลื่อนตารางในแนวนอนได้
            </p>

            <VirtualTableScrollbar
                virtualTableContainerRef={virtualTableContainerRef}
                virtualTableRef={virtualTableRef}
            />
            <TableContainer
                ref={tableContainerRef}
                className="mt-1"
            >
                <Table ref={tableRefCallback}>
                    <Thead>
                        <Tr>
                            <Th>Mode</Th>
                            <Th>SID</Th>
                            <Th>Application</Th>
                            <Th>User</Th>
                            <Th>Action</Th>
                            <Th>Action Result</Th>
                            <Th>Action Message</Th>
                            <Th>Timestamp</Th>
                            <Th>IP Address</Th>
                            <Th>Client IP Address</Th>
                            <Th>Key UUID</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {!appHistory || appHistory?.length === 0 ? (
                            <Tr className="text-center">
                                <Td colSpan={9}>No data</Td>
                            </Tr>
                        ) : (
                            appHistory.map(log => {
                                const formattedDate = dayjs(log.timestamp).format(
                                    'DD/MM/YYYY HH:mm:ss น.'
                                );

                                return (
                                    <Tr key={log.action_id}>
                                        <Td>
                                            <Badge
                                                className={twMerge(
                                                    'w-[50px] border bg-white text-center',
                                                    log.mode === 'gui'
                                                        ? 'border-primary-700 text-primary-700'
                                                        : 'border-blue-700 text-blue-700'
                                                )}
                                            >
                                                <span className="relative top-[1px]">
                                                    {log.mode.toUpperCase()}
                                                </span>
                                            </Badge>
                                        </Td>
                                        <Td className="underline">{log.sid}</Td>
                                        <Td className="underline">{log.application}</Td>
                                        <Td>{log.citizen_id}</Td>
                                        <Td>{log.action}</Td>
                                        <Td>
                                            <Badge
                                                className={twMerge(
                                                    'w-full rounded-md text-center text-white',
                                                    log.action_response === 'success'
                                                        ? 'bg-success-500'
                                                        : 'bg-danger-500'
                                                )}
                                            >
                                                {log.action_response}
                                            </Badge>
                                        </Td>
                                        <Td>{log.action_message}</Td>
                                        <Td>{formattedDate}</Td>
                                        <Td>{log.ip_address}</Td>
                                        <Td>{log.client_ip_address}</Td>
                                        <Td>{log.key_uuid}</Td>
                                    </Tr>
                                );
                            })
                        )}
                    </Tbody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default memo(ApplicationHistoryTable);
