import React, { memo, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ImHammer2 } from 'react-icons/im';
import { FaEdit } from 'react-icons/fa';
import { PiUserCircleFill } from 'react-icons/pi';
import { IoCloseCircle, IoArrowUpCircle, IoArrowDownCircle } from 'react-icons/io5';

import { QuillEditor } from 'common/components';
import { FileInfo, TaskStore } from 'models/taskStore/Task';

type Props = {
    task: TaskStore;
    taskType: string;
    userType?: string;
    model: string;
    setModel: React.Dispatch<React.SetStateAction<string>>;
    posterBackground: string;
    className?: string;
};

function PosterPreview(props: Props) {
    const {
        taskType = '',
        userType = '',
        task,
        className = '',
        model,
        setModel,
        posterBackground
    } = props;

    const {
        is_release,
        subject,
        description: detail,
        created_by,
        updated_on,
        attachment,
        mode
    } = task;
    const updatedOnDate = new Date(Date.parse(updated_on));

    const detailElementRef = useRef<HTMLParagraphElement>(null);

    const [showReadMore, setShowReadMore] = useState(false);

    const [profilePic, setProfilePic] = useState<FileInfo>();

    useEffect(() => {
        const element = detailElementRef.current;

        if (!element) {
            return;
        }

        // Computed style needed
        const styles = window.getComputedStyle(element);
        const lineHeight = parseInt(styles.lineHeight);

        // Exit function if lines less than or equal 3 compare to client height (box size)
        if (element.clientHeight <= lineHeight * 3) {
            return;
        }

        // Clamp line if more than 3 lines
        element.classList.add('line-clamp-3');
        setShowReadMore(true);
    }, []);

    return (
        <div
            className={twMerge(
                'rounded-md bg-white p-2 drop-shadow-md',
                `${className} ${is_release}`,
                is_release && userType === 'super_admin' ? 'border-2 border-green-300' : 'border'
            )}
        >
            <div className="flex w-full items-center justify-between">
                <p className="flex-1 pl-4 text-primary-900">
                    {`${updatedOnDate.getDate()}/${
                        updatedOnDate.getMonth() + 1
                    }/${updatedOnDate.getFullYear()}`}{' '}
                    {`${updatedOnDate.toLocaleTimeString('th-TH')} น.`}
                </p>

                {userType === 'super_admin' && (
                    <div className="ml-auto flex rounded border [&>span:not(:first-child)]:border-l">
                        <span className="hidden cursor-pointer px-2 py-0.5 transition-all hover:scale-150 hover:text-amber-800">
                            <ImHammer2 size={20} />
                        </span>

                        <span
                            className={twMerge(
                                'cursor-pointer px-2 py-0.5 transition-all hover:shadow-inner',
                                is_release
                                    ? 'hover:text-rose-600 hover:shadow-red-400'
                                    : 'hover:text-green-600 hover:shadow-green-400'
                            )}
                        >
                            {is_release ? (
                                <IoArrowDownCircle
                                    size={20}
                                    color="red"
                                />
                            ) : (
                                <IoArrowUpCircle
                                    size={20}
                                    color="green"
                                />
                            )}
                        </span>

                        <span className="cursor-pointer px-2 py-0.5 transition-all hover:text-rose-600 hover:shadow-inner hover:shadow-red-400">
                            <IoCloseCircle size={20} />
                        </span>
                        <span className="cursor-pointer px-2 py-0.5 transition-all hover:text-amber-600 hover:shadow-inner hover:shadow-amber-400">
                            <FaEdit size={20} />
                        </span>
                    </div>
                )}
            </div>

            <div className="flex h-[350px] flex-col gap-2 p-2">
                <div
                    className="flex h-[280px] flex-col justify-start rounded-lg"
                    style={
                        posterBackground && posterBackground.includes('blob')
                            ? {
                                  background: `center no-repeat url('${posterBackground}')`,
                                  backgroundSize: '100%'
                              }
                            : posterBackground.includes('https')
                            ? {
                                  background: `center no-repeat url('${encodeURI(
                                      posterBackground
                                  )}')`,
                                  backgroundSize: '100% auto'
                              }
                            : { backgroundColor: posterBackground }
                    }
                >
                    {/* <span
                        ref={detailElementRef}
                        className="mt-3 flex-1 overflow-hidden whitespace-pre-wrap break-words px-2 text-sm text-neutral-500"
                        dangerouslySetInnerHTML={{
                            __html: poster_subject ? poster_subject : ''
                        }}
                    /> */}
                    {/* <div className="mt-3 flex flex-1 overflow-hidden whitespace-pre-wrap break-words rounded-lg border-2 px-2 text-sm text-neutral-500"> */}
                    <div
                        className={twMerge(
                            'mt-3 flex flex-1 overflow-hidden whitespace-pre-wrap break-words rounded-lg px-2 text-sm text-neutral-500',
                            posterBackground ? '' : 'border-2'
                        )}
                        ref={detailElementRef}
                    >
                        <QuillEditor
                            value={model}
                            onChange={setModel}
                        />
                    </div>
                    {showReadMore && (
                        <span className="z-10 mt-2 cursor-pointer px-2 text-[12px] font-medium underline">
                            Read more
                        </span>
                    )}
                    {taskType === 'task' && (
                        <div className="mt-auto flex justify-end">
                            {profilePic ? (
                                <div className="bg h-[60px] w-[60px] rounded-full">
                                    <img
                                        key={profilePic.file_name}
                                        src={profilePic.file_path}
                                        className="h-[60px] w-[60px] rounded-full p-1.5"
                                    />
                                </div>
                            ) : (
                                <div className="bg h-[60px] w-[60px] rounded-full bg-white">
                                    <PiUserCircleFill
                                        style={{
                                            width: '60px',
                                            height: '60px',
                                            color: '#A1A1A1'
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="h-[50px] flex-1 flex-col justify-end border-t"></div>
            </div>
        </div>
    );
}

export default memo(PosterPreview);
