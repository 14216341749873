import { makeAutoObservable, runInAction } from 'mobx';
import { getLog } from '../../data/log/repositories/logRepository';
import { Log, RequestLog } from '../../models/log/Log';
import RootStore from '../Root.store';

class LogStore {
    private _log: Log[] = [];

    public get log() {
        return this._log;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchLogStore(searchLog: RequestLog) {
        const [error, responesLog] = await getLog(searchLog);
        if (error) {
            throw error;
        }
        runInAction(() => {
            this._log = responesLog;
        });
    }
}

export default LogStore;
