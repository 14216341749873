import { TaskInbox } from '../../../models/link/Task';
import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from '../../../models';
import {
    getAll,
    reqestTaskInbox,
    requestCancelTask,
    requestCloseTask,
    requestLinkTask,
    requestTaskActivity
} from '../datasources/task.datasource';
import {
    GetTaskSchedulResponse,
    GetTaskScheduleRequest,
    LinkMapping,
    TaskActivity,
    TaskActivityRequest
} from '../schemas';
import { Task } from '../../../models/link/Link';

export async function getTasksSchedule(
    params: GetTaskScheduleRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<LinkMapping>>> {
    try {
        const data = (await getAll(params)).data;
        //console.log('[Debug] getAll success', data);

        return [null!, data];
    } catch (error) {
        //console.log('[Debug] getAll error', error);
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getTaskActivity(
    params: TaskActivityRequest
): Promise<DatasourceReturnType<ResponseError, TaskActivity>> {
    try {
        const data = (await requestTaskActivity(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getTaskInbox(
    params: string
): Promise<DatasourceReturnType<ResponseError, ResponseList<TaskInbox>>> {
    try {
        const data = (await reqestTaskInbox(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getTaskLink(
    params: string
): Promise<DatasourceReturnType<ResponseError, Task[]>> {
    try {
        const data = (await requestLinkTask(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function closeTaskActivity(
    object_link: string
): Promise<DatasourceReturnType<ResponseError, { message: string }>> {
    try {
        const data = (await requestCloseTask(object_link)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function cancelTaskActivity(
    object_link: string
): Promise<DatasourceReturnType<ResponseError, { message: string }>> {
    try {
        const data = (await requestCancelTask(object_link)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
