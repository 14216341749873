import axios from 'axios';

import {
    ClientSystemProgramRequest,
    ClientSystemProgramResponse
} from '../schemas';

export async function get(params: ClientSystemProgramRequest) {
    return axios.get<ClientSystemProgramResponse>(
        '/v1/client-system/program',
        {
            params,
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            }
        }
    );
}
