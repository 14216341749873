import { makeAutoObservable, runInAction } from 'mobx';
import { getToken } from '../../data/token/repositories/tokenRepository';
import RootStore from '../Root.store';

class TokenStore {
    private _token: string = '';

    public get token() {
        return this._token;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchToken(
        company_token: string,
        citizen_id: string,
        email: string,
        first_name: string,
        last_name: string
    ) {
        const mode = localStorage.get('mode') ?? 'web';

        const [error, token] = await getToken({
            company_token,
            citizen_id,
            email,
            first_name,
            last_name,
            mode,
            sid: '',
            application: ''
        });

        //console.log("[Debug] token", token)

        if (error) {
            throw error;
        }

        runInAction(() => {
            this._token = token;
        });
    }
}

export default TokenStore;
