import { parseResponseError } from '../../../common/helpers';
import { getAll } from '../datasources/companyApplicationMappingMenu.datasource';
import { arrGetSubDashboardComponentV2 } from '../../../models/dashboard/SubDashboardComponentV2';
import {
    DatasourceReturnType,
    ResponseError,
    ResponseList
} from '../../../models';
import {
    CompanyApplicationMappingMenuRequest,
    CompanyApplicationMappingMenuResponse
} from '../schemas';

export async function getApplicationsMappingMenu(
    params: CompanyApplicationMappingMenuRequest
): Promise<
    DatasourceReturnType<
        ResponseError,
        ResponseList<arrGetSubDashboardComponentV2>
    >
> {
    try {
        const data = (await getAll(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
