import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/platform/Application';
import * as Models from '../../models/platform/Application';
import ModalRegisterAppComponent from './ModalRegisterAppComponent';
import ModalPlatformInApplicationComponent from '../group/ModalPlatformInApplicationComponent';
import ModalConfirmStatusApplicationComponent from './ModalConfirmStatusApplicationComponent';
import { Button} from 'reactstrap';

type Store = Models.ApplicationState & typeof Stores.actionCreators;

class Application extends React.Component<any, { pageLoad: boolean, isOpenModalSerInApp: boolean, isOpenModalAccRej: boolean, appUUID: string, appName: string, typeClick: string }, Store> {

    constructor(props: any) {

        super(props);

        this.state = {
            pageLoad: true,
            isOpenModalSerInApp: false,
            isOpenModalAccRej: false,
            appUUID: "",
            appName: "",
            typeClick: ""
        }

        this.BindData = this.BindData.bind(this);
        this.onClickTr = this.onClickTr.bind(this);
        this.afterOpenModalSerInApp = this.afterOpenModalSerInApp.bind(this);
        this.afterCloseModalSerInApp = this.afterCloseModalSerInApp.bind(this);
        this.onClickAccOrRej = this.onClickAccOrRej.bind(this);
        this.afterOpenModalAccRej = this.afterOpenModalAccRej.bind(this);
        this.afterCloseModalAccRej = this.afterCloseModalAccRej.bind(this);
        this.clickEmpty = this.clickEmpty.bind(this);
        this.afterCloseModalRegister = this.afterCloseModalRegister.bind(this);
    }

    public componentDidMount() {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
        this.props.requestFunction(this.state.pageLoad);

        this.setState({
            pageLoad: false
        });
    }

    public BindData() {
        this.setState({
            pageLoad: true
        });

        this.ensureDataFetched();
    }

    private onClickTr(e: any) {
        this.setState({
            pageLoad: false,
            isOpenModalSerInApp: true,
            appUUID: e.currentTarget.dataset.appuuid,
            appName: e.currentTarget.dataset.appname
        });
    }

    public afterOpenModalSerInApp() {
        this.setState({
            isOpenModalSerInApp: false
        });
    }

    public afterCloseModalSerInApp() {
        this.setState({
            pageLoad: true
        });
    } 

    private onClickAccOrRej(e: any) {
        this.setState({
            pageLoad: false,
            isOpenModalAccRej: true,
            appUUID: e.target.dataset.appuuid,
            appName: e.target.dataset.appname,
            typeClick: e.target.dataset.typeclick
        });
    }

    public afterOpenModalAccRej() {
        this.setState({
            isOpenModalAccRej: false
        });
    }

    public afterCloseModalAccRej() {
        this.setState({
            pageLoad: true
        });
    }

    public afterCloseModalRegister() {
        this.setState({
            pageLoad: true
        });
    }

    public clickEmpty() {
        
    } 

    render() {

        var { isOpenModalSerInApp } = this.state;
        var { isOpenModalAccRej } = this.state;
        var { appUUID } = this.state;
        var { appName } = this.state;
        var { typeClick } = this.state;

        return (
            <div style={{ paddingLeft: 'unset' }}>
                <ModalRegisterAppComponent BindData={this.BindData.bind(this)} afterCloseModalRegister={this.afterCloseModalRegister.bind(this)} />
                <hr />
                <h4 className="mb-3" style={{ fontWeight: 'bold' }}>Application</h4>

                {this.props.isLoading && this.renderService()}

                <ModalPlatformInApplicationComponent afterOpenModal={this.afterOpenModalSerInApp.bind(this)} afterCloseModal={this.afterCloseModalSerInApp.bind(this)}
                    isOpenModal={isOpenModalSerInApp} appUUID={appUUID} appName={appName} />

                <ModalConfirmStatusApplicationComponent afterOpenModal={this.afterOpenModalAccRej.bind(this)} afterCloseModal={this.afterCloseModalAccRej.bind(this)}
                    isOpenModal={isOpenModalAccRej} appUUID={appUUID} appName={appName} typeClick={typeClick} BindData={this.BindData.bind(this)}/>
            </div>
        );
    }

    renderService() {

        return (
            <React.Fragment>
                <table className='table table-bordered'>
                    <thead>
                        <tr style={{ background: "rgba(9, 132, 227, 1)", color: "#fff", fontSize: '10pt' }}>
                            <th>Application UUID</th>
                            <th>Application Name</th>
                            <th>Application ID</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>Created On</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(this.props.isLoading && this.props.response.result_list != undefined) && this.props.response.result_list.map((item: Models.Application) =>
                            <tr key={item.app_uuid} className={'table-tbody table-onclick table-tr-' + item.active_status} data-appuuid={item.app_uuid} data-appname={item.app_name}
                                onClick={item.active_status == "active" ? this.onClickTr : this.clickEmpty} >
                                <td >{item.app_uuid}</td>
                                <td >{item.app_name}</td>
                                <td >{item.app_id}</td>
                                <td >{item.email}</td>
                                <td >
                                    {item.active_status == "pending" ?
                                        <div>
                                            <Button color="primary" data-appuuid={item.app_uuid} data-appname={item.app_name} data-typeclick={'Accept'} onClick={this.onClickAccOrRej} >Accept</Button>{' '}
                                            <Button color="danger" data-appuuid={item.app_uuid} data-appname={item.app_name} data-typeclick={'Reject'} onClick={this.onClickAccOrRej} >Reject</Button>
                                        </div>
                                        :
                                        item.active_status
                                    }
                                </td>
                                <td >{item.created_by}</td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default connect((state: ApplicationState) => state.application,
    Stores.actionCreators
)(Application);