import { PropsWithChildren, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

type Props = {
    className?: string;
};

function OverlayPortal(props: PropsWithChildren<Props>) {
    const { className, children } = props;
    const overlayPortalElement = useRef(document.createElement('div')).current;
    overlayPortalElement.className = 'overlay-portal' + (className ? ' ' + className : '');

    useEffect(() => {
        document.body.appendChild(overlayPortalElement);

        return () => {
            document.body.removeChild(overlayPortalElement);
        };
    }, []);

    return ReactDOM.createPortal(children, overlayPortalElement);
}

export default OverlayPortal;
