import React, { useRef, useState } from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '../../common/materials';
import TaskGroup, { TaskGroupHandle } from './TaskGroup.view';
import { twMerge } from 'tailwind-merge';

type Props = {
    isOpen?: boolean;
    onClose?: () => void;
    onRefresh?: () => void;
};

function TaskGroupModal(props: Props) {
    const { isOpen, onClose, onRefresh } = props;

    const [isBlur, setIsBlur] = useState(false);

    const ref = useRef<TaskGroupHandle>(null);

    return (
        <Modal
            noPortal
            fullscreen
            isOpen={isOpen}
            onClose={onClose}
            className={twMerge(
                // isBlur ? 'pointer-events-none translate-y-60 opacity-60' : 'translate-y-0',
                isBlur ? 'pointer-events-none translate-y-60' : 'translate-y-0',
                'transition-transform'
            )}
        >
            <ModalHeader>Task Group Management</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-x-auto">
                <TaskGroup
                    ref={ref}
                    onClose={onClose}
                    onRefresh={onRefresh}
                    setIsBlur={setIsBlur}
                />
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button
                    outline
                    onClick={onClose}
                    className="text-neutral-400"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        ref.current?.handleClickSaveTaskGroup();
                    }}
                    className="hidden bg-[#E55C00] text-white"
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default TaskGroupModal;
