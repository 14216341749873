import { memo, PropsWithChildren, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

import { BsFillGridFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';

function StyledButton(
    props: PropsWithChildren<React.HtmlHTMLAttributes<HTMLButtonElement> & { active?: boolean }>
) {
    const { active, children, ...buttonProps } = props;

    return (
        <button
            {...buttonProps}
            className={twMerge(
                'flex flex-1 items-center justify-center bg-[#F6F8F9] p-2.5 text-[#B0BABF] transition-colors',
                active && 'text-primary-900'
            )}
        >
            {children}
        </button>
    );
}

type ViewMode = 'grid' | 'list';

type Props = {
    viewMode: ViewMode;
    className?: string;
    onClick?: (viewMode: ViewMode) => void;
};

function ToggleView(props: Props) {
    const { viewMode, className, onClick } = props;

    const handleClickGrid = useCallback(() => onClick?.('grid'), [onClick]);

    const handleClickList = useCallback(() => onClick?.('list'), [onClick]);

    return (
        <div
            className={twMerge(
                'relative flex w-fit overflow-hidden rounded-md border [&_>_button:not(:last-child)]:border-r',
                className
            )}
        >
            <StyledButton
                active={viewMode === 'grid'}
                onClick={handleClickGrid}
            >
                <BsFillGridFill />
            </StyledButton>
            <StyledButton
                active={viewMode === 'list'}
                onClick={handleClickList}
            >
                <FaThList />
            </StyledButton>
        </div>
    );
}

export default memo(ToggleView);
