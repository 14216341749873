import { memo } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import F1_LOGO from 'images/f1-new-logo.png';

function F1Logo() {
    return (
        <Link
            to="/"
            className={twMerge(
                'relative hidden min-w-[360px] cursor-pointer overflow-hidden bg-[#EEEEEE] py-2 lg:flex',
                'after:absolute after:right-0 after:top-0 after:h-0 after:w-0 after:border-b-[50px] after:border-r-[50px] after:border-t-0 after:border-solid',
                "after:border-b-transparent after:border-l-transparent after:border-r-[#1C2F36] after:border-t-transparent after:content-['']"
            )}
        >
            <img
                className="mx-auto h-[33px] w-[76px]"
                src={F1_LOGO}
                alt="F1"
            />
        </Link>
    );
}

export default memo(F1Logo);
