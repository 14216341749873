import axios from 'axios';
import {
    CancelTaskRequest,
    CancelTaskResponse,
    CreateTaskResponse,
    EditTaskResponse,
    GetCurrentAnnouncementRequest,
    GetTaskStoreRequest,
    ReleaseTaskRequest,
    ReleaseTaskResponse,
    TaskStoreMapping
} from '../schemas';

export async function requestGetTask(params: GetTaskStoreRequest) {
    return axios.get<TaskStoreMapping[]>('v1/task-store/task', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestCreateTask(formData: FormData) {
    return axios.post<CreateTaskResponse>('v1/task-store/task', formData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function requestEditTask(formData: FormData) {
    return axios.put<EditTaskResponse>('v1/task-store/task', formData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function requestReleaseTask(params: ReleaseTaskRequest) {
    return axios.post<ReleaseTaskResponse>('v1/task-store/release-task', null, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestCancelTask(params: CancelTaskRequest) {
    return axios.post<CancelTaskResponse>('v1/task-store/cancel-task', null, {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestGetCurrentAnnouncement(params: GetCurrentAnnouncementRequest) {
    return axios.get<TaskStoreMapping[]>('v1/task-store/task-announcement', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'application/json'
        }
    });
}

export async function requestCreateTaskAnnouncement(formData: FormData) {
    return axios.post<CreateTaskResponse>('v1/task-store/task-announcement', formData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'multipart/form-data'
        }
    });
}

export async function requestEditTaskAnnouncement(formData: FormData) {
    return axios.put<CreateTaskResponse>('v1/task-store/task-announcement', formData, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'multipart/form-data'
        }
    });
}
