import UserApplicationStore from './domain/UserApplication.store';
import SubDashboardStore from './domain/SubDashboard.store';
import UserCompanyStore from './domain/UserCompany.store';
import ApplicationBuildVersionStore from './domain/ApplicationBuildVersion.store';
import ClientSystemStore from './domain/ClientSystem.store';
import CompanyMasterStore from './domain/CompanyMaster.store';
import FocusoneLinkStore from './domain/FocusoneLink.store';
import TaskStore from './domain/Task.store';
import TokenStore from './domain/Token.store';
import SandboxStore from './domain/Sandbox.store';
import LogStore from './domain/Log.store';
import ProfileStore from './domain/Profile.store';

class RootStore {
    public userApplicationStore = new UserApplicationStore(this);
    public subDashboardStore = new SubDashboardStore(this);
    public userCompanyStore = new UserCompanyStore(this);
    public versionStore = new ApplicationBuildVersionStore(this);
    public clientSystemStore = new ClientSystemStore(this);
    public companyMasterStore = new CompanyMasterStore(this);
    public focusOneLinkStore = new FocusoneLinkStore(this);
    public tokenStore = new TokenStore(this);
    public taskStore = new TaskStore(this);
    public sandboxStore = new SandboxStore(this);
    public logStore = new LogStore(this);
    public profileStore = new ProfileStore(this);
}

export default RootStore;
