import { useCallback, useMemo, useState } from 'react';

type PaginationOptions = {
    pageSize: number;
    initialPage?: number;
};

function usePagination<U>(collections: U[], options: PaginationOptions) {
    const pageSize = options.pageSize;
    const numberOfPages = Math.ceil((collections.length ?? 1) / pageSize);
    const [currentPage, setCurrentPage] = useState(1);

    const slicedDocuments = useMemo(
        () => collections.slice((currentPage - 1) * pageSize, currentPage * pageSize),
        [collections, currentPage, pageSize]
    );

    const handleClickFirstPage = useCallback(() => {
        setCurrentPage(1);
    }, []);

    const handleClickNextPage = useCallback(() => {
        if (currentPage === numberOfPages) {
            return;
        }

        setCurrentPage(prevState => prevState + 1);
    }, [setCurrentPage, currentPage, numberOfPages]);

    const handleClickLastPage = useCallback(() => {
        setCurrentPage(numberOfPages);
    }, [numberOfPages]);

    const handleClickPreviousPage = useCallback(() => {
        if (currentPage === 1) {
            return;
        }

        setCurrentPage(prevState => prevState - 1);
    }, [setCurrentPage, currentPage]);

    return {
        collections: slicedDocuments,
        pageSize,
        numberOfPages,
        currentPage,
        handleClickFirstPage,
        handleClickPreviousPage,
        handleClickNextPage,
        handleClickLastPage
    };
}

export default usePagination;
