import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';

import { cn } from '../helpers';
import { getToken } from '../../data/token/repositories/tokenRepository';
import { arrGetCompanyApplicationMappingMain } from '../../models/company/CompanyApplicationMapping';
import { useLoader, useStore } from '../hooks';

type Props = {
    dataItem: arrGetCompanyApplicationMappingMain;
    //mode: string;
};

function CardApplication(props: Props) {
    const { dataItem /*, mode*/ } = props;
    const isInactive = dataItem.application_status === 'inactive';
    const url = isInactive ? undefined : dataItem.application_ingress;

    const { tokenStore, userCompanyStore, subDashboardStore } = useStore();
    //const { token } = tokenStore;
    const { mode, citizenId, email, firstName, lastName } = userCompanyStore;
    const companyToken = subDashboardStore.selectedCompanyToken;
    const screenLoader = useLoader();

    const windowRef = useRef<ReturnType<typeof window.open>>();

    useEffect(() => {
        getUserInformation('', '', 'active');
    }, []);

    const getUserInformation = useCallback(
        async (sid: string, citizenId: string, status: string) => {
            await userCompanyStore.fetchUserCompany(sid, citizenId, status);
        },
        []
    );

    const openApplication = async (isInactive: boolean, url = '') => {
        try {
            screenLoader.show();

            if (isInactive) {
                throw new Error('คุณไม่ได้รับอนุญาติให้ใช้แอปพลิเคชันนี้');
            }

            if (!companyToken) {
                throw new Error('ไม่พบบริษัทนี้');
            }

            const userId = citizenId;
            const mode = localStorage.getItem('mode') || 'web';
            //if (companyToken != '1IbuASmpqIapjmhVfzcLF4XPVY9'
            //    && companyToken != '2KlP5DjCQeyOSgyAH0vNezHzu18') {
            //    userId = citizenId.split('@')[0];
            //}

            const [error, getTokenResponse] = await getToken({
                company_token: companyToken,
                citizen_id: userId,
                email: email,
                first_name: firstName,
                last_name: lastName,
                mode: mode,
                sid: dataItem.group_name,
                application: dataItem.application_name.split('\n').join(' ')
            });

            if (error) {
                console.log(error);

                throw new Error(
                    'We are unable to process your request at this time. Please try again.'
                );
            }

            //console.log("[Debug] token", getTokenResponse[1])
            // const composedUrlWithToken = url + '?token=' + getTokenResponse;

            console.log(dataItem);
            console.log(dataItem.application_type);

            if (dataItem.application_type == 'web') {
                const composedUrlWithToken =
                    url + (url.indexOf('?') > -1 ? '&token=' : '?token=') + getTokenResponse;

                if (mode == 'gui') {
                    var jResWeb = {
                        action: 'LOGIN_SUCCESS',
                        token: getTokenResponse,
                        citizen_id: citizenId,
                        select_server: {
                            system_type: 'WEB',
                            //system_id: value.sid,
                            system_name: dataItem.application_name,
                            //endpoint: value.ingress + '?token=' + result.token
                            endpoint: composedUrlWithToken
                            //host: value.host,
                            //port: value.port
                        }
                    };

                    var res = JSON.stringify(jResWeb);

                    (window as any).chrome.webview.postMessage(res);

                    return;
                }

                windowRef.current = window.open();
                windowRef.current && (windowRef.current.location = composedUrlWithToken);
            } else if (dataItem.application_type == 'gui') {
                var jResGui = {
                    action: 'LOGIN_SUCCESS',
                    token: getTokenResponse,
                    citizen_id: citizenId,
                    select_server: {
                        system_type: 'GUI',
                        system_id: dataItem.application_sid,
                        system_name: dataItem.application_name.replace('\n', ' '),
                        host: dataItem.application_host,
                        port: dataItem.application_port
                    }
                };

                var res = JSON.stringify(jResGui);

                (window as any).chrome.webview.postMessage(res);
            } else {
                throw new Error(
                    'Application Type ' + dataItem.application_type + ' does not support'
                );
            }
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire({
                    title: '',
                    text: error.message,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false
                });
            }
        } finally {
            screenLoader.hide();
        }
    };

    const openApplicationOnNewTab = useCallback(() => {
        //windowRef.current = window.open();
        openApplication(isInactive, url);
    }, [isInactive, url, openApplication]);

    return (
        <div
            tabIndex={0}
            className={cn(
                'group m-[5px] inline-flex h-[145px] w-[110px] flex-col rounded-[6px] px-[5px] py-[10px] shadow-[5px_7px_19px_-3px_#00000040] transition-[box-shadow,transform]',
                dataItem.application_status != 'inactive'
                    ? `hover:[0_45px_65px_rgba(0,0,0,0.50),_0_35px_22px_rgba(0,0,0,0.16)] focus:[0_45px_65px_rgba(0,0,0,0.50),_0_35px_22px_rgba(0,0,0,0.16)] cursor-pointer bg-white hover:scale-105 focus:scale-105 focus:shadow-gray-500`
                    : 'cursor-not-allowed bg-[#7A7A7A40] grayscale'
            )}
            onClick={openApplicationOnNewTab}
        >
            <div className="flex flex-1 flex-col items-center">
                <img
                    className="h-9 w-9"
                    src={dataItem.application_img_url}
                />
            </div>
            <span
                className={cn(
                    'mt-[16px] whitespace-pre-line break-words text-center text-[11px] leading-[15px] text-[#0C0B0B] transition-[text-shadow] group-focus:[text-shadow:_0px_0px_1px_currentColor]',
                    dataItem.application_status == 'inactive' ? 'text-[#7A7A7A]' : ''
                )}
            >
                {dataItem.application_name}
            </span>
            <div className="flex flex-1 flex-row items-end justify-center">
                {dataItem.application_status == 'inactive' && (
                    <div>
                        <span className="mt-[15px] text-[12px] text-[#7f7171]">INACTIVE</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default observer(CardApplication);
