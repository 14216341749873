import React, { createContext, PropsWithChildren, useState } from "react";

import * as UserCompanyModel from '../models/user/UserCompanyMaster';

export type UserContextType = {
    userCompany: UserCompanyModel.arrGetUserCompanyMaster | undefined;
    setUserCompany: (value: UserCompanyModel.arrGetUserCompanyMaster | undefined) => void;
};

export const UserContext = createContext<UserContextType | null>(null);

//const initialUserCompany: UserCompanyModel.arrGetUserCompanyMaster = {
//    citizen_id: '',
//    company_name: '',
//    sid: '',
//    status: '',
//    type: '',
//    created_by: '',
//    created_on: '',
//    updated_by: '',
//    updated_on: ''
//}

function UserProvider({ children }: PropsWithChildren<{}>) {
    const [userCompany, setUserCompany] = useState<UserCompanyModel.arrGetUserCompanyMaster | undefined>();

    const provide = {
        userCompany,
        setUserCompany
    };

    return (
        <UserContext.Provider value={provide}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;
