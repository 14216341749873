import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as ApplicationModel from '../../models/platform/ApplicationManage';

interface RequestFunctionAction {
    type: 'REQUEST_APPLICATIONMANAGE';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_APPLICATIONMANAGE';
    model: ApplicationModel.ResponseApplicationManage;
    version: number;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (appName: string, email: string, username: string, password: string, method: string, version: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        const body = {
            'app_name': appName,
            'email': email,
            'username': username,
            'password': password
        }

        if (appState && appState.applicationManage && method == "POST") {
            console.log("call api register apps");
            fetch(`/v1/apps/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => response.json() as Promise<ApplicationModel.ResponseApplicationManage>)
                .then(data => {
                    console.log("register apps success. - " + version);
                    dispatch({ type: 'RECEIVE_APPLICATIONMANAGE', model: data, version: version });
                })
                .then(error => {

                });

            dispatch({ type: 'REQUEST_APPLICATIONMANAGE' });
        }
    }
};

const unloadedState: ApplicationModel.ApplicationManageState = { isLoading: false, response: undefined, version: 0 };

export const reducer: Reducer<ApplicationModel.ApplicationManageState> = (state: ApplicationModel.ApplicationManageState | undefined, incomingAction: Action): ApplicationModel.ApplicationManageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_APPLICATIONMANAGE':
            return {
                isLoading: false,
                version : 0
            };
        case 'RECEIVE_APPLICATIONMANAGE':
            return {
                isLoading: true,
                response: action.model,
                version: action.version + 1
            };

        default: return state;
    }
};