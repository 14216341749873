import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    disabled?: boolean;
    outline?: boolean;
    values?: string[];
    defaultSelectedValues?: string[];
    onChange?: (values: string[]) => void;
};

function ButtonGroup(props: Props) {
    const { disabled, outline, values = [], defaultSelectedValues = [], onChange } = props;

    const [selectedValues, setSelectedValues] = useState(new Set(defaultSelectedValues));

    const handleClick = (value: string) => {
        const nextSelectedValues = new Set(selectedValues);

        if (!selectedValues.has(value)) {
            nextSelectedValues.add(value);
        } else {
            nextSelectedValues.delete(value);
        }

        setSelectedValues(nextSelectedValues);
        onChange && onChange(Array.from(nextSelectedValues));
    };

    return (
        <div
            className={twMerge(
                'inline-flex items-center [&>button:first-child]:rounded-l-md [&>button:last-child]:rounded-r-md',
                disabled ? 'pointer-events-none' : ''
            )}
        >
            {values.map((value, index) => (
                <button
                    key={`btn-${value}-${index}`}
                    className={twMerge(
                        'border bg-neutral-50 py-1 px-3 text-xs text-neutral-600 transition-colors duration-100',
                        selectedValues.has(value)
                            ? outline
                                ? 'border-primary-900 bg-white text-primary-900'
                                : 'border-primary-900 bg-primary-900 text-white'
                            : 'border-neutral-400 text-neutral-600'
                    )}
                    onClick={() => handleClick(value)}
                >
                    <span className="relative top-[1px]">{value}</span>
                </button>
            ))}
        </div>
    );
}

export default ButtonGroup;
