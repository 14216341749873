import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as ApplicationModel from '../../models/platform/Application';

interface RequestFunctionAction {
    type: 'REQUEST_APPLICATION';
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_APPLICATION';
    model: ApplicationModel.ResponseApplication;
}

interface ReceiveApplicationMaster {
    type: 'RECEIVE_APPLICATION_MASTER';
    model: ApplicationModel.ResponseApplicationMaster;
}

interface ReceiveApplicationMappingEndpoint {
    type: 'RECEIVE_APPLICATION_MAPPING_ENDPOINT';
    model: ApplicationModel.ResponseApplicationMappingEndpoint;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction | ReceiveApplicationMaster | ReceiveApplicationMappingEndpoint

export const actionCreators = {
    requestFunction: (pageload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.application && pageload == true) {

            fetch(`/v1/apps`, {
                method: 'GET'
            })
                .then(response => response.json() as Promise<ApplicationModel.ResponseApplication>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_APPLICATION', model: data });
                })
                .then(error => {

                });

            dispatch({ type: 'REQUEST_APPLICATION' });
        }
    },

    requestGetApplicationMaster: (pageload: boolean, app_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.application && pageload == true) {

            fetch(`v1/applications`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
                .then(response => response.json() as Promise<ApplicationModel.ResponseApplicationMaster>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_APPLICATION_MASTER', model: data });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_APPLICATION' });
        }
    },

    requestGetUserApplicationMappingEndpoint: (pageload: boolean, user_id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.application && pageload == true) {

            fetch(`v1/applications/endpoint` + '?user_id=' + user_id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
                .then(response => response.json() as Promise<ApplicationModel.ResponseApplicationMappingEndpoint>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_APPLICATION_MAPPING_ENDPOINT', model: data });
                })
                .catch(error => {
                    console.log("[Debug] req get app mapping failed. ->", error)
                });

            dispatch({ type: 'REQUEST_APPLICATION' });
        }
    }
};

const unloadedState: ApplicationModel.ApplicationState = { isLoading: false, response: undefined, responseAppMaster: undefined, responseAppMappingEndpoint: undefined };

export const reducer: Reducer<ApplicationModel.ApplicationState> = (state: ApplicationModel.ApplicationState | undefined, incomingAction: Action): ApplicationModel.ApplicationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_APPLICATION':
            return {
                isLoading: false
            };
        case 'RECEIVE_APPLICATION':
            return {
                isLoading: true,
                response: action.model
            };
        case 'RECEIVE_APPLICATION_MASTER':
            return {
                isLoading: true,
                responseAppMaster: action.model
            };
        case 'RECEIVE_APPLICATION_MAPPING_ENDPOINT':
            return {
                ...state,
                isLoading: true,
                responseAppMappingEndpoint: action.model
            };
        default: return state;
    }
};