import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/group/PlatformManage';
import * as Models from '../../models/group/Platform';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

type Store = Models.PlatformState & typeof Stores.actionCreators;

class ModalRegisterPlatformComponent extends React.PureComponent<any, { isOpen: boolean, serName: string, createBy: string, isSave: boolean, classSpinner: string }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            serName: "",
            createBy: "",
            isSave: false,
            classSpinner: "d-none"
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.SaveClick = this.SaveClick.bind(this);
        this.handleChangeSerName = this.handleChangeSerName.bind(this);
        this.handleChangeCreateBy = this.handleChangeCreateBy.bind(this);
    }

    private toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private handleChangeSerName(event: any) {
        this.setState({ serName: event.target.value });
    }

    private handleChangeCreateBy(event: any) {
        this.setState({ createBy: event.target.value });
    }

    private SaveClick() {
        this.setState({
            isSave: true,
            classSpinner: "d-inline-block"
        });

        this.props.requestFunction("", this.state.serName, this.state.createBy, "POST");
    }

    render() {

        if (this.props.isLoading && this.state.isSave) {
            this.props.BindData();

            this.toggleModal();
            this.setState({
                isSave: false
            });
        }

        return (
            <div>
                <Button color="primary" onClick={this.toggleModal}>Register Platform</Button>
                <Modal isOpen={this.state.isOpen} >
                    <ModalHeader toggle={this.toggleModal}>Register Platform</ModalHeader>
                    <ModalBody>
                        <label htmlFor="txtPlatformName"><b>Platform Name</b></label>
                        <input type="text" id="txtPlatformName" className="form-control" value={this.state.serName} onChange={this.handleChangeSerName}></input>
                        <label htmlFor="txtCreateBy"><b>Create by Name</b></label>
                        <input type="text" id="txtCreateBy" className="form-control" value={this.state.createBy} onChange={this.handleChangeCreateBy}></input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.SaveClick} >
                            <Spinner color="primary" className={this.state.classSpinner} style={{ width: '20px', height: '20px' }} />
                            {' '}Save
                            </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.platform,
    Stores.actionCreators
)(ModalRegisterPlatformComponent);
