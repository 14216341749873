import React, { useState } from 'react';

import { Label, Input } from '../../../../common/materials';
import { Images } from '../../../../assets';
import {
    AiOutlineDoubleLeft,
    AiOutlineDoubleRight,
    AiOutlineLeft,
    AiOutlineRight
} from 'react-icons/ai';

function WorkflowModal() {
    const datasRow = [
        'Row_WorkFlow_A',
        'Row_WorkFlow_B',
        'Row_WorkFlow_C',
        'Row_WorkFlow_D',
        'Row_WorkFlow_E',
        'Row_WorkFlow_F'
    ];

    const [optionsWorkflow, setOptionsWorkflow] = useState<string[]>(datasRow);
    const [selectedWorkflow, setSelectedWorkflow] = useState<string[]>([]);
    const [backupOptionWorkflow, setBackupOptionWorkflow] = useState<string[]>([]);
    const [backupSelectedWorkflow, setBackupSelectedWorkflow] = useState<string[]>([]);

    const [checkRowWorkflow, setCheckRowWorkflow] = useState<boolean[]>([]);
    const [checkRowSelected, setCheckRowSelected] = useState<boolean[]>([]);

    const [inputSearch, setInputSearch] = useState<string>('');

    const setStatusRowWorkflow = index => {
        setCheckRowWorkflow(prevStatus => {
            const newStatusWorkflow = [...prevStatus];
            newStatusWorkflow[index] = !newStatusWorkflow[index];
            return newStatusWorkflow;
        });
    };

    const setStatusRowSelected = index => {
        setCheckRowSelected(prevStatus => {
            const newStatusSelected = [...prevStatus];
            newStatusSelected[index] = !newStatusSelected[index];
            return newStatusSelected;
        });
    };

    const setStatusRowIsFalseAll = () => {
        setCheckRowWorkflow(datasRow.map(row => false));
        setCheckRowSelected(datasRow.map(row => false));
    };

    const handleBackupOptionWorkflow = (index, rowCurrent) => {
        if (!backupOptionWorkflow.includes(rowCurrent)) {
            setBackupOptionWorkflow([...backupOptionWorkflow, rowCurrent]);
            setStatusRowWorkflow(index);
        } else {
            setBackupOptionWorkflow(backupOptionWorkflow.filter(rowSame => rowSame !== rowCurrent));
            setStatusRowWorkflow(index);
        }
    };

    const handleBackupSelectedWorkflow = (index, rowCurrent) => {
        if (!backupSelectedWorkflow.includes(rowCurrent)) {
            setBackupSelectedWorkflow([...backupSelectedWorkflow, rowCurrent]);
            setStatusRowSelected(index);
        } else {
            setBackupSelectedWorkflow(
                backupSelectedWorkflow.filter(rowSame => rowSame !== rowCurrent)
            );
            setStatusRowSelected(index);
        }
    };

    const handleMoveToSelectedWorkflow = () => {
        if (backupOptionWorkflow) {
            setSelectedWorkflow([...selectedWorkflow, ...backupOptionWorkflow]);
            setOptionsWorkflow(
                optionsWorkflow.filter(rowOption => !backupOptionWorkflow.includes(rowOption))
            );
            setBackupOptionWorkflow([]);
            setStatusRowIsFalseAll();
        }
    };

    const handleMoveToOptionsWorkflow = () => {
        if (backupSelectedWorkflow) {
            setOptionsWorkflow([...optionsWorkflow, ...backupSelectedWorkflow]);
            setSelectedWorkflow(
                selectedWorkflow.filter(
                    rowSelected => !backupSelectedWorkflow.includes(rowSelected)
                )
            );
            setBackupSelectedWorkflow([]);
            setStatusRowIsFalseAll();
        }
    };

    const handleMoveToSelectedAll = () => {
        setSelectedWorkflow([...selectedWorkflow, ...optionsWorkflow]);
        setOptionsWorkflow([]);
        setStatusRowIsFalseAll();
    };

    const handleMoveToOptionsAll = () => {
        setOptionsWorkflow([...optionsWorkflow, ...selectedWorkflow]);
        setSelectedWorkflow([]);
        setStatusRowIsFalseAll();
    };

    // console.log('------------------------------------------------------------')
    // console.log('WorkFlow: ', optionsWorkflow)
    // console.log('Selected: ',selectedWorkflow)
    // console.log('backup WorkFlow: ',backupOptionWorkflow)
    // console.log('backup Selected: ',backupSelectedWorkflow)
    // console.log('------------------------------------------------------------')

    return (
        <React.Fragment>
            <div className="flex items-center gap-2">
                <Label>System Group : </Label>
                <Input
                    className="w-[350px]"
                    placeholder="Search ..."
                    onChange={event => setInputSearch(event.target.value)}
                />
            </div>

            <p className="mt-6 font-medium text-neutral-600">Select WorkFlow</p>

            <div className="mt-3 grid grid-cols-11 gap-4">
                <div className="col-span-5 bg-gray-100 p-3">
                    <p className="font-medium">Option WorkFlow</p>
                    <ul className="mt-3 [&>li:not(:last-child)]:border-b">
                        {optionsWorkflow
                            .filter(rowOption => {
                                if (inputSearch === '') {
                                    return rowOption;
                                } else if (
                                    rowOption.toLowerCase().includes(inputSearch.toLowerCase())
                                ) {
                                    return rowOption;
                                }
                            })
                            .map((rowOption, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="flex cursor-pointer items-center justify-between bg-white py-2 px-2 hover:bg-white/5 active:bg-white/10"
                                        onClick={() => handleBackupOptionWorkflow(index, rowOption)}
                                    >
                                        <span>{rowOption}</span>
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 cursor-pointer accent-primary-900"
                                            checked={checkRowWorkflow[index]}
                                        />
                                    </li>
                                );
                            })}
                    </ul>
                </div>

                <div className="col-span-1 flex flex-col items-center justify-center gap-3">
                    <button
                        onClick={handleMoveToSelectedWorkflow}
                        className="rounded-md border border-primary-900 p-4 text-primary-900"
                    >
                        <AiOutlineRight size={18} />
                    </button>
                    <button
                        onClick={handleMoveToOptionsWorkflow}
                        className="rounded-md border border-primary-900 p-4 text-primary-900"
                    >
                        <AiOutlineLeft size={18} />
                    </button>
                    <button
                        onClick={handleMoveToSelectedAll}
                        className="rounded-md border border-primary-900 p-4 text-primary-900"
                    >
                        <AiOutlineDoubleRight size={18} />
                    </button>
                    <button
                        onClick={handleMoveToOptionsAll}
                        className="rounded-md border border-primary-900 p-4 text-primary-900"
                    >
                        <AiOutlineDoubleLeft size={18} />
                    </button>
                </div>

                <div className="col-span-5 bg-gray-100 p-3">
                    <p className="font-medium">Selected WorkFlow</p>
                    <ul className="mt-3 [&>li:not(:last-child)]:border-b">
                        {selectedWorkflow.map((rowSelected, index) => {
                            return (
                                <li
                                    key={index}
                                    className="flex cursor-pointer items-center justify-between bg-white py-2 px-2 hover:bg-white/5 active:bg-white/10"
                                    onClick={() => handleBackupSelectedWorkflow(index, rowSelected)}
                                >
                                    <span>{rowSelected}</span>
                                    <input
                                        type="checkbox"
                                        className="h-4 w-4 cursor-pointer accent-primary-900"
                                        checked={checkRowSelected[index]}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default WorkflowModal;
