import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Icons } from 'assets';
import { useStore } from 'common/hooks';
import { arrGetSubDashboardComponentV2GroupDetail } from 'models/dashboard/SubDashboardComponentV2';

type Props = {
    header: string;
    indexKey: number;
    dashboardDataItem: arrGetSubDashboardComponentV2GroupDetail[];
    onClickApplication?: (application: arrGetSubDashboardComponentV2GroupDetail) => void;
};

function Accordion(props: Props) {
    const { subDashboardStore } = useStore();
    const { header, dashboardDataItem, indexKey, onClickApplication } = props;
    const [isExpansion, setIsExpansion] = useState(true);
    const selectDashBoard = subDashboardStore.selectedApplication;

    return (
        <div key={indexKey}>
            <div className="flex flex-row">
                <div
                    className="mt-[2px] flex flex-1 flex-row justify-between rounded-[4px] bg-[#FFFFFF] p-[10px]"
                    onClick={() => setIsExpansion(prevState => !prevState)}
                >
                    <div className="ml-[10px] flex">
                        <img src={Icons.VECTOR_GRAY} />
                        <span className="ml-[16px] text-[14px] font-bold leading-[21px] text-black">
                            {header}
                        </span>
                    </div>
                    <img
                        src={Icons.EXPAND_ARROW_BLACK}
                        className={`${isExpansion && 'rotate-90'}`}
                    />
                </div>
            </div>
            {isExpansion &&
                dashboardDataItem.map((dataItem, index) => (
                    <Link
                        key={index}
                        to={'/user-applications-redesign'}
                        className={`text-black`}
                        onClick={() => {
                            subDashboardStore.setSelectedApplication(
                                dataItem.group_name,
                                dataItem.company_token
                            );
                            onClickApplication && onClickApplication(dataItem);
                        }}
                    >
                        {selectDashBoard === dataItem.group_name ? (
                            <div className="flex flex-1 border-b-[1px] bg-[#F5F5F5] p-[8px] pl-[50px]">
                                <span className="text-[14px] font-normal leading-[21px] text-[#E55C00]">
                                    {dataItem.group_name}
                                </span>
                            </div>
                        ) : (
                            <div className="flex flex-1 border-b-[1px] border-[#D3D1D1] bg-[#F9F9F9] p-[8px] pl-[50px]">
                                <span className="text-[14px] font-normal leading-[21px]">
                                    {dataItem.group_name}
                                </span>
                            </div>
                        )}
                    </Link>
                ))}
        </div>
    );
}

export default observer(Accordion);
