import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { TaskGroup } from '../../models/taskGroup/TaskGroup';
import { useDisclosure, useLoader, useStore } from '../../common/hooks';
import { extractAccessToken } from '../../common/helpers';
import {
    DeleteTaskGroup,
    createTaskGroup,
    editTaskGroup as requestEditTaskGroup,
    getTaskGroup,
    swapTaskGroupIndex
} from '../../data/taskGroup/repositories/taskGroupRepository';

import { TaskGroupProps } from './TaskGroup.view';

function TaskGroupViewModel({ onClose, onRefresh, setIsBlur }: TaskGroupProps) {
    const { userCompanyStore } = useStore();
    const loader = useLoader();
    const accessToken = extractAccessToken();

    const [addTaskGroup, setAddTaskGroup] = useState<TaskGroup>({
        group_index: '',
        group_id: '',
        group_description: ''
    });
    const [editTaskGroup, setEditTaskGroup] = useState<TaskGroup>({
        group_index: '',
        group_id: '',
        group_description: ''
    });
    const [deleteTaskGroup, setDeleteTaskGroup] = useState<TaskGroup>({
        group_index: '',
        group_id: '',
        group_description: ''
    });
    const [taskGroupList, setTaskGroupList] = useState<TaskGroup[]>([]);

    const { isOpen: showAddTaskGroupModal, onToggle: toggleShowAddTaskGroupModal } =
        useDisclosure();
    const { isOpen: showEditTaskGroupModal, onToggle: toggleShowEditTaskGroupModal } =
        useDisclosure();
    const { isOpen: showDeleteTaskGroupModal, onToggle: toggleShowDeleteTaskGroupModal } =
        useDisclosure();

    useEffect(() => {
        fetchTaskGroup();
    }, []);

    const fetchTaskGroup = async () => {
        try {
            loader.show();

            const [error, data] = await getTaskGroup('management');

            if (error) {
                throw error;
            }

            // if (data.length === 0) {
            //     return;
            // }

            setTaskGroupList(data);
        } catch (e) {
            console.log('[Debug] req get task group failed. ->', e);
        } finally {
            loader.hide();
        }
    };

    // todo: delete multiple at once
    // const reorderArray = (
    //     selectedObject: { oldIndex: number; newIndex: number },
    //     originalArray: TaskGroupList[]
    // ): TaskGroupList[] => {
    //     const movedItem = originalArray.find((_, index) => index === selectedObject.oldIndex);

    //     const remainingItems = originalArray.filter(
    //         (_, index) => index !== selectedObject.oldIndex
    //     );

    //     const reorderedItems = [
    //         ...remainingItems.slice(0, selectedObject.newIndex),
    //         movedItem,
    //         ...remainingItems.slice(selectedObject.newIndex)
    //     ];

    //     return reorderedItems as TaskGroupList[];
    // };

    // const handleChangeOrder = (selectedObjectIndex: number, direction: 'UP' | 'DOWN') => {
    //     setTaskGroupList(
    //         reorderArray(
    //             {
    //                 oldIndex: selectedObjectIndex,
    //                 newIndex: selectedObjectIndex + (direction === 'UP' ? -1 : 1)
    //             },
    //             taskGroupList
    //         ) as TaskGroupList[]
    //     );
    // };
    //
    //
    // const handleClickDeleteTaskGroup = async (group_id: string) => {
    //     setTaskGroupList(prevTaskGroupList =>
    //         prevTaskGroupList.map(item => {
    //             if (item.group_id === group_id) {
    //                 return { ...item, isDeleted: true };
    //             }
    //             return item;
    //         })
    //     );
    // };

    const handleChangeOrder = async (selectedObjectIndex: number, direction: 'UP' | 'DOWN') => {
        try {
            loader.show();

            const group_index_target = (selectedObjectIndex + 1).toString();
            const group_index_relate = (
                selectedObjectIndex +
                (direction === 'UP' ? -1 : 1) +
                1
            ).toString();

            const [error, data] = await swapTaskGroupIndex({
                group_index_target,
                group_index_relate
            });

            if (error) {
                throw error;
            }

            if (!data) {
                throw new Error('something went wrong');
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
            fetchTaskGroup();
            // onClose && onClose();
            // onRefresh && onRefresh();
        }
    };

    const handleAddTaskGroup = (taskGroup: TaskGroup) => {
        if (!taskGroup) {
            return;
        }

        setIsBlur && setIsBlur(prev => !prev);
        setAddTaskGroup(taskGroup);
        toggleShowAddTaskGroupModal();
    };

    const handleChangeAddTaskGroup = <K extends keyof typeof addTaskGroup>(
        key: K,
        value: (typeof addTaskGroup)[K]
    ) => {
        setAddTaskGroup(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const confirmAddTaskGroup = async () => {
        try {
            loader.show();

            const { group_id, group_description } = addTaskGroup;

            if (!group_id.trim() || !group_description.trim()) {
                throw new Error('group_id or group_description is null or empty.');
            }

            const [error, data] = await createTaskGroup({ group_id, group_description });

            if (error) {
                throw error;
            }

            if (!data) {
                return;
            }

            if (data.result) {
                Swal.fire({
                    title: 'Success!',
                    text: `Create task group success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then(() => onRefresh && onRefresh());
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
            setIsBlur && setIsBlur(prev => !prev);
            toggleShowAddTaskGroupModal();
            fetchTaskGroup();
            // onClose && onClose();
            // onRefresh && onRefresh();
        }
    };

    const handleEditTaskGroup = (taskGroup: TaskGroup) => {
        if (!taskGroup) {
            return;
        }

        setIsBlur && setIsBlur(prev => !prev);
        setEditTaskGroup(taskGroup);
        toggleShowEditTaskGroupModal();
    };

    const handleChangeEditTaskGroup = <K extends keyof typeof addTaskGroup>(
        key: K,
        value: (typeof addTaskGroup)[K]
    ) => {
        setEditTaskGroup(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const confirmEditTaskGroup = async () => {
        try {
            loader.show();

            const { group_id, group_description } = editTaskGroup;

            if (!group_id.trim() || !group_description.trim()) {
                throw new Error('group_id or group_description is null or empty.');
            }

            const [error, data] = await requestEditTaskGroup({ group_id, group_description });

            if (error) {
                throw error;
            }

            if (!data) {
                return;
            }

            if (data.result) {
                Swal.fire({
                    title: 'Success!',
                    text: `Edit task group success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then(() => onRefresh && onRefresh());
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
            setIsBlur && setIsBlur(prev => !prev);
            toggleShowEditTaskGroupModal();
            fetchTaskGroup();
            // onClose && onClose();
            // onRefresh && onRefresh();
        }
    };

    const handleDeleteTaskGroup = (taskGroup: TaskGroup) => {
        if (!taskGroup) {
            return;
        }

        setIsBlur && setIsBlur(prev => !prev);
        setDeleteTaskGroup(taskGroup);
        toggleShowDeleteTaskGroupModal();
    };

    const confirmDeleteTaskGroup = async () => {
        try {
            loader.show();

            const { group_id } = deleteTaskGroup;
            if (!group_id.trim()) {
                throw new Error('group_id is null or empty.');
            }

            const [error, data] = await DeleteTaskGroup(group_id);

            if (error) {
                throw error;
            }

            if (!data) {
                return;
            }
            if (data.result) {
                Swal.fire({
                    title: 'Success!',
                    text: `Delete task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then(() => onRefresh && onRefresh());
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
            setIsBlur && setIsBlur(prev => !prev);
            toggleShowDeleteTaskGroupModal();
            fetchTaskGroup();
            // onClose && onClose();
            onRefresh && onRefresh();

            console.log('jj taskgroupList', taskGroupList);
        }
    };

    const handleClickSaveTaskGroup = async () => {
        try {
            console.log('jj click save');
            console.log('jj taskgroupList', taskGroupList);

            const newTaskGroupList = taskGroupList.map((item, index) => {
                return {
                    ...item,
                    group_index: index + 1
                };
            });

            console.log('jj newtasklist', newTaskGroupList);
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();

            // onClose && onClose();
            // onRefresh && onRefresh();
        }
    };

    return {
        addTaskGroup,
        showAddTaskGroupModal,
        handleAddTaskGroup,
        handleChangeAddTaskGroup,
        confirmAddTaskGroup,
        editTaskGroup,
        showEditTaskGroupModal,
        handleEditTaskGroup,
        handleChangeEditTaskGroup,
        confirmEditTaskGroup,
        deleteTaskGroup,
        showDeleteTaskGroupModal,
        handleDeleteTaskGroup,
        confirmDeleteTaskGroup,
        taskGroupList,
        handleChangeOrder,
        handleClickSaveTaskGroup
    };
}

export default TaskGroupViewModel;
