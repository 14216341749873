import { DatasourceReturnType, ResponseError } from "models";
import { DmsUserDataReq } from "../schemas/dms.shema";
import { parseResponseError } from "common/helpers";
import { putInsertOrUpdateDmsUser } from "../datasources/dms.datasource";

export async function InsertOrUpdateDmsUser(body: DmsUserDataReq):
    Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await putInsertOrUpdateDmsUser(body)).data;

        return [null!, response];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
