import { useDeferredValue, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useReadLocalStorage, useWindowSize } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import { AiOutlineMenu } from 'react-icons/ai';

import { useStore, useUserApplicationMapping } from 'common/hooks';

import ApplicationAccordions from './ApplicationAccordions';

function SideNavigation() {
    const containerElementRef = useRef<HTMLDivElement>(null);

    const accessToken = useReadLocalStorage<string>('SSO_AUTH');
    const dimensions = useDeferredValue(useWindowSize());

    const { subDashboardStore, userCompanyStore } = useStore();
    const dataSubDashBoard = subDashboardStore.groupedApplications;
    const { citizenId } = userCompanyStore;
    const userApplicationProvider = useUserApplicationMapping();

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        if (!accessToken) {
            return;
        }

        fetchSubDashboardMenu();
    }, []);

    useEffect(() => {
        const containerElement = containerElementRef.current;

        if (!containerElement) {
            return;
        }

        const handleClick = (event: MouseEvent) => {
            if (!event.target) {
                return;
            }

            const target = event.target as HTMLElement;
            if (!containerElement.contains(target)) {
                setShowMenu(false);
            }
        };

        document.addEventListener('click', handleClick);

        return () => document.removeEventListener('click', handleClick);
    }, []);

    useEffect(() => {
        if (dataSubDashBoard.length > 0) {
            userApplicationProvider.setUserApplication(dataSubDashBoard);
        }
    }, [dataSubDashBoard]);

    const fetchSubDashboardMenu = async () => {
        await subDashboardStore.fetchSubDashboardMenu(citizenId);
    };

    return (
        <div
            ref={containerElementRef}
            className={twMerge(
                'absolute z-10 flex h-full flex-col overflow-y-auto bg-neutral-100 shadow-md transition-transform duration-300 sm:w-[360px] lg:relative lg:translate-x-0 lg:shadow-none',
                showMenu ? 'translate-x-0' : '-translate-x-full'
            )}
        >
            <div
                className="absolute left-[99.8%] top-0 z-10 cursor-pointer rounded-r-md bg-white/70 p-[10px] shadow-md lg:hidden"
                onClick={() => setShowMenu(prevState => !prevState)}
            >
                <AiOutlineMenu
                    size={22}
                    className="text-neutral-800"
                />
            </div>

            {dimensions.width > 1024 && <ApplicationAccordions />}
        </div>
    );
}

export default observer(SideNavigation);
