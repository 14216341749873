import * as React from 'react';
// import './style/login.css'; // Deprecated
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { store } from '../../store';

import { parseJwt } from '../../modules/common';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import UserSupportComponent from '../../components/user/UserSupportComponent';
import { useDisclosure } from '../../common/hooks';

type InviteProps = any;

export interface InviteBody {
    email: string | null;
    redirect: string | null;
    ssoWeb: string;
    waitLoading: boolean;
    isopen: boolean;
}
class invite extends React.Component<InviteProps, InviteBody> {
    constructor(props: any) {
        super(props);

        this.state = {
            email: '',
            redirect: '',
            ssoWeb: '',
            waitLoading: true,
            isopen: false
        };
        this.handleToggle = this.handleToggle.bind(this);
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const [redirect, email] = ['redirect', 'email'].map(key => searchParams.get(key));
        this.props.history.replace({
            pathname: '/invite',
            search: ''
        });

        fetch('/v1/sso/ext', {
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                result => {
                    console.log(result);

                    this.setState({
                        waitLoading: false,
                        ssoWeb: result.result.endpoint,
                        redirect: redirect,
                        email: email
                    });
                },
                error => {
                    console.log(error);
                }
            );
    }

    handleToggle() {
        this.setState({
            isopen: !this.state.isopen
        });
    }

    render() {
        const { waitLoading, redirect, ssoWeb, email } = this.state;
        if (waitLoading) {
            return <div></div>;
        }

        var isLoginSuccess = localStorage.getItem('SSO_AUTH');
        if (isLoginSuccess) {
            return <Redirect to="/" />;
        } else {
            //var redirect = window.location.href;

            const ssoAuth =
                ssoWeb +
                '/citizen/register?email=' +
                //ssoRedirectToken +
                email +
                '&redirect=' +
                redirect;
            //const ssoAuth = ssoWeb + '/auth/signin_v2?token=' + ssoRedirectToken + appendedMode+ "&redirect=" + redirect;
            //const ssoAuth = 'http://localhost:8000/auth/signin?token=' + ssoRedirectToken + appendedMode + "&redirect=" + redirect;
            var myContent = "<iframe style='width: 100%;height: 100%' src='" + ssoAuth + "' />";

            return (
                <div>
                    <div
                        style={{ height: '90vh', width: '100vw' }}
                        dangerouslySetInnerHTML={{ __html: myContent }}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <span
                            onClick={this.handleToggle}
                            className="text-[#0366D6] hover:underline"
                            style={{ cursor: 'pointer' }}
                        >
                            Contact Support
                        </span>
                    </div>

                    <Modal
                        isOpen={this.state.isopen}
                        className="my-0 max-w-[95vw_!important]"
                        contentClassName="min-h-[95vh] lg:mt-[1.25rem]"
                    >
                        <ModalHeader toggle={this.handleToggle}>
                            <span>Support</span>
                        </ModalHeader>
                        <ModalBody>
                            <UserSupportComponent
                                username=""
                                email=""
                                onClose={this.handleToggle}
                                from="login"
                            />
                        </ModalBody>
                    </Modal>
                </div>
            );
        }
    }
}

export default connect()(invite as any);
