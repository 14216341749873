import { useEffect, useMemo, useRef, useState } from 'react';
import Swal from 'sweetalert2';

import { extractAccessToken } from 'common/helpers';
import { useLoader, useStore } from 'common/hooks';
import { TaskStore } from 'models/taskStore/Task';
import { editTask } from 'data/taskStore/repositories/taskRepository';
import { Option } from 'models/taskGroup/TaskGroup';

type NormalRef = {
    getNormalFormData: () => Promise<FormData | undefined>;
};

type PosterRef = {
    getPosterFormData: () => Promise<FormData | undefined>;
};

type EditTaskViewModelProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    selectedTask?: TaskStore;
    taskGroupList: Option[];
};

function EditTaskViewModel({
    onClose,
    onRefresh,
    selectedTask,
    taskGroupList
}: EditTaskViewModelProps) {
    const { userCompanyStore } = useStore();
    const loader = useLoader();
    const accessToken = extractAccessToken();

    const normalRef = useRef<NormalRef>(null);
    const posterRef = useRef<PosterRef>(null);

    const mode = useMemo(() => {
        return ['Normal', 'Poster'];
    }, []);

    const [selectedMode, setSelectedMode] = useState<string>(mode[0]);

    useEffect(() => {
        if (!selectedTask) {
            return;
        }
        setSelectedMode(selectedTask.mode);
    }, [selectedTask]);

    const handleChangeMode = (selectedMode: string) => {
        setSelectedMode(selectedMode);
    };

    const handleClickEditTask = async () => {
        try {
            loader.show();

            const mergeFormData = new FormData();
            mergeFormData.append('mode', selectedMode);

            const normalFormData = await normalRef.current?.getNormalFormData();

            if (!normalFormData) {
                return;
            }

            for (const [key, value] of normalFormData.entries()) {
                mergeFormData.append(key, value);
            }

            if (selectedMode.toLowerCase() === mode[1].toLowerCase()) {
                const posterFormData = await posterRef.current?.getPosterFormData();

                if (!posterFormData) {
                    return;
                }

                for (const [key, value] of posterFormData.entries()) {
                    mergeFormData.append(key, value);
                }
            }

            // for (const [key, value] of mergeFormData.entries()) {
            //     console.log(`mergeFormData : ${key} : ${value}`);
            // }

            const [error, data] = await editTask(mergeFormData);

            if (error) {
                throw error;
            }

            if (data.result) {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Edit task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) {
                    onClose && onClose();
                    onRefresh && onRefresh();
                }
            }
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });
        } finally {
            loader.hide();
        }
    };

    // const handleClickEditTask = async () => {
    //     try {
    //         loader.show();

    //         const { key_id, subject, created_by: createdBy, attachment } = task;

    //         if (!subject.trim()) {
    //             throw new Error('Subject must not be null or empty.');
    //         }

    //         const attachmentData = JSON.stringify(attachment);

    //         const formData = new FormData();
    //         formData.append('key_id', key_id);
    //         formData.append('subject', subject);
    //         formData.append('group_id', selectedTaskGroup?.value ?? '');
    //         formData.append('created_by', createdBy);
    //         formData.append('attachment', attachmentData);
    //         formData.append('updated_by', userCompanyStore.citizenId);
    //         formData.append('updated_on', '');

    //         // Attach creator picture if exists
    //         if (profileUpload) {
    //             formData.append(`profile`, profileUpload, 'profile-pic-' + profileUpload.name);
    //         }

    //         // Attach files that outside editor if exists
    //         if (filesUpload && filesUpload.length > 0) {
    //             const existingFileNames = new Set(
    //                 attachment && attachment.file_list
    //                     ? attachment.file_list.map(fileObj => fileObj.file_name)
    //                     : []
    //             );

    //             filesUpload.forEach((file, index) => {
    //                 if (!existingFileNames.has(file.name)) {
    //                     formData.append(`file-${index + 1}`, file, file.name);
    //                     // console.log('jj file', file);
    //                 }
    //             });
    //         }

    //         // Attach file inside editor if exists
    //         const editorContentDOM = document.createElement('div');
    //         editorContentDOM.innerHTML = model;

    //         const attachmentNodes = editorContentDOM.querySelectorAll('img, video') as NodeListOf<
    //             HTMLImageElement | HTMLVideoElement
    //         >;
    //         await Promise.all(
    //             Array.from(attachmentNodes).map(async (node, index) => {
    //                 const url = node.getAttribute('src')!;

    //                 // if already exists on s3
    //                 if (url.includes('s3')) {
    //                     return;
    //                 }

    //                 const fileName = 'editor-file-' + (index + 1);
    //                 const file = await axios
    //                     .get<Blob>(url, { responseType: 'blob' })
    //                     .then(response => new File([response.data], fileName));

    //                 node.src = fileName;

    //                 URL.revokeObjectURL(url);
    //                 formData.append(file.name, file);
    //             })
    //         );

    //         const innerHtml = editorContentDOM.innerHTML;
    //         formData.append('description', innerHtml);

    //         const [error, data] = await editTask(formData);
    //         if (error) {
    //             throw error;
    //         }

    //         if (data.result) {
    //             await Swal.fire({
    //                 title: 'Success!',
    //                 text: `Edit task success`,
    //                 icon: 'success',
    //                 showCancelButton: false,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'Ok',
    //                 allowOutsideClick: false
    //             });
    //         }
    //     } catch (error) {
    //         await Swal.fire({
    //             title: 'Error!',
    //             text: (error as any).message,
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#3085d6',
    //             confirmButtonText: 'Yes',
    //             allowOutsideClick: false
    //         });
    //     } finally {
    //         loader.hide();

    //         onClose && onClose();
    //         onRefresh && onRefresh();
    //     }
    // };

    return {
        normalRef,
        posterRef,
        mode,
        selectedMode,
        handleChangeMode,
        handleClickEditTask
    };
}

export default EditTaskViewModel;
