import React, { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Gifs } from 'assets';
import {
    Button,
    IconButton,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    useConst
} from '@chakra-ui/react';
import { BsSpeedometer2 } from 'react-icons/bs';

type PingStatus = 'neutral' | 'fast' | 'slow' | 'very slow';

const PING_STATUS_TW_CLASSES = new Map<PingStatus, string>([
    ['fast', 'text-success-400'],
    ['slow', 'text-yellow-400'],
    ['very slow', 'text-danger-400']
]);

const IMAGE_URL =
    'https://focusone-documents.s3.ap-southeast-1.amazonaws.com/speed_test/10.5-MB-scaled.bmp';
const DOWNLOAD_SIZE = 13107200;

function SpeedTestPopover() {
    const popoverRef = useRef<HTMLElement>(null);

    const [fetching, setFetching] = useState(false);
    const [ping, setPing] = useState<number>();
    const [downloadSpeed, setDownloadSpeed] = useState<number>();

    const pingStatus: PingStatus = !ping
        ? 'neutral'
        : ping > 0 && ping < 100
        ? 'fast'
        : ping < 150
        ? 'slow'
        : 'very slow';

    useEffect(() => {
        setTimeout(() => fetchPing(true), 200);
    }, []);

    const fetchDownloadSpeed = useCallback(() => {
        let startTime: number;
        let endTime: number;

        setFetching(true);
        setDownloadSpeed(-1);

        const download = new Image();
        download.onload = () => {
            endTime = new Date().getTime();
            if (endTime && startTime) {
                const duration = (endTime - startTime) / 1000;
                const bitsLoaded = DOWNLOAD_SIZE * 8;
                const speedBps: number = bitsLoaded / duration;
                const speedKbps: number = speedBps / 1024;
                const speedMbps: number = speedKbps / 1024;

                setDownloadSpeed(speedMbps);
                setFetching(false);
            }
        };
        download.onerror = () => {
            setDownloadSpeed(-1);
        };

        startTime = new Date().getTime();
        download.src = IMAGE_URL + '?at=' + startTime;
    }, []);

    const fetchPing = useCallback((warmup?: boolean) => {
        setFetching(true);

        let startTime: number;
        let endTime: number;

        const http = new XMLHttpRequest();
        http.open('GET', 'https://focusone-documents.s3.ap-southeast-1.amazonaws.com', true);
        http.onreadystatechange = () => {
            if (http.readyState == 4) {
                endTime = new Date().getTime();
                const milliseconds = endTime - startTime;

                if (warmup) {
                    setFetching(false);

                    return;
                }

                setPing(milliseconds);

                setTimeout(() => {
                    fetchDownloadSpeed();
                }, 100);
            }
        };

        try {
            startTime = new Date().getTime();
            http.send(null);
        } catch {}
    }, []);

    const handleClickStart = useCallback(() => {
        setTimeout(fetchPing, 0);
        popoverRef.current?.focus();
    }, []);

    const spinner = useConst(
        <img
            src={Gifs.F1_LOADINGGIF}
            className="h-6 w-6"
        />
    );

    return (
        <Popover
            isLazy
            placement="bottom-end"
        >
            <PopoverTrigger>
                <IconButton
                    size="md"
                    variant="unstyled"
                    aria-label="Speed Test"
                    className="group"
                    icon={
                        <BsSpeedometer2
                            size={28}
                            className="text-white transition-colors group-hover:text-primary-900"
                        />
                    }
                />
            </PopoverTrigger>
            <PopoverContent ref={popoverRef}>
                <PopoverCloseButton />
                <PopoverHeader className="border-0 text-xl font-medium">Speed Test</PopoverHeader>
                <PopoverBody className="space-y-4">
                    <div>
                        <p className="text-neutral-600">Ping</p>
                        <div>
                            {fetching ? (
                                spinner
                            ) : !ping ? (
                                '-'
                            ) : (
                                <React.Fragment>
                                    <p className="space-x-2">
                                        <span
                                            className={twMerge(
                                                'text-2xl',
                                                PING_STATUS_TW_CLASSES.get(pingStatus)
                                            )}
                                        >
                                            {ping / 1000}
                                        </span>
                                        <span className="text-lg">วินาที</span>
                                    </p>
                                    <p className={PING_STATUS_TW_CLASSES.get(pingStatus)}>
                                        ({ping} ms)
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div>
                        <p className="text-neutral-600">Download Speed</p>
                        <div>
                            {fetching ? (
                                spinner
                            ) : !downloadSpeed ? (
                                '-'
                            ) : (
                                <p className="space-x-2">
                                    <span className="text-2xl">{downloadSpeed.toFixed(2)}</span>
                                    <span className="text-lg">Mbps</span>
                                </p>
                            )}
                        </div>
                    </div>
                </PopoverBody>
                <PopoverFooter className="flex justify-end border-0">
                    <Button
                        size="sm"
                        isLoading={fetching}
                        spinner={spinner}
                        className="bg-primary-900 text-white hover:bg-primary-700"
                        onClick={handleClickStart}
                    >
                        Start
                    </Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    );
}

export default SpeedTestPopover;
