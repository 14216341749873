import axios from 'axios';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';

import { extractAccessToken } from 'common/helpers';
import { FULL_MONTHS } from 'common/helpers/format';
import { useDisclosure, useLoader } from 'common/hooks';
import { getTaskGroup } from 'data/taskGroup/repositories/taskGroupRepository';
import { getTask } from 'data/taskStore/repositories/taskRepository';
import { TaskStoreMapping } from 'data/taskStore/schemas';
import { ResponseList } from 'models';
import { Option } from 'models/taskGroup/TaskGroup';
import {
    Attachment,
    CommentTaskResponse,
    LikeTaskResponse,
    LikedTask,
    TaskCommnet,
    TaskStack,
    TaskStackResponse,
    TaskStore
} from 'models/taskStore/Task';
import { arrGetUserCompanyMaster } from 'models/user/UserCompanyMaster';

type TaskListGroupByType = {
    task: TaskStore[];
    announcement: TaskStore[];
};

type TaskListGroupByMonth = {
    date: string;
    month: string;
    year: string;
    tasks: TaskStore[];
};

const DEFAULT_GROUP: Option = {
    index: '0',
    label: 'Show All',
    value: '9999'
};

const tabs = ['Information Update', 'Announcement'];

function FeedTaskViewModel() {
    const loader = useLoader();
    const accessToken = extractAccessToken();

    const { isOpen: showModalNewTask, onToggle: toggleShowModalNewTaskPopup } = useDisclosure();
    const { isOpen: showNewAnnouncementModal, onToggle: toggleNewAnnouncementModal } =
        useDisclosure();
    const { isOpen: showCloseTaskModal, onToggle: toggleShowCloseTaskModal } = useDisclosure();
    const { isOpen: showReleaseTaskModal, onToggle: toggleShowReleaseTaskModal } = useDisclosure();
    const { isOpen: showCancelTaskModal, onToggle: toggleShowCancelTaskModal } = useDisclosure();
    const { isOpen: showEditTaskModal, onToggle: toggleShowModalEditTaskModal } = useDisclosure();
    const { isOpen: showEditAnnouncementModal, onToggle: toggleShowModalEditAnnouncementModal } =
        useDisclosure();
    const { isOpen: showTaskGroupModal, onToggle: toggleShowTaskGroupModal } = useDisclosure();

    const [userType, setUserType] = useState('');

    const [taskGroupList, setTaskGroupList] = useState<Option[]>([DEFAULT_GROUP]);
    const [selectedTaskGroup, setSelectedTaskGroup] = useState<SingleValue<Option>>(DEFAULT_GROUP);
    const [taskStoreList, setTaskStoreList] = useState<TaskStoreMapping[]>();

    // ? app-pool-task-store
    const [selectedTaskActivity, setSelectedTaskActivity] = useState<TaskStore>();
    const [announcementList, setAnnouncementList] = useState<TaskStore[]>([]);
    const announcementGroups = useMemo(
        () => groupTaskStoreByMonth(announcementList),
        [announcementList]
    );
    const [taskList, setTaskList] = useState<TaskStore[]>([]);
    const taskGroups = useMemo(() => {
        const groupedTask: TaskListGroupByMonth[] = groupTaskStoreByMonth(taskList);

        if (selectedTaskGroup?.value === '9999') {
            return groupedTask;
        } else {
            return groupedTask
                .map(group => ({
                    ...group,
                    tasks: group.tasks.filter(task => task.group_id === selectedTaskGroup?.value)
                }))
                .filter(group => group.tasks.length > 0);
        }
    }, [taskList, selectedTaskGroup]);
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    // Use for track user already pressed like or not
    const isLiking = useRef(false);

    useEffect(() => {
        // fetchTaskInbox('all'); // ? link-task
        fetchUserCompanyMapping();
        fetchTaskGroup();
        fetchLike();
    }, []);

    useEffect(() => {
        if (!userType) {
            return;
        }

        handleFetchTaskStore(); // ? app-pool-task-store
    }, [userType]);

    const fetchTaskGroup = async () => {
        try {
            loader.show();

            const [error, data] = await getTaskGroup('all');
            if (error) {
                throw error;
            }

            if (data.length === 0) {
                return;
            }

            const groupList = data.map(group => ({
                index: group.group_index,
                label: group.group_description,
                value: group.group_id
            }));

            setTaskGroupList([DEFAULT_GROUP, ...groupList]);
        } catch (e) {
            console.log('[Debug] req get task group failed. ->', e);
        } finally {
            loader.hide();
        }
    };

    const fetchTaskStore = async (filter: string) => {
        try {
            loader.show();

            const [error, data] = await getTask({ filter });

            if (error) {
                throw error;
            }
            if (data.length === 0) {
                return;
            }

            setTaskStoreList(data!);

            const groupByType = data.reduce(
                (result: TaskListGroupByType, current) => {
                    current.is_release = current.is_release === ('true' as unknown as boolean);
                    current.is_active = current.is_active === ('true' as unknown as boolean);

                    if (current.type === 'task') {
                        result.task.push(current);
                    } else {
                        result.announcement.push(current);
                    }
                    return result;
                },
                { task: [], announcement: [] }
            );

            setTaskList(groupByType.task);
            setAnnouncementList(groupByType.announcement);
        } catch (e) {
            console.log('[Debug] req get task store failed. ->', e);
        } finally {
            loader.hide();
        }
    };

    // ? fetch link-task
    // const fetchTaskInbox = async (filter: string, options?: { nextFeed?: boolean }) => {
    //     try {
    //         loader.show();

    //         let excludeKey = options?.nextFeed ? taskList.map(task => task.key).join(',') : '';

    //         console.log('ngame exclude key', excludeKey);

    //         const data = (
    //             await axios.post<ResponseList<TaskInbox>>(
    //                 'v1/link/task-inbox',
    //                 { exclude_key: excludeKey },
    //                 {
    //                     params: {
    //                         filter
    //                     },
    //                     headers: {
    //                         Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
    //                         'Content-Type': 'application/json'
    //                     }
    //                 }
    //             )
    //         ).data;

    //         if (data.total_count === 0) {
    //             return;
    //         }

    //         console.log('jj', data.result_list);
    //         if (options?.nextFeed) {
    //             setTaskList(prevState => {
    //                 const combinedTaskList = [...prevState, ...data.result_list];

    //                 return combinedTaskList.sort((a, b) => {
    //                     const checkInDateTimeA = a.checkin_date + a.checkin_time;
    //                     const checkInDateTimeB = b.checkin_date + b.checkin_time;

    //                     if (checkInDateTimeA > checkInDateTimeB) {
    //                         return -1;
    //                     } else if (checkInDateTimeA < checkInDateTimeB) {
    //                         return 1;
    //                     }

    //                     return 0;
    //                 });
    //             });
    //         } else {
    //             setTaskList(data.result_list);
    //         }

    //         loader.hide();
    //     } catch (e) {
    //         // console.log('[Debug] req get task inbox failed. ->', e);

    //         loader.hide();
    //     }
    // };

    const fetchUserCompanyMapping = async () => {
        try {
            const data = (
                await axios.get<ResponseList<arrGetUserCompanyMaster>>('v1/company_master/user', {
                    params: {
                        citizen_id: accessToken.citizen_id
                    },
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                    }
                })
            ).data;

            if (data.total_count === 0) {
                setUserType('user');
                return;
            }

            const userType = findUserType(data.result_list);

            // setUserCompanyInfoList(data.result_list);
            setUserType(userType);
        } catch (error) {
            console.log('get user company mapping failed!', error);
        }
    };

    const findUserType = (userInfoList: arrGetUserCompanyMaster[]) => {
        const foundSuperAdminType = userInfoList.find(
            user => user.type.toLowerCase() === 'super_admin'
        );
        if (foundSuperAdminType) return 'super_admin';

        const foundAdminType = userInfoList.find(user => user.type.toLowerCase() === 'admin');
        if (foundAdminType) return 'admin';

        return 'user';
    };

    const handleFetchTaskStore = () => {
        if (userType === 'super_admin') {
            fetchTaskStore('all');
        } else {
            fetchTaskStore('task');
        }
    };

    const handleCloseTask = (task?: TaskStore) => {
        if (!task) {
            setSelectedTaskActivity(undefined);
        }

        setSelectedTaskActivity(task);
        toggleShowCloseTaskModal();
    };

    const handleReleaseTask = (task?: TaskStore) => {
        if (!task) {
            setSelectedTaskActivity(undefined);
        }

        setSelectedTaskActivity(task);
        toggleShowReleaseTaskModal();
    };

    const handleCancelTask = (task?: TaskStore) => {
        if (!task) {
            setSelectedTaskActivity(undefined);
        }

        setSelectedTaskActivity(task);
        toggleShowCancelTaskModal();
    };

    const handleEditTask = (task?: TaskStore) => {
        if (!task) {
            setSelectedTaskActivity(undefined);
        }

        setSelectedTaskActivity(task);
        toggleShowModalEditTaskModal();
    };

    const handleEditAnnouncement = (task?: TaskStore) => {
        if (!task) {
            setSelectedTaskActivity(undefined);
        }

        setSelectedTaskActivity(task);
        toggleShowModalEditAnnouncementModal();
    };

    const handleChangeTab = (selectedTab: string) => {
        setSelectedTab(selectedTab);
    };

    const handleChangeTaskGroup = (selectedTaskGroup: SingleValue<Option>) => {
        setSelectedTaskGroup(selectedTaskGroup);
    };

    function groupTaskStoreByMonth(taskList: TaskStore[]) {
        return taskList.reduce<TaskListGroupByMonth[]>((accumulate, task) => {
            const formatDate = new Date(Date.parse(task.updated_on));
            const year = formatDate.getFullYear().toString();
            const monthNumber = formatDate.getMonth() + 1;
            const month = FULL_MONTHS[monthNumber - 1];
            const date = formatDate.getDate().toString();

            const foundTaskGroup = accumulate.find(
                taskGroup => taskGroup.year === year && taskGroup.month === month
            );

            if (foundTaskGroup) {
                foundTaskGroup.tasks.push(task);
            } else {
                accumulate.push({
                    date,
                    month,
                    year,
                    tasks: [task]
                });
            }

            return accumulate;
        }, []);
    }

    const [showImageIndex, setShowImageIndex] = useState(0);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    // const [selectedTask, setSelectedTask] = useState<TaskInbox>(); // ? link-task
    const [scrollToComment, setScrollToComment] = useState(false);
    const [selectedTask, setSelectedTask] = useState<TaskStore>(); // ? app-pool-task-store
    const profilePic = useMemo(() => {
        if (!selectedTask) {
            return;
        }

        const { attachment } = selectedTask;
        const attatchmentObject: Attachment = attachment ? JSON.parse(attachment) : '';

        return attatchmentObject.profile_pic;
    }, [selectedTask]);
    const removeSelectedTask = useCallback(() => {
        setSelectedTask(undefined);
        setScrollToComment(false);
    }, []);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyIdValue = searchParams.get('key_id');

    useEffect(() => {
        if (keyIdValue !== null) {
            const fillterTaskStore =
                taskStoreList &&
                taskStoreList.find(value => {
                    return value.key_id === keyIdValue;
                });

            if (fillterTaskStore !== undefined) {
                const { attachment } = fillterTaskStore!;
                const parseAttachment: Attachment = attachment ? JSON.parse(attachment) : '';

                const { file_list } = parseAttachment;

                const images = file_list.reduce(
                    (accumulate, current) => {
                        if (
                            current.file_path.includes('.jpg') ||
                            current.file_path.includes('.png') ||
                            current.file_path.includes('.jpeg') ||
                            current.file_path.includes('.mp4')
                        ) {
                            accumulate[0].push(current.file_path);
                        } else {
                            accumulate[1].push(current.file_path);
                        }

                        return accumulate;
                    },
                    [[], []] as [string[], string[]]
                );

                const imageArray = [...images[0], ...images[1]];
                setSelectedTask(fillterTaskStore);
                setShowImageIndex(-1);
                setSelectedImages(imageArray ?? []);
            }
        }
    }, [keyIdValue !== null, taskStoreList]);

    const handleEventElement = async (task: TaskStore, eventType: string) => {
        // Prevent user spam a like button
        if (isLiking.current) {
            return;
        }

        isLiking.current = true;

        try {
            switch (eventType) {
                case 'like':
                    await axios.post<any>('v1/task-like/like', null, {
                        params: { key_id: task.key_id, username: accessToken.username }
                    });

                    isLiking.current = false;

                    break;
                case 'unlike':
                    await axios.delete<any>('v1/task-like/unlike', {
                        params: { key_id: task.key_id, username: accessToken.username }
                    });

                    isLiking.current = false;

                    break;
                case 'share':
                    const currentUrl = window.location.href;

                    Swal.fire({
                        title: 'Share To',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Send',
                        showLoaderOnConfirm: true,
                        preConfirm: async email => {
                            await axios.post<any>('v1/task-share/share', null, {
                                params: {
                                    key_id: task.key_id,
                                    username: accessToken.username,
                                    email: email,
                                    form_email: accessToken.email,
                                    current_url: currentUrl
                                },
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                                }
                            });
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    });

                    break;
            }
        } catch (error) {}

        fetchLike();
    };

    const [liked, setLiked] = useState<Map<string, LikedTask>>();
    const [taskStack, setTaskStack] = useState<TaskStack[]>();

    const fetchLike = async () => {
        const data = (
            await axios.get<ResponseList<LikedTask | undefined>>('v1/task-like/get', {
                params: { username: accessToken.citizen_id ?? accessToken.username },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
        ).data;

        if (!data.result_list) {
            return;
        }

        setLiked(new Map(data.result_list.map(task => [task.key_id, task])));

        fetchTaskStack();
    };

    const fetchTaskStack = async () => {
        const dataStack = (
            await axios.get<TaskStackResponse>('v1/task-stack', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
        ).data;

        setTaskStack(dataStack.result_list);
    };

    const { isOpen: showUserEventModal, onToggle: toggleShowUserEventModal } = useDisclosure();
    const { isOpen: showUserCommentModal, onToggle: toggleShowUserCommentModal } = useDisclosure();

    const [userEventStatus, setUserEventStatus] = useState<{ type: string; username: string[] }>();
    const [keyIdComment, setKeyIdComment] = useState<string>();

    const onClickShowUserEvent = (type: string, username: string[]) => {
        toggleShowUserEventModal();
        setUserEventStatus({ type: type, username: username! });
    };

    const onClickShowCommentEvent = (keyId: string) => {
        setKeyIdComment(keyId);
        fetchComment(keyId);
        // toggleShowUserCommentModal();
    };

    const onSubmitComment = async (keyId: string, message: string) => {
        await axios.post<any>('v1/task-comment/create', null, {
            params: { key_id: keyId, message, username: accessToken.username },
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            }
        });

        fetchComment(keyId);
    };

    const [postCommnet, setPostComment] = useState<TaskCommnet[]>();

    const fetchComment = async (keyId: string) => {
        const data = (
            await axios.get<CommentTaskResponse>('v1/task-comment/get', {
                params: { key_id: keyId },
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            })
        ).data;

        setPostComment(data.result_list);
        fetchTaskStack();
    };

    const handleReadmore = async (keyId: string) => {
        if (
            taskStack
                ?.find(task => task.key_id === keyId)
                ?.view_username.includes(accessToken.username)
        ) {
            return;
        }

        await axios.post<any>('v1/task-view/add', null, {
            params: { key_id: keyId, username: accessToken.username },
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
            }
        });

        fetchTaskStack();
    };

    const handleEventComment = async (
        type: string,
        commentId: string,
        keyId: string,
        message?: string
    ) => {
        switch (type) {
            case 'reply':
                await axios.post<any>('v1/task-comment/reply', null, {
                    params: {
                        key_id: keyId,
                        ref_comment_id: commentId,
                        message: message,
                        username: accessToken.username
                    },
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                    }
                });
                break;
            case 'edit':
                await axios.post<any>('v1/task-comment/edit', null, {
                    params: {
                        key_id: keyId,
                        comment_id: commentId,
                        message: message,
                        username: accessToken.username
                    },
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                    }
                });
                break;
            case 'delete':
                await axios.delete<any>('v1/task-comment/delete', {
                    params: {
                        key_id: keyId,
                        comment_id: commentId
                    },
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                    }
                });
                break;
        }
        fetchComment(keyId);
        fetchTaskStack();
    };

    return {
        tabs,
        liked,
        userType,
        taskList,
        taskStack,
        profilePic,
        taskGroups,
        selectedTab,
        postCommnet,
        keyIdComment,
        selectedTask,
        taskGroupList,
        selectedImages,
        showImageIndex,
        userEventStatus,
        announcementList,
        showModalNewTask,
        showEditTaskModal,
        selectedTaskGroup,
        showTaskGroupModal,
        announcementGroups,
        showCloseTaskModal,
        showUserEventModal,
        showCancelTaskModal,
        showUserCommentModal,
        selectedTaskActivity,
        showReleaseTaskModal,
        showNewAnnouncementModal,
        showEditAnnouncementModal,
        scrollToComment,
        // fetchTaskInbox,

        handleEditTask,
        fetchTaskGroup,
        handleReadmore,
        setSelectedTask,
        handleChangeTab,
        onSubmitComment,
        handleCloseTask,
        handleCancelTask,
        handleReleaseTask,
        setSelectedImages,
        setShowImageIndex,
        handleEventComment,
        handleEventElement,
        removeSelectedTask,
        onClickShowUserEvent,
        handleFetchTaskStore,
        handleChangeTaskGroup,
        handleEditAnnouncement,
        onClickShowCommentEvent,
        toggleShowUserEventModal,
        toggleShowTaskGroupModal,
        toggleShowUserCommentModal,
        toggleNewAnnouncementModal,
        toggleShowModalNewTaskPopup,
        setScrollToComment
    };
}

export default FeedTaskViewModel;
