import React, { createRef, useCallback, useRef } from 'react';

function useDynamicRefs() {
    const refsMap = useRef(new Map<string, React.RefObject<unknown>>()).current;

    const getRef = useCallback(<T>(key: string) => refsMap.get(key) as React.RefObject<T>, []);

    const setRef = useCallback(<T>(key: string) => {
        const existsRef = refsMap.get(key);
        if (existsRef) {
            return existsRef as React.RefObject<T>;
        }

        const ref = createRef<T>();
        refsMap.set(key, ref);

        return ref;
    }, []);

    return [getRef, setRef] as const;
}

export default useDynamicRefs;
