import { DatasourceReturnType, ResponseError } from "models";
import { putInsertOrUpdateF1Wrapper } from "../datasources/f1Connect.datasource";
import { EmployeeF1Request } from "../schemas/f1Connect.shema";
import { parseResponseError } from "common/helpers";

export async function insertOrUpdateF1User(body: EmployeeF1Request):
    Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const response = (await putInsertOrUpdateF1Wrapper(body)).data;

        return [null!, response];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
