import axios from 'axios';
import { BuildVersionResponse } from '../schemas';

export async function get() {
    return axios.get<BuildVersionResponse>('/v1/version', {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
