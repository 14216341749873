import * as React from 'react';
// import './style/login.css'; // Deprecated
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import SupportModal from 'common/components/TopNavigation/SupportModal';
import * as Authentication from 'store/authentication/Authentication';
import * as Models from 'models/authentication/Authentication';
import { Redirect } from 'react-router';
import { store } from 'store';

import { parseJwt } from 'modules/common';
import axios, { AxiosError } from 'axios';
import { extractAccessToken } from 'common/helpers';

function RedirectToHome() {
    const [isLogged, setIsLogged] = React.useState<'pending' | 'success' | 'failed'>('pending');
    const [error, setError] = React.useState<string>();

    React.useEffect(() => {
        const logAuthHistory = async () => {
            try {
                setIsLogged('pending');

                const { citizen_id } = extractAccessToken();

                await axios.post(
                    '/v1/log-auth-history',
                    {
                        citizen_id,
                        mode: localStorage.getItem('mode') || 'web'
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('SSO_AUTH')}`
                        }
                    }
                );

                setIsLogged('success');
            } catch (error) {
                console.error('log-auth-history', error);
                setIsLogged('failed');

                if (error instanceof AxiosError) {
                    setError(JSON.stringify(error.response?.data));
                } else if (error instanceof Error) {
                    setError(error.message);
                }
            }
        };

        logAuthHistory();
    }, []);

    if (isLogged === 'pending') return null;

    if (isLogged === 'failed') {
        return (
            <div>
                <p className="font-bold">Failed to logged authentication</p>
                <p>Error: {error}</p>
            </div>
        );
    }

    return <Redirect to="/" />;
}

type AuthenticationProps = any &
    Models.AuthenticationState &
    typeof Authentication.actionCreators &
    AuthenticationBody;

export interface AuthenticationBody {
    email: string;
    password: string;
    isHideAlert: boolean;
    waitLoading: boolean;
    ssoWeb: string;
    ssoRedirectToken: string;
    isopen: boolean;
}

class login extends React.Component<AuthenticationProps, AuthenticationBody> {
    constructor(props: any) {
        super(props);

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            email: '',
            password: '',
            isHideAlert: false,
            waitLoading: true,
            ssoWeb: '',
            ssoRedirectToken: '',
            isopen: false
        };
        this.handleToggle = this.handleToggle.bind(this);
        this.handleEvent = this.handleEvent.bind(this);
        this.isFromMobileBrowser = this.isFromMobileBrowser.bind(this);
    }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const [token, mode] = ['token', 'mode'].map(key => searchParams.get(key));

        // console.log('ngame', token, mode);

        try {
            (window as any).chrome.webview.addEventListener('message', this.handleEvent);

            var jResWeb = {
                action: 'MODE'
            };

            var res = JSON.stringify(jResWeb);

            (window as any).chrome.webview.postMessage(res);
            console.log('login on gui');
        } catch {
            console.log('login on web');
        }

        fetch('/v1/sso/ext', {
            method: 'GET'
        })
            .then(res => res.json())
            .then(
                result => {
                    console.log(result);
                    console.log(this.props.mode);
                    if (!this.props.mode) {
                        store.userCompanyStore.mode = mode ?? '';
                        this.props.setMode(mode);
                    }

                    if (token != null) {
                        var jwt = parseJwt(token);
                        if (jwt.extra != undefined) {
                            var extra = JSON.parse(jwt.extra);
                            var citizenId = extra.citizen_id;
                            if (!citizenId) {
                                var citizenId = extra.username;
                            }
                            console.log(extra);
                            console.log(citizenId);
                            if (citizenId != undefined) {
                                var jsonToken = {
                                    access_token: token
                                };

                                localStorage.setItem('SSO_AUTH', JSON.stringify(jsonToken));
                                store.userCompanyStore.fetchUserFromLocalStorage();
                                //window.location.reload();
                            }
                        }
                    }

                    this.setState({
                        waitLoading: false,
                        ssoWeb: result.result.endpoint,
                        ssoRedirectToken: result.result.token
                    });
                },
                error => {
                    console.log(error);
                }
            );
    }

    handleEvent(message) {
        console.log(message.data);
        store.userCompanyStore.mode = message.data ?? '';
        this.props.setMode(message.data);
    }

    handleSubmit(e: any) {
        var { email, password } = this.state;

        this.props.requestAuthentication({ email: email, password: password });

        this.setState({
            isHideAlert: false,
            waitLoading: true
        });

        e.preventDefault();
    }

    handleEmailChange(e: any) {
        this.setState({
            email: e.target.value
        });
    }

    handlePasswordChange(e: any) {
        this.setState({
            password: e.target.value
        });
    }

    handleToggle() {
        this.setState({
            isopen: !this.state.isopen
        });
    }

    isFromMobileBrowser() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    render() {
        const { waitLoading, ssoWeb, ssoRedirectToken } = this.state;
        if (waitLoading) {
            return <div></div>;
        }

        var isLoginSuccess = localStorage.getItem('SSO_AUTH');
        if (isLoginSuccess) {
            return <RedirectToHome />;
        } else {
            const { mode } = this.props;
            var appendedMode = '';
            if (mode) {
                appendedMode = '&mode=' + mode;
            }
            var redirect = window.location.href;

            const currentDate = new Date();
            const timestamp = String(
                currentDate.getFullYear().toString() +
                    (currentDate.getMonth() + 1).toString().padStart(2, '0') +
                    currentDate.getDate().toString().padStart(2, '0') +
                    currentDate.getHours().toString().padStart(2, '0') +
                    currentDate.getMinutes().toString().padStart(2, '0') +
                    currentDate.getSeconds().toString().padStart(2, '0')
            );

            const ssoAuth =
                ssoWeb +
                '/auth/signin?token=' +
                ssoRedirectToken +
                appendedMode +
                '&redirect=' +
                redirect +
                '&timestamp=' +
                timestamp;
            //const ssoAuth = ssoWeb + '/auth/signin_v2?token=' + ssoRedirectToken + appendedMode+ "&redirect=" + redirect;
            //const ssoAuth = 'http://localhost:8000/auth/signin?token=' + ssoRedirectToken + appendedMode + "&redirect=" + redirect;
            var myContent = "<iframe style='width: 100%;height: 100%' src='" + ssoAuth + "' />";

            const isMobile = this.isFromMobileBrowser();
            if (isMobile) {
                (window as any).top.location.href = ssoAuth;
            }

            return (
                <div>
                    <div
                        style={{ height: '90vh', width: '100vw' }}
                        dangerouslySetInnerHTML={{ __html: myContent }}
                    />
                    <div style={{ textAlign: 'center' }}>
                        <span
                            onClick={this.handleToggle}
                            className="text-[#0366D6] hover:underline"
                            style={{ cursor: 'pointer' }}
                        >
                            Contact Support
                        </span>
                    </div>

                    <SupportModal
                        from="login"
                        isOpen={this.state.isopen}
                        onClose={this.handleToggle}
                    />
                </div>
            );
        }
    }
}

export default connect(
    (state: ApplicationState) => state.authentication,
    Authentication.actionCreators
)(login as any);
