import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { CreateTaskResponse } from '../../models/taskStore/Task';
import useLoader from '../../common/hooks/useLoader';
import {
    extractAccessToken,
    parseDateTimeStringToISO,
    parseDateToString
} from '../../common/helpers';
import { editTaskAnnouncement } from '../../data/taskStore/repositories/taskRepository';

import { EditAnnouncementViewProps } from './EditAnnouncement.view';

type EditAnnouncement = {
    key_id: string;
    type: string;
    subject: string;
    description?: string;
    start_date_time: Date;
    end_date_time: Date;
};

function EditAnnouncementViewModel({
    onClose,
    onRefresh,
    selectedAnnouncement: selectedTask
}: EditAnnouncementViewProps) {
    const loader = useLoader();

    const [announcementForm, setAnnouncementForm] = useState<EditAnnouncement>({
        key_id: '',
        type: '',
        subject: '',
        description: '',
        start_date_time: new Date(),
        end_date_time: new Date()
    });

    //const LIMIT_DESCRIPTION_CHAR = 3000;

    useEffect(() => {
        if (!selectedTask) {
            return;
        }

        const { key_id, type, subject, description, start_date_time, end_date_time } = selectedTask;

        // console.log('jj selected announcement');

        setAnnouncementForm({
            key_id,
            type,
            subject,
            description,
            start_date_time: new Date(
                parseDateTimeStringToISO(
                    start_date_time.substring(0, 8),
                    start_date_time.substring(8, 14)
                )
            ),
            end_date_time: new Date(
                parseDateTimeStringToISO(
                    end_date_time.substring(0, 8),
                    end_date_time.substring(8, 14)
                )
            )
        });
    }, [selectedTask]);

    const handleChangeAnnouncementForm = useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            event.persist();

            setAnnouncementForm(prevState => ({
                ...prevState,
                [event.target.name]: event.target.value
            }));
        },
        [setAnnouncementForm]
    );

    const handleChangeDateAnnouncementForm = useCallback(
        (key: keyof Pick<typeof announcementForm, 'start_date_time' | 'end_date_time'>) =>
            (date: Date | null, _event: React.ChangeEvent<HTMLInputElement> | undefined) => {
                if (!date) {
                    return;
                }

                setAnnouncementForm(prevState => ({
                    ...prevState,
                    [key]: date
                }));
            },
        [setAnnouncementForm]
    );
    const handleClickSaveEditAnnouncement = useCallback(async () => {
        try {
            loader.show();

            const { key_id, subject, description, start_date_time, end_date_time } =
                announcementForm;

            //if (description && description.length > LIMIT_DESCRIPTION_CHAR) {
            //    throw {
            //        response: {
            //            data: {
            //                message: `You have exceeded the maximum number of characters allowed for the task description (${LIMIT_DESCRIPTION_CHAR} characters)`
            //            }
            //        }
            //    };
            //}
            if (!subject.trim()) {
                throw {
                    response: {
                        data: {
                            message: 'Subject must not null or empty.'
                        }
                    }
                };
            }

            const [startDateString, startTimeString] = parseDateToString(new Date(start_date_time));
            const [endDateString, endTimeString] = parseDateToString(new Date(end_date_time));

            const formData = new FormData();
            formData.append('key_id', key_id);
            formData.append('subject', subject);
            formData.append('start_date_time', startDateString + startTimeString);
            formData.append('end_date_time', endDateString + endTimeString);
            formData.append('description', description ?? '');
            formData.append('updated_by', extractAccessToken().citizen_id);
            formData.append('updated_on', '');

            const [error, data] = await editTaskAnnouncement(formData);

            if (error) {
                throw error;
            }
            if (!data) {
                return;
            }

            if (data.result) {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Edit announcement success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) {
                    onClose && onClose();
                    window.location.reload();
                }
            }
        } catch (e) {
            console.log(e);

            const result = await Swal.fire({
                title: 'Error!',
                text: (e as any).response.data.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            if (result.isConfirmed) {
                onClose && onClose();
                // window.location.reload();
            }
        } finally {
            loader.hide();
        }
    }, [announcementForm, loader]);

    return {
        announcementForm,
        handleChangeAnnouncementForm,
        handleChangeDateAnnouncementForm,
        handleClickSaveEditAnnouncement
    };
}

export default EditAnnouncementViewModel;
