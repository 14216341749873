import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { ImBlocked } from 'react-icons/im';
import { BiImage } from 'react-icons/bi';

import { Label } from 'common/materials';
import { Option } from 'models/taskGroup/TaskGroup';

import PosterPreview from './PosterPreview';
import PosterModeViewModel from './PosterMode.viewmodel';

type PosterModeHandle = {
    getPosterFormData: () => Promise<FormData | undefined>;
};

type PosterModeViewProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

const editorBackgroundColors = ['red', 'green', 'blue'];

const PosterModeView = forwardRef<PosterModeHandle, PosterModeViewProps>((props, ref) => {
    const {
        selectedBackground,
        backGroundMode,
        selectedMode,
        handleChangeBackgroundMode,
        model,
        setModel,
        getPosterFormData,
        handleChangePosterBackground,
        handleChangePosterBackgroundImage
    } = PosterModeViewModel(props);

    const fileInputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
        return {
            getPosterFormData
        };
    });

    return (
        <React.Fragment>
            <div className="mt-2 flex flex-1 flex-col">
                <Label>Poster Subject</Label>

                <div className="flex items-center gap-2 py-2">
                    <ImBlocked
                        size={32}
                        className={twMerge(
                            'cursor-pointer text-neutral-700',
                            !selectedBackground ? 'rounded-lg border-4 border-blue-400' : ''
                        )}
                        onClick={() => handleChangePosterBackground('')}
                    />
                    {editorBackgroundColors.map((color, index) => (
                        <div
                            key={index}
                            className={twMerge(
                                'h-8 w-8 cursor-pointer rounded-md',
                                selectedBackground === color ? 'border-4 border-blue-400' : ''
                            )}
                            style={{ background: color }}
                            onClick={() => handleChangePosterBackground(color)}
                        />
                    ))}
                    <div className="rounded-lg border-2">
                        <BiImage
                            size={30}
                            className="cursor-pointer text-neutral-700"
                            onClick={() => fileInputRef.current?.click()}
                        />
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            className="hidden"
                            onChange={handleChangePosterBackgroundImage}
                        />
                    </div>
                </div>

                <div className="mt-4">
                    <Label>Poster Preview</Label>

                    {/* <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-4"> */}
                    {/* <div className="grid w-[322px] grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-4"> */}
                    <div className="w-[322px]">
                        <PosterPreview
                            task={{
                                key_id: '0000000000',
                                type: 'task',
                                subject: 'test',
                                description: '',
                                created_on: '',
                                created_by: 'focusone',
                                start_date_time: '9999',
                                end_date_time: '9999',
                                group_id: '',
                                is_active: false,
                                is_release: false,
                                mode: 'poster',
                                updated_by: 'focusone',
                                updated_on: new Date().toString(),
                                attachment: ''
                                // poster_subject: '',
                                // poster_background: ''
                            }}
                            taskType="task"
                            userType="super_admin"
                            model={model}
                            setModel={setModel}
                            posterBackground={selectedBackground}
                        />
                    </div>
                </div>
            </div>

            {/* <div className="mt-2 flex flex-col">
                <Label>Poster Background Option</Label>
                <RadioButtonGroup
                    outline
                    values={backGroundMode}
                    defaultSelectedValue={backGroundMode[0]}
                    onChange={handleChangeBackgroundMode}
                />
            </div>
            {selectedMode === backGroundMode[1] ? (
                <div className="mt-2 flex flex-1 flex-col">
                    <Label>Poster Background Color</Label>
                    <Input />
                </div>
            ) : (
                <div className="mt-2 flex flex-1 flex-col">
                    <Label>Poster Background Image</Label>
                    <Input />
                </div>
            )} */}
        </React.Fragment>
    );
});

export default PosterModeView;
