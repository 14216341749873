import 'react-datepicker/dist/react-datepicker.css';

import { memo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';
import { IoMdSearch } from 'react-icons/io';

import { Input, Label } from 'common/materials';

import ToggleView from './ToggleView';

export type DateRange = {
    dateFrom?: Date;
    dateTo?: Date;
};

type Props = {
    dateRange: DateRange;
    keyword: string;
    viewMode?: 'grid' | 'list';
    className?: string;
    onDateRangeChange?: (dateRange: DateRange) => void;
    onKeywordChange?: (keyword: string) => void;
    onViewModeChange?: (viewMode: 'grid' | 'list') => void;
};

function SearchCriteria(props: Props) {
    const {
        dateRange,
        keyword,
        viewMode = 'grid',
        className,
        onDateRangeChange,
        onKeywordChange,
        onViewModeChange
    } = props;

    const handleKeywordChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => onKeywordChange?.(event.target.value),
        [onKeywordChange]
    );

    const handleDateFromChange = useCallback(
        (date: Date | null) => {
            date && onDateRangeChange?.({ ...dateRange, dateFrom: date });
        },
        [dateRange, onDateRangeChange]
    );

    const handleDateToChange = useCallback(
        (date: Date | null) => date && onDateRangeChange?.({ ...dateRange, dateTo: date }),
        [dateRange, onDateRangeChange]
    );

    return (
        <div className={twMerge('flex flex-wrap gap-x-16', className)}>
            <div className="flex gap-3">
                <div className="flex flex-col">
                    <Label>DATE FROM</Label>
                    <DatePicker
                        data-name="dateFrom"
                        name="date-from"
                        placeholderText="DD/MM/YYYY"
                        selected={dateRange.dateFrom}
                        onChange={handleDateFromChange}
                        dateFormat="dd/MM/yyyy"
                        customInput={<Input />}
                    />
                </div>
                <div className="flex flex-col">
                    <Label>DATE TO</Label>
                    <DatePicker
                        data-name="dateTo"
                        name="date-to"
                        placeholderText="DD/MM/YYYY"
                        selected={dateRange.dateTo}
                        onChange={handleDateToChange}
                        dateFormat="dd/MM/yyyy"
                        customInput={<Input />}
                    />
                </div>
            </div>

            <div className="relative min-w-[250px] flex-1 self-end">
                <IoMdSearch className="absolute left-5 top-1/2 -translate-x-1/2 -translate-y-1/2 text-neutral-300" />

                <Input
                    placeholder="Search keyword"
                    value={keyword}
                    onChange={handleKeywordChange}
                    className="rounded-full pl-8"
                />
            </div>

            <ToggleView
                viewMode={viewMode}
                onClick={onViewModeChange}
                className="self-end"
            />
        </div>
    );
}

export default memo(SearchCriteria);
