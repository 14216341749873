import React from 'react';
import { observer } from 'mobx-react';

import ExpansionApplication from '../../../common/components/ExpansionApplication';
import UserApplicationsViewModel from './UserApplications.viewmodel';

function UserApplications() {
    const { selectedApplication } = UserApplicationsViewModel();
    return (
        <React.Fragment>
            <ExpansionApplication dataSelectedOpject={selectedApplication} />
        </React.Fragment>
    );
}

export default observer(UserApplications);
