import { memo, RefObject } from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    virtualTableContainerRef: RefObject<HTMLDivElement>;
    virtualTableRef: RefObject<HTMLDivElement>;
};

function VirtualTableScrollbar(props: Props) {
    const { virtualTableContainerRef, virtualTableRef, ...divProps } = props;

    return (
        <div
            ref={virtualTableContainerRef}
            {...divProps}
            className="h-[20px] overflow-x-auto overflow-y-hidden"
        >
            <div
                className="h-[20px]"
                ref={virtualTableRef}
            />
        </div>
    );
}

export default memo(VirtualTableScrollbar);
