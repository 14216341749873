import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Icons } from 'assets';
import { GridApplication } from 'common/components';
import { arrGetCompanyApplicationMappingMain } from 'models/company/CompanyApplicationMapping';

type Props = {
    header: string;
    contentData?: arrGetCompanyApplicationMappingMain[];
};

const MATCH_HEADER_COLORS = [
    ['USER ACCEPTANCE TEST', 'bg-[#FECDA6]'],
    ['PRE-PRODUCTION', 'bg-[#F9B572]']
] as const;

function AccordionLayout(props: Props) {
    const { header, contentData } = props;
    const [isExpansion, setIsExpansion] = useState(true);

    const headerBgColor = useMemo(
        () =>
            MATCH_HEADER_COLORS.slice(0).reduce((accumulate, [matchWord, value], _, array) => {
                if (header.includes(matchWord)) {
                    array.splice(1); // Break loop

                    return value;
                }

                return accumulate;
            }, 'bg-[#FFF]'),
        [header]
    );

    return (
        <React.Fragment>
            <div
                className={twMerge(
                    'mb-2 flex justify-between rounded bg-[#FFFFFF] px-[10px] py-[6px]',
                    headerBgColor
                )}
                onClick={() => setIsExpansion(prevState => !prevState)}
            >
                <div className="flex">
                    <div className="cursor-pointer text-[14px] font-bold leading-[21px] text-[#E55C00]">
                        {header}
                    </div>
                </div>
                <div className="flex cursor-pointer items-center justify-center">
                    <img
                        src={Icons.EXPAND_ARROW_ORANGE}
                        className={`${isExpansion && 'rotate-90'}`}
                    />
                </div>
            </div>
            {contentData && isExpansion && (
                <div className="mb-2 w-auto border-b-2 bg-white">
                    {header === 'Registration Form' ? (
                        <div className="flex flex-col gap-4 p-[11px]">
                            {contentData.map(card => (
                                <a
                                    href={card.application_ingress}
                                    className="group inline-flex w-fit items-center gap-2"
                                >
                                    <img
                                        src={card.application_img_url}
                                        className="h-[30px] w-[30px]"
                                    />
                                    <span className="transition-colors group-hover:text-primary-900 group-hover:underline">
                                        {card.application_name}
                                    </span>
                                </a>
                            ))}
                        </div>
                    ) : (
                        <GridApplication contentDataCard={contentData} />
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default AccordionLayout;
