// import '../style/UserDashboardComponentMain.css';

import React from 'react';
import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    TableContainer
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { FaEnvelope, FaFilter } from 'react-icons/fa';

import { Input, Table, Tbody, Td, Th, Thead, Tr } from 'common/materials';
import { parseDateString, parseJwt } from 'modules/common';
import { Task } from 'models/link/Link';

import UserDashboardComponent from './UserDashboardComponent';
import UserDashboardComponentAdmin from './UserDashboardComponentAdmin';
import { BiChevronDown } from 'react-icons/bi';
declare global {
    interface Array<T> {
        searchTasks(searchTerms: string): Array<T>;
    }
}

Array.prototype.searchTasks = function (searchTerms) {
    return this.filter(
        task => task.jobdescription?.includes(searchTerms) || task.ticketNO.includes(searchTerms)
    );
};

interface State {
    email: string;
    fullName: string;
    CheckUser: any;
    isLoadingTasks: boolean;
    hasLoadedTasks: boolean;
    taskList: Task[];
    searchTerms: string;
    dropdownOpen: boolean;
    selectFilterProgress: string;
    inputFilterProgress: string;
    filterTaskList: Task[];
}

class UserDashboardComponentMain extends React.Component<any, State> {
    public filterOperators = ['>=', '<=', '>', '<', '='];

    constructor(props: any) {
        super(props);
        this.state = {
            CheckUser: undefined,
            email: '',
            fullName: '',
            isLoadingTasks: false,
            hasLoadedTasks: false,
            taskList: [],
            searchTerms: '',
            dropdownOpen: false,
            selectFilterProgress: '>=',
            inputFilterProgress: '',
            filterTaskList: []
        };

        this.filterProgress = this.filterProgress.bind(this);
    }

    public componentDidMount() {
        const token = localStorage.getItem('SSO_AUTH');
        let userId = '';

        if (token) {
            let jls: any = JSON.parse(token);
            let accessToken = jls.access_token;
            let jwt = parseJwt(accessToken);
            let extra = JSON.parse(jwt.extra);

            if (extra.first_name) {
                userId = extra.first_name + ' ' + extra.last_name;
            } else if (extra.user_id) {
                userId = extra.user_id;
            } else if (extra.username) {
                userId = extra.username;
            } else if (extra.citizen_id) {
                userId = extra.citizen_id;
            }

            this.setState({ email: extra.email, fullName: userId });
            this.getTasks(extra.email);
            //this.getTasks("krit");
        }

        /*fetch(`/v1/user/company?citizen_id=` + userId, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            },
        })
            .then((response) => {
                return response
            })
            .then((data) => {
                if (!data.ok) {
                    data.json().then(err => {
                    })
                } else {
                    data.json().then(data => {
                        if (data['total_count'] > 0) {
                            this.setState({
                                CheckUser: data['result_list'][0].type
                            })
                        } else {
                            this.setState({
                                CheckUser: "user"
                            })
                        }
                    })
                }
            })*/
    }

    public async getTasks(_email: string) {
        try {
            const email = _email;
            // const email = 'rungrudc@scg.com'; // For development purpose

            // You'll get kicked if you have no email
            if (email === undefined || email === null || email.length === 0) {
                return;
            }

            this.setState(prevState => ({ ...prevState, isLoadingTasks: true }));
            const response = await fetch('/v1/task?' + new URLSearchParams({ email }), {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                }
            });

            if (response.ok) {
                const taskList: Task[] = await response.json();

                this.setState(prevState => ({
                    ...prevState,
                    isLoadingTasks: false,
                    taskList: taskList || [],
                    hasLoadedTasks: taskList.length > 0 ? true : false,
                    filterTaskList: taskList || []
                }));
            } else {
                const error = await response.json();
                throw new Error(error.message);
            }
        } catch (error) {
            Swal.fire({
                title: 'Something went wrong!',
                text: error as any,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                allowOutsideClick: false
            });
        }
    }

    // unused now. use prototype instead (look the top of file)
    public filteredTask(tasks: Task[], searchTerms: string) {
        return tasks.filter(
            task => task.jobdescription.includes(searchTerms) || task.ticketNO.includes(searchTerms)
        );
    }

    public changeState<K extends keyof State>(key: K, value: State[K]) {
        this.setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    public filterProgress() {
        const { taskList, searchTerms, selectFilterProgress, inputFilterProgress } = this.state;

        switch (selectFilterProgress) {
            case '<=': {
                this.setState({
                    filterTaskList: taskList
                        .searchTasks(searchTerms)
                        .filter(
                            task =>
                                parseFloat(task.taskStatusPercent) <=
                                parseFloat(inputFilterProgress)
                        )
                });
                break;
            }
            case '<': {
                this.setState({
                    filterTaskList: taskList
                        .searchTasks(searchTerms)
                        .filter(
                            task =>
                                parseFloat(task.taskStatusPercent) < parseFloat(inputFilterProgress)
                        )
                });
                break;
            }
            case '>': {
                this.setState({
                    filterTaskList: taskList
                        .searchTasks(searchTerms)
                        .filter(
                            task =>
                                parseFloat(task.taskStatusPercent) > parseFloat(inputFilterProgress)
                        )
                });
                break;
            }
            case '=': {
                this.setState({
                    filterTaskList: taskList
                        .searchTasks(searchTerms)
                        .filter(
                            task =>
                                parseFloat(task.taskStatusPercent) ===
                                parseFloat(inputFilterProgress)
                        )
                });
                break;
            }
            default: {
                // >=
                //console.log(selectFilterProgress)
                this.setState({
                    filterTaskList: taskList
                        .searchTasks(searchTerms)
                        .filter(
                            task =>
                                parseFloat(task.taskStatusPercent) >=
                                parseFloat(inputFilterProgress)
                        )
                });
                break;
            }
        }
    }

    public render() {
        const { CheckUser, filterTaskList, searchTerms } = this.state;

        switch (CheckUser) {
            case 'user':
                return <UserDashboardComponentAdmin />;
            case 'admin':
            case 'super_admin':
                return <UserDashboardComponent />;
            default:
                return (
                    <React.Fragment>
                        <div className="flex items-center">
                            <div className="flex-1">
                                <Input
                                    placeholder="Search ..."
                                    value={searchTerms}
                                    className="flex-1"
                                    onChange={event =>
                                        this.setState({ searchTerms: event.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="w-full overflow-auto [&_*]:text-sm">
                            <table className="w-full table-auto">
                                <thead>
                                    <tr className="sticky top-0 whitespace-nowrap border border-zinc-300 bg-inherit bg-zinc-100 [&_th:not(:first-child)]:px-4 [&_th]:border-l [&_th]:border-l-zinc-300 [&_th]:py-2">
                                        <th>Title</th>
                                        <th>Ticket No</th>
                                        <th>Response Person</th>
                                        <th>Activity Status</th>
                                        <th>Work Group</th>
                                        <th>
                                            <div className="flex items-center gap-1">
                                                <span>Progress</span>
                                                <Popover placement="bottom-end">
                                                    <PopoverTrigger>
                                                        <IconButton
                                                            size="xs"
                                                            aria-label="filter-icon"
                                                            variant="unstyled"
                                                            className="group hover:bg-primary-900"
                                                            icon={
                                                                <FaFilter
                                                                    size={16}
                                                                    className="text-primary-900 transition-colors group-hover:text-white"
                                                                />
                                                            }
                                                        />
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-fit">
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverBody className="flex gap-2">
                                                            <Menu>
                                                                <MenuButton
                                                                    as={Button}
                                                                    size="sm"
                                                                    rightIcon={<BiChevronDown />}
                                                                >
                                                                    {
                                                                        this.state
                                                                            .selectFilterProgress
                                                                    }
                                                                </MenuButton>
                                                                <MenuList>
                                                                    {this.filterOperators.map(
                                                                        operator => (
                                                                            <MenuItem
                                                                                key={operator}
                                                                                onClick={() =>
                                                                                    this.changeState(
                                                                                        'selectFilterProgress',
                                                                                        operator
                                                                                    )
                                                                                }
                                                                            >
                                                                                {operator}
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </MenuList>
                                                            </Menu>
                                                            <Input
                                                                className="w-14"
                                                                onChange={event =>
                                                                    this.changeState(
                                                                        'inputFilterProgress',
                                                                        event.target.value
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                size="sm"
                                                                onClick={this.filterProgress}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                        </th>
                                        <th>Issue Category</th>
                                        <th>Issue Type</th>
                                        <th>Issue Area</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterTaskList.searchTasks(searchTerms).map((task, index) => {
                                        const prevCreateDate = task[index - 1]?.createD_ON || '';
                                        const currCreatedDate = task.createD_ON!;
                                        const shouldRenderGroupingDate =
                                            prevCreateDate !== currCreatedDate;

                                        return (
                                            <React.Fragment key={index}>
                                                {shouldRenderGroupingDate && (
                                                    <tr>
                                                        <td colSpan={99}>
                                                            <div className="flex items-center rounded-md border border-primary-900 px-4 py-1">
                                                                <p className="flex-1 font-semibold text-neutral-900">
                                                                    {parseDateString(
                                                                        task.createD_ON
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr className="border border-zinc-300 [&_*]:whitespace-nowrap [&_td]:px-4 [&_td]:py-1">
                                                    <td>
                                                        <div className="flex items-center gap-2">
                                                            <FaEnvelope size={16} />
                                                            <span>{task.show_radius}</span>
                                                        </div>
                                                    </td>
                                                    <td>{task.ticketNO}</td>
                                                    <td>{task.leaderEN}</td>
                                                    <td>{task.activityOverviewStatus}</td>
                                                    <td>{task.projectcategory}</td>
                                                    <td className="text-right">
                                                        {task.taskStatusPercent}
                                                    </td>
                                                    {task.additionalField.length !== 0
                                                        ? ['category', 'type', 'area'].map(
                                                              (type, index) => {
                                                                  const additionalField =
                                                                      task.additionalField.find(
                                                                          field =>
                                                                              field.fieldName
                                                                                  .toLowerCase()
                                                                                  .includes(type)
                                                                      );

                                                                  return (
                                                                      <td key={index}>
                                                                          {
                                                                              additionalField?.optionsName
                                                                          }
                                                                      </td>
                                                                  );
                                                              }
                                                          )
                                                        : Array.from({ length: 3 }).map(
                                                              (_, index) => <td key={index}></td>
                                                          )}
                                                </tr>
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                );
        }
    }
}

export default UserDashboardComponentMain;
