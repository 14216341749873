import axios from 'axios'

import {
    ResponseGetLIFF,
    RequestAddLineId,
    ResponseAddLineId
} from '../schemas/LineConnect.schema'

export async function getLIFF() {
    return axios.get<ResponseGetLIFF>('/v1/liff')
}

export async function putLineId(params: RequestAddLineId) {
    return axios.post<ResponseAddLineId>('/v1/conf/line',
        params,
        {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH') }
        }
    );
}