import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError } from '../../../models';
import { getFocusOneHelpTokenFromAPI } from '../datasources/focusOneHelp.datasource';

export async function getFocusOneHelpToken(
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await getFocusOneHelpTokenFromAPI()).data;

        return [null!, data.token];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}