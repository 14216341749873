import React from 'react';
import { twMerge } from 'tailwind-merge';

function FocusoneTextLogo(
    props: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLSpanElement>,
        HTMLSpanElement
    >
) {
    const { className, ...spanProps } = props;

    return (
        <span
            className={twMerge('text-2xl font-bold', className)}
            {...spanProps}
        >
            focus
            <span className="text-[#ffa500] font-extrabold">O</span>
            ne
        </span>
    );
}

export default FocusoneTextLogo;
