import { makeAutoObservable, runInAction } from 'mobx';
import { getApplicationsLinkedToTheCompany } from '../../data/company/repositories/companyApplicationMappingRepository';

import {
    arrGetCompanyApplicationMapping,
    arrGetCompanyApplicationMappingMain
} from '../../models/company/CompanyApplicationMapping';
import RootStore from '../Root.store';

class UserApplicationStore {
    private _userApplications: arrGetCompanyApplicationMapping[] = [];
    private _monitorApplication: arrGetCompanyApplicationMappingMain | null = null;

    public get userApplications() {
        return this._userApplications;
    }

    public get monitorApplication() {
        return this._monitorApplication;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchUserApplications(citizenId: string) {
        const [error, responseUserApplicationList] = await getApplicationsLinkedToTheCompany({
            citizen_id: citizenId
        });

        if (error) {
            throw error;
        }

        const selectedApplication = responseUserApplicationList.result_list.find(
            applicationData => applicationData.group_id === 'A8006'
        );

        this._replaceSpaceToNewLine(responseUserApplicationList.result_list);

        runInAction(() => {
            this._monitorApplication =
                selectedApplication?.application_list.find(
                    value => value.application_id === 'A0028007002'
                ) || null;
            this._userApplications = responseUserApplicationList.result_list;
        });
    }

    private _replaceSpaceToNewLine(appGroups: arrGetCompanyApplicationMapping[]) {
        const appGroupsLength = appGroups.length;
        for (let i = 0; i < appGroupsLength; i++) {
            appGroups[i].application_list = appGroups[i].application_list.map(app => ({
                ...app,
                application_name: app.application_name.replace(' ', '\n')
            }));
        }
    }
}

export default UserApplicationStore;
