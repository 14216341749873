import React from 'react';

import { nameof } from '../../../common/helpers';
import { UserInformationResponse } from '../../../models/citizen/Citizen';

import ChangeDetectionInput from './ChangeDetectionInput';

type Props = {
    userInformation: UserInformationResponse;
    copyUserInformation: UserInformationResponse;
    handleChangeCopyUserInformation: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function WorkingPosition(props: Props) {
    const { userInformation, copyUserInformation, handleChangeCopyUserInformation } = props;

    const { company, department, division, position, employment_id } = copyUserInformation;
    const comparers = [userInformation, copyUserInformation] as [
        UserInformationResponse,
        UserInformationResponse
    ];

    return (
        <React.Fragment>
            <p className="text-lg font-medium">Working Position Information</p>
            <hr />

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput
                        comparers={comparers}
                        compareKey="company"
                        label="Company"
                        name={nameof('company', userInformation)}
                        placeholder="Company"
                        value={company ?? ''}
                        onChange={handleChangeCopyUserInformation}
                    />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput
                        comparers={comparers}
                        compareKey="department"
                        label="Department"
                        name={nameof('department', userInformation)}
                        placeholder="Department"
                        value={department ?? ''}
                        onChange={handleChangeCopyUserInformation}
                    />
                </div>
            </div>

            <div className="flex space-x-4">
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput
                        comparers={comparers}
                        compareKey="division"
                        label="Division"
                        name={nameof('division', userInformation)}
                        placeholder="Division"
                        value={division ?? ''}
                        onChange={handleChangeCopyUserInformation}
                    />
                </div>
                <div className="flex flex-1 flex-col">
                    <ChangeDetectionInput
                        comparers={comparers}
                        compareKey="position"
                        label="Position"
                        name={nameof('position', userInformation)}
                        placeholder="Position"
                        value={position ?? ''}
                        onChange={handleChangeCopyUserInformation}
                    />
                </div>
            </div>

            <div className="flex">
                <div className="flex w-1/2 flex-col pr-2">
                    <ChangeDetectionInput
                        comparers={comparers}
                        compareKey="employment_id"
                        label="Employment ID"
                        name={nameof('employment_id', userInformation)}
                        placeholder="Employment ID"
                        value={employment_id ?? ''}
                        onChange={handleChangeCopyUserInformation}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default WorkingPosition;
