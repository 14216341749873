import axios from 'axios';

import { SandboxParticipateRequest, SandboxRegisterRequest } from '../schemas';

export async function createSandboxUser(params: SandboxRegisterRequest) {
    return axios.post<string>('/v1/sandbox', params, {
        headers: {
            Authorization:
                'Bearer ' +
                //JSON.parse(localStorage.getItem('SSO_AUTH') ?? '').access_token
                localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function participateCompanySandbox(params: SandboxParticipateRequest) {
    return axios.post<string>('/v1/user/company/sandbox', params, {
        headers: {
            Authorization:
                'Bearer ' +
                //JSON.parse(localStorage.getItem('SSO_AUTH') ?? '').access_token
                localStorage.getItem('SSO_AUTH')
        }
    })
}
