import { GroupBase, Props } from 'react-select';
import ReactSelectCreatable from 'react-select/creatable';
import { twMerge } from 'tailwind-merge';

function Creatable<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
    const { className, ...reactSelectCreatableProps } = props;

    return (
        <ReactSelectCreatable
            {...reactSelectCreatableProps}
            classNames={{
                control: props =>
                    twMerge(
                        'transition-[outline-color] duration-200',
                        props.isFocused && 'outline outline-1 outline-orange-500 border-neutral-300'
                    )
            }}
            styles={{
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingTop: 0,
                    paddingBottom: 0
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '14px',
                    minHeight: '32px',
                    boxShadow: 'none',
                    outline: 0
                }),
                placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: '14px'
                }),
                dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    paddingTop: 0,
                    paddingBottom: 0
                })
            }}
            components={{ DropdownIndicator: null }}
            className={twMerge('w-full', className)}
        />
    );
}

export default Creatable;
