import axios from 'axios';
import { ResponseList } from 'models';

import {
    ChangeEmployeePasswordRequest,
    Employee,
    EmployeeBranch,
    EmployeePrefix,
    GetEmployeeBranchParams,
    GetEmployeePrefixParams,
    GetEmployeeRequestParams,
    SaveEmployeeRequest
} from 'models/user/Employee';

export function requestGetEmployee(params: GetEmployeeRequestParams) {
    return axios.get<ResponseList<Employee>>('/v1/f1/employee', { params });
}

export function requestGetEmployeePrefix(params: GetEmployeePrefixParams) {
    return axios.get<ResponseList<EmployeePrefix>>('/v1/f1/employee-prefix', { params });
}

export function requestGetEmployeeBranch(params: GetEmployeeBranchParams) {
    return axios.get<ResponseList<EmployeeBranch>>('/v1/f1/employee-branch', { params });
}

export function requestSaveEmployee(employee: SaveEmployeeRequest) {
    return axios.post('/v1/f1/employee', employee);
}

export function requestCanChangePassword() {
    return axios.get('/v1/f1/employee-can-change-password');
}

export function requestChangePassword(request: ChangeEmployeePasswordRequest) {
    return axios.post('/1/f1/employee-change-password', request);
}
