import React, { PropsWithChildren } from 'react';

import { TopNavigation, SideNavigation } from '.';

function Dashboard({ children }: PropsWithChildren<{}>) {
    return (
        <React.Fragment>
            <TopNavigation />
            <main className="flex h-[calc(100vh-49px)]">
                <SideNavigation />

                {/* Content */}
                <div className="relative flex flex-1 flex-col overflow-y-auto bg-white px-4 py-3">
                    {children}
                </div>
            </main>
        </React.Fragment>
    );
}

export default Dashboard;
