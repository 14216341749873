import React from 'react';

type Props = React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableSectionElement>,
    HTMLTableSectionElement
>;

function Tbody(props: Props) {
    const { children, ...tbodyProps } = props;

    return <tbody {...tbodyProps}>{children}</tbody>;
}

export default Tbody;
