import React, { useState } from 'react';
import { BiMessageError } from 'react-icons/bi';

import { useDisclosure } from '../../../../common/hooks';
import {
    Button,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '../../../../common/materials';

import { Log } from 'models/log/Log';

type Props = {
    logs: Log[] | undefined;
};

function LogsTable({ logs }: Props) {
    const { isOpen: showMessageModal, onToggle: toggleMessageModal } = useDisclosure();
    const [selectedLogError, setSelectedLogError] = useState<string | undefined>('');

    return (
        <React.Fragment>
            <Table>
                <Thead>
                    <Tr>
                        <Th>No.</Th>
                        <Th>User</Th>
                        <Th>SID</Th>
                        <Th>Action</Th>
                        <Th>With</Th>
                        <Th>Create No</Th>
                        <Th>Message</Th>
                        <Th>Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {logs &&
                        logs.map((log, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>{log.user}</Td>
                                    <Td>{log.sid}</Td>
                                    <Td>{log.action}</Td>
                                    <Td>{log.with}</Td>
                                    <Td>{log.created_on}</Td>
                                    <Td>
                                        <button
                                            className="flex"
                                            onClick={() => {
                                                toggleMessageModal();
                                                setSelectedLogError(log.message);
                                            }}
                                        >
                                            <BiMessageError
                                                key={index}
                                                className="text-2xl text-primary-900"
                                            />
                                        </button>
                                    </Td>
                                    <Td>{log.status}</Td>
                                </Tr>
                            );
                        })}
                </Tbody>
            </Table>
            <Modal
                closeOnClickOverlay
                isOpen={showMessageModal}
                className="min-w-[80vw!important]"
                onClose={toggleMessageModal}
            >
                <ModalHeader>
                    <span className="px-4 font-medium text-gray-500">Message Error</span>
                </ModalHeader>
                <ModalBody>
                    <div className="border border-gray-300 bg-gray-100 p-4">
                        <div className="flex flex-col gap-2">
                            <p className="px-4 font-medium">Message Details</p>
                            <div className="flex flex-col border-b-2 border-gray-300 bg-white p-2 text-gray-400">
                                Error: {selectedLogError}
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="px-4">
                    <Button
                        size="md"
                        onClick={toggleMessageModal}
                        className="bg-primary-900 text-white"
                    >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default LogsTable;
