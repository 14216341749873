import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useStore } from 'common/hooks';
import { Icons } from 'assets';

import Accordion from './Accordion/Accordion';

type Props = {
    onClickApplication?: () => void;
};

function ApplicationAccordions(props: Props) {
    const { onClickApplication } = props;

    const { subDashboardStore } = useStore();
    const { groupedApplications } = subDashboardStore;

    const [showSandbox, setShowSandbox] = useState(false);

    useEffect(() => {
        fetchShowSandbox();
    }, []);

    const fetchShowSandbox = async () => {
        const data = (await axios.get('/v1/show-sandbox')).data;
        setShowSandbox(data.show_sandbox === 'true');
    };

    if (!groupedApplications) {
        return null;
    }

    return (
        <div>
            {groupedApplications.map((groupApplication, index) => {
                if (groupApplication.groupItems.length > 0) {
                    return (
                        <Accordion
                            key={index}
                            indexKey={index}
                            header={groupApplication.groupName}
                            dashboardDataItem={groupApplication.groupItems}
                            onClickApplication={onClickApplication}
                        />
                    );
                } else if (
                    groupApplication.groupItems.length === 0 &&
                    groupApplication.groupName.includes('SANDBOX') &&
                    showSandbox
                ) {
                    return (
                        <div
                            key={index}
                            className="flex items-center"
                        >
                            <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                                <Link
                                    to={'/sandbox-register'}
                                    className="text-[14px] font-bold leading-[21px] text-black"
                                >
                                    <div className="ml-[10px] flex">
                                        <img src={Icons.VECTOR_GRAY} />
                                        <span className="ml-[16px]">
                                            {groupApplication.groupName}
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    );
                }
            })}

            {groupedApplications.length === 0 && showSandbox && (
                <div className="flex items-center">
                    <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                        <Link
                            to="/sandbox-register"
                            className="text-[14px] font-bold leading-[21px] text-black"
                        >
                            <div className="ml-[10px] flex">
                                <img src={Icons.VECTOR_GRAY} />
                                <span className="ml-[16px]">F1 ERP WEB SANDBOX</span>
                            </div>
                        </Link>
                    </div>
                </div>
            )}

            <div className="flex items-center">
                <div className="mt-[2px] flex flex-1 flex-row rounded-[4px] bg-[#FFFFFF] p-[10px]">
                    {showSandbox ? (
                        <Link
                            to={'/client-system-program'}
                            className="text-[14px] font-bold leading-[21px] text-black"
                        >
                            <div className="ml-[10px] flex">
                                <img src={Icons.VECTOR_GRAY} />
                                <span className="ml-[16px]">DOWNLOAD FGUI FOR CLIENT SYSTEM</span>
                            </div>
                        </Link>
                    ) : (
                        <Link
                            to={'/scg-client-system-program'}
                            className="text-[14px] font-bold leading-[21px] text-black"
                        >
                            <div className="ml-[10px] flex">
                                <img src={Icons.VECTOR_GRAY} />
                                <span className="ml-[16px]">DOWNLOAD FGUI FOR CLIENT SYSTEM</span>
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ApplicationAccordions;
