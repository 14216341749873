import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import {
    getLIFFData,
    updateLineId
} from '../../data/LineConnect/repositories/LineConnect.repository';
import { parseJwt } from '../../modules/common';

const liff = window.liff;

function LineConnectViewModel() {
    const [fetching, setFetching] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [error, setError] = useState('');

    // User information
    const [username, setUserame] = useState<string>();
    const [name, setName] = useState<string>();
    const [userLineID, setUserLineID] = useState<string>();
    const [pictureUrl, setPictureUrl] = useState<string>();

    const updateUserLineId = async () => {
        try {
            setError('');
            setFetching(true);

            await updateLineId({ emp_id: username!, line_id: userLineID! });

            setIsConfirmed(true);
        } catch (error) {
            if (error instanceof AxiosError) {
                setError(error.response?.data.message ?? '');
            }
        } finally {
            setFetching(false);
        }
    };

    const closeTab = () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    };

    useEffect(() => {
        const getUsername = () => {
            var ls: string | null = localStorage.getItem('SSO_AUTH');
            if (ls) {
                var jls: any = JSON.parse(ls);
                if (jls) {
                    var access_token = parseJwt(jls.access_token);
                    var extra = JSON.parse(access_token.extra);

                    if (extra.citizen_id) {
                        setUserame(extra.citizen_id);
                    } else if (extra.username) {
                        setUserame(extra.username);
                    }
                }
            }
        };

        const fetchData = async (liffId: string) => {
            try {
                await liff.init({ liffId: `${liffId}` });
                if (liff.isLoggedIn()) {
                    const profile = await liff.getProfile();
                    setName(profile.displayName);
                    setUserLineID(profile.userId);
                    setPictureUrl(profile.pictureUrl);
                } else {
                    liff.login();
                }
            } catch (err) {
                console.log(err);

                return;
            } finally {
            }
        };

        const fetchLIFFData = async () => {
            try {
                const [, response] = await getLIFFData();

                await fetchData(response.LIFF_ID);
            } catch (err) {
                console.log(err);

                return;
            } finally {
            }
        };

        getUsername();
        fetchLIFFData();
    }, []);

    return {
        fetching,
        isConfirmed,
        error,
        username,
        name,
        userLineID,
        pictureUrl,
        updateUserLineId,
        closeTab
    };
}

export default LineConnectViewModel;
