import React, { useRef, useState } from 'react';

import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    RadioButtonGroup
} from '../../common/materials';
import { Option } from '../../models/taskGroup/TaskGroup';

// import NewTaskView, { NewTaskHandle } from './NewTask.view';
import NewTaskViewModel from './NewTask.viewmodel';
import PosterModeView from './components/PosterMode/PosterMode.view';
import NormalModeView from './components/NormalMode/NormalMode.view';

type Props = {
    isOpen?: boolean;
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

function NewTaskModal(props: Props) {
    const { isOpen, onClose, onRefresh, taskGroupList } = props;

    const { normalRef, posterRef, mode, selectedMode, handleChangeMode, handleClickAddTask } =
        NewTaskViewModel(props);

    return (
        <Modal
            noPortal
            fullscreen
            // closeOnClickOverlay
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalHeader>New Information Update</ModalHeader>
            <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                {/* <NewTaskView
                    onClose={onClose}
                    onRefresh={onRefresh}
                    ref={ref}
                    taskGroupList={taskGroupList}
                /> */}
                <React.Fragment>
                    <div className="mt-2">
                        <NormalModeView
                            ref={normalRef}
                            onClose={onClose}
                            onRefresh={onRefresh}
                            taskGroupList={taskGroupList}
                        />
                    </div>

                    <div className="mt-2 flex flex-col">
                        <Label>Thumbnail Mode</Label>
                        <RadioButtonGroup
                            outline
                            values={mode}
                            defaultSelectedValue={mode[0]}
                            onChange={handleChangeMode}
                        />
                    </div>
                    {selectedMode.toLowerCase() === mode[1].toLowerCase() ? (
                        <div className="mb-6 mt-6">
                            <PosterModeView
                                ref={posterRef}
                                onClose={onClose}
                                onRefresh={onRefresh}
                                taskGroupList={taskGroupList}
                            />
                        </div>
                    ) : undefined}
                </React.Fragment>
            </ModalBody>
            <ModalFooter className="mt-auto">
                <Button
                    outline
                    onClick={onClose}
                    className="text-neutral-400"
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleClickAddTask}
                    // onClick={() => normalRef.current?.handleClickAddTask()}
                    className="bg-[#E55C00] text-white"
                >
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default NewTaskModal;
