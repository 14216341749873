import React, { useEffect, useRef, useState } from 'react';
import { useReadLocalStorage } from 'usehooks-ts';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalProps
} from '@chakra-ui/react';

import { Step1, Step2, Step3, Step4 } from '../LineOnboarding';

function LineConnectOnboardModal(props: Omit<ModalProps, 'children'>) {
    const { onClose } = props;

    const lineConnectStatus = useReadLocalStorage('line-connect-status');

    const [step, setStep] = useState(1);

    useEffect(() => {
        switch (lineConnectStatus) {
            default:
                return;
            case 'pending':
                setStep(3);
                break;
            case 'done':
                setStep(4);
                break;
        }
    }, [lineConnectStatus]);

    const next = () => {
        if (step === 4) {
            onClose();
            setTimeout(() => setStep(1), 150);
        }

        if (step < 4) {
            setStep(step + 1);
        }
    };

    const previous = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    const stepComponents = useRef(
        new Map<number, () => JSX.Element>([
            [1, Step1],
            [2, Step2],
            [3, Step3],
            [4, Step4]
        ])
    ).current;
    const SelectedStep = stepComponents.get(step) ?? (() => null);

    return (
        <Modal
            {...props}
            size={props.size ?? 'sm'}
        >
            <ModalOverlay />
            <ModalContent className="h-[585px] overflow-hidden rounded-[32px]">
                <ModalHeader className="px-10 text-center text-sm">
                    เพิ่ม FocusOne เป็นเพื่อนเพื่อรับการแจ้งเตือนที่สะดวกและรวดเร็วผ่าน Line Chat
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody className="flex flex-col py-4">
                    <SelectedStep />
                </ModalBody>
                <ModalFooter className="p-0">
                    <Button
                        size="lg"
                        variant="unstyled"
                        className="bg-line-green-600 m-0 flex-1 rounded-none border-0 px-4 py-2 text-white"
                        onClick={previous}
                    >
                        ย้อนกลับ
                    </Button>
                    <Button
                        size="lg"
                        variant="unstyled"
                        className="bg-line-green-400 m-0 flex-1 rounded-none border-0 px-4 py-2 text-white"
                        onClick={next}
                    >
                        {step < 4 ? 'ถัดไป' : 'ปิด'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default LineConnectOnboardModal;
