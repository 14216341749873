export function getCurrentDateTime() {
    const dateTimeNow = new Date();

    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Asia/Bangkok',
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    const dateTimeFormat = dateTimeNow.toLocaleString('en-US', options).split(', ');

    let date = dateTimeFormat[0];
    date = date.replace('/', '').replace('/', '');
    date = date.substring(4, 8) + date.substring(0, 2) + date.substring(2, 4); // yyyyMMdd

    const hh = dateTimeFormat[1].substring(0, 2);
    const mm = dateTimeFormat[1].substring(3, 5);

    const time = (hh === '24' ? '00' : hh) + mm + '00'; // set seconds = 00

    return date + time;
}

export function parseDateString(dateString: string) {
    if (dateString.length === 0) {
        return '';
    }

    const date = dateString.substring(6, 8);
    const month = dateString.substring(4, 6);
    const year = dateString.substring(0, 4);

    return `${date}/${month}/${year}`;
}

export function parseTimeString(timeString: string) {
    if (timeString.length === 0) return '';

    const hh = timeString.substring(0, 2);
    const mm = timeString.substring(2, 4);
    const ss = timeString.substring(4, 6);

    return `${hh}:${mm}:${ss}`;
}

export function parseDateToString(date: Date): [string, string] {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedTime = `${hours}${minutes}${seconds}`;

    return [formattedDate, formattedTime];
}

export function formatDateTimeString(date?: string, time?: string) {
    if (!date && !time) {
        return '-';
    }

    return `${parseDateString(date ?? '')} ${parseTimeString(time ?? '')}`;
}

export function parseDateTimeStringToISO(date: string, time: string) {
    let year, month, day;
    if (date.includes('/')) {
        const [d, m, y] = date.split('/').map(Number);
        year = y.toString().padStart(4, '0');
        month = m.toString().padStart(2, '0');
        day = d.toString().padStart(2, '0');
    } else if (date.length === 8) {
        year = date.slice(0, 4);
        month = date.slice(4, 6);
        day = date.slice(6, 8);
    } else {
        throw new Error('Invalid date format');
    }

    let hours, minutes, seconds;
    if (time.includes(':')) {
        const [h, m, s] = time.split(':').map(Number);
        hours = h.toString().padStart(2, '0');
        minutes = m.toString().padStart(2, '0');
        seconds = s.toString().padStart(2, '0');
    } else if (time.length === 6) {
        hours = time.slice(0, 2);
        minutes = time.slice(2, 4);
        seconds = time.slice(4, 6);
    } else {
        throw new Error('Invalid time format');
    }

    const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return isoDateString;
}

export function nameof<T>(key: keyof T, _instance?: T) {
    return key;
}

export function diff<T>(a: T, b: T) {
    switch (a as unknown as string | number | boolean | bigint) {
        case '':
        case undefined:
        case null: {
            if (b && a !== b) {
                return 'ADDED';
            }

            break;
        }
        default: {
            if (!b) {
                return 'REMOVED';
            } else if (a !== b) {
                return 'MODIFIED';
            }
        }
    }

    return '';
}

export function isEmailValid(email: string) {
    return email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}
