import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import Swal from 'sweetalert2';
import axios from 'axios';

import { useLoader } from '../../../../common/hooks';
import { Option } from '../../../../models/taskGroup/TaskGroup';
import { RemarkFileList } from '../../../../models/taskStore/Task';
import { extractAccessToken } from '../../../../common/helpers';
import { createTask } from '../../../../data/taskStore/repositories/taskRepository';

type TaskInfoUpdate = {
    subject: string;
    groupId: string;
    description: string;
    createdBy: string;
    // startDate: Date;
    // endDate: Date;
};

type NormalModeViewModelProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    taskGroupList: Option[];
};

function NormalModeViewModel(props: NormalModeViewModelProps) {
    const { onClose, onRefresh, taskGroupList } = props;

    const loader = useLoader();
    const accessToken = extractAccessToken();

    const [filesUpload, setFilesUpload] = useState<File[]>([]);
    const [profileUpload, setProfileUpload] = useState<File>();

    const fileInputRef = useRef<HTMLInputElement>(null);
    const profileInputRef = useRef<HTMLInputElement>(null);

    const [task, setTask] = useState<TaskInfoUpdate>({
        subject: '',
        groupId: '',
        description: '',
        createdBy: accessToken.first_name + ' ' + accessToken.last_name
        // startDate: new Date(),
        // endDate: new Date()
    });

    const [selectedTaskGroup, setSelectedTaskGroup] = useState<SingleValue<Option>>();

    const [model, setModel] = useState<string>('');

    //const LIMIT_DETAIL_CHAR = 3000;

    useEffect(() => {
        if (!taskGroupList) {
            return;
        }

        setSelectedTaskGroup(taskGroupList.find(item => item.value === '0000'));
    }, [taskGroupList]);

    const handleChangeProfile = (files: FileList | null) => {
        if (!files || files.length === 0) {
            return;
        }

        setProfileUpload(files[0]);
    };

    const handleChangeTask = <K extends keyof typeof task>(key: K, value: (typeof task)[K]) => {
        setTask(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleChangeFile = (files: FileList | null) => {
        if (!files) {
            return;
        }

        setFilesUpload(prevState => [...prevState, ...Array.from(files)]);
    };

    const handleDeleteFile = (index: number) => {
        if (index < 0) {
            return;
        }

        const files = [...filesUpload];
        files.splice(index, 1);

        setFilesUpload(files);
    };

    const addTaskValidation = (task: TaskInfoUpdate): [boolean, string] => {
        const { subject, createdBy } = task;

        if (!subject.trim()) {
            return [false, 'subject is null or empty.'];
        }
        if (!createdBy.trim()) {
            return [false, 'createdBy is null or empty.'];
        }

        return [true, ''];
    };

    // ? app-pool-task-store
    // const handleClickAddTask = async () => {
    //     try {
    //         loader.show();

    //         const [valid, message] = addTaskValidation(task);

    //         if (!valid) {
    //             throw new Error(message);
    //         }

    //         const { subject, description, createdBy } = task;

    //         const fileList: RemarkFileList[] = [];

    //         const formData = new FormData();
    //         formData.append('type', 'task');
    //         formData.append('subject', subject);
    //         formData.append('start_date_time', '999999999999');
    //         formData.append('end_date_time', '999999999999');
    //         // formData.append('description', description); // ? old editor
    //         formData.append('attachment', '');
    //         formData.append('is_active', 'true');
    //         formData.append('is_release', 'false');
    //         formData.append('group_id', selectedTaskGroup?.value ?? '0000');
    //         formData.append('created_by', createdBy);
    //         formData.append('created_on', '');
    //         formData.append('updated_by', '');
    //         formData.append('updated_on', '');

    //         if (profileUpload) {
    //             formData.append(`profile`, profileUpload, 'profile-pic-' + profileUpload.name);
    //         }

    //         filesUpload.forEach((file, index) => {
    //             formData.append(`file-${index + 1}`, file, file.name);
    //             fileList.push({ file_name: file.name, url: '' });
    //         });

    //         // Attach file inside editor if exists
    //         const editorContentDOM = document.createElement('div');
    //         editorContentDOM.innerHTML = model;

    //         const attachmentNodes = editorContentDOM.querySelectorAll('img, video') as NodeListOf<
    //             HTMLImageElement | HTMLVideoElement
    //         >;
    //         await Promise.all(
    //             Array.from(attachmentNodes).map(async (node, index) => {
    //                 const url = node.getAttribute('src')!;

    //                 // if already exists on s3
    //                 if (url.includes('s3')) {
    //                     return;
    //                 }

    //                 const fileName = 'editor-file-' + (index + 1);
    //                 const file = await axios
    //                     .get<Blob>(url, { responseType: 'blob' })
    //                     .then(response => new File([response.data], fileName));

    //                 node.src = fileName;

    //                 URL.revokeObjectURL(url);
    //                 formData.append(file.name, file);
    //             })
    //         );

    //         const innerHtml = editorContentDOM.innerHTML;
    //         formData.append('description', innerHtml);

    //         const [error, data] = await createTask(formData);

    //         if (error) {
    //             throw error;
    //         }

    //         if (!data) {
    //             return;
    //         }

    //         if (data.result) {
    //             await Swal.fire({
    //                 title: 'Success!',
    //                 text: `Create task success`,
    //                 icon: 'success',
    //                 showCancelButton: false,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'Ok',
    //                 allowOutsideClick: false
    //             });
    //         }
    //     } catch (error) {
    //         await Swal.fire({
    //             title: 'Error!',
    //             text: (error as any).message,
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#3085d6',
    //             confirmButtonText: 'Yes',
    //             allowOutsideClick: false
    //         });
    //     } finally {
    //         loader.hide();

    //         onClose && onClose();
    //         onRefresh && onRefresh();
    //     }
    // };

    // ? pass FormData to new task ref
    const getNormalFormData = async () => {
        try {
            const [valid, message] = addTaskValidation(task);

            if (!valid) {
                throw new Error(message);
            }

            const { subject, description, createdBy } = task;

            const fileList: RemarkFileList[] = [];

            const formData = new FormData();
            formData.append('type', 'task');
            formData.append('subject', subject);
            formData.append('start_date_time', '999999999999');
            formData.append('end_date_time', '999999999999');
            // formData.append('description', description); // ? old editor
            formData.append('attachment', '');
            formData.append('is_active', 'true');
            formData.append('is_release', 'false');
            formData.append('group_id', selectedTaskGroup?.value ?? '0000');
            formData.append('created_by', createdBy);
            formData.append('created_on', '');
            formData.append('updated_by', '');
            formData.append('updated_on', '');

            if (profileUpload) {
                formData.append(`profile`, profileUpload, 'profile-pic-' + profileUpload.name);
            }

            filesUpload.forEach((file, index) => {
                formData.append(`file-${index + 1}`, file, file.name);
                fileList.push({ file_name: file.name, url: '' });
            });

            // Attach file inside editor if exists
            const editorContentDOM = document.createElement('div');
            editorContentDOM.innerHTML = model;

            const attachmentNodes = editorContentDOM.querySelectorAll('img, video') as NodeListOf<
                HTMLImageElement | HTMLVideoElement
            >;
            await Promise.all(
                Array.from(attachmentNodes).map(async (node, index) => {
                    const url = node.getAttribute('src')!;

                    // if already exists on s3
                    if (url.includes('s3')) {
                        return;
                    }

                    const fileName = 'editor-file-' + (index + 1);
                    const file = await axios
                        .get<Blob>(url, { responseType: 'blob' })
                        .then(response => new File([response.data], fileName));

                    node.src = fileName;

                    URL.revokeObjectURL(url);
                    formData.append(file.name, file);
                })
            );

            const innerHtml = editorContentDOM.innerHTML;
            formData.append('description', innerHtml);

            return formData;
        } catch (error) {
            await Swal.fire({
                title: 'Error!',
                text: (error as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            return;
        }
    };

    // const LIMIT_DETAIL_CHAR = 3000;
    //
    // ? old editor
    // const handleUploadImage = (file: File): Promise<{ data: { link: FileReader['result'] } }> => {
    //     return new Promise(resolve => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve({ data: { link: reader.result } });
    //     });
    // };

    // ? old editor
    // const handleEditorStateChange = (editorState: EditorState) => {
    //     const htmlContent: string = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    //     const stringContent: string = convertToRaw(editorState.getCurrentContent())
    //         .blocks.map(block => block.text)
    //         .join('\n');
    //     // console.log("html content =", htmlContent);
    //     // console.log('string content', convertToRaw(editorState.getCurrentContent()).blocks.map(b => b.text).join('\n'));

    //     setEditorState(editorState);

    //     handleChangeTask('detail', htmlContent);
    //     // handleChangeTask('detail', stringContent);
    // };

    // ? link-task
    // const handleClickAddTask = async () => {
    //     try {
    //         loader.show();

    //         if (task.detail.length > LIMIT_DETAIL_CHAR) {
    //             throw new Error(
    //                 `You have exceeded the maximum number of characters allowed for the task description (${LIMIT_DETAIL_CHAR} characters)`
    //             );
    //         }

    //         const [startDateString, startTimeString] = parseDateToString(task.startDate);
    //         const [endDateString, endTimeString] = parseDateToString(task.endDate);
    //         const fileList: RemarkFileList[] = [];

    //         const formData = new FormData();
    //         formData.append('subject', task.subject);
    //         formData.append('detail', task.detail);
    //         formData.append('start_date', startDateString);
    //         formData.append('end_date', endDateString);
    //         formData.append('start_time', startTimeString);
    //         formData.append('end_time', endTimeString);
    //         formData.append('activity_type', task.activityType);

    //         filesUpload.forEach((file, index) => {
    //             formData.append(`file-${index + 1}`, file, file.name);
    //             fileList.push({ file_name: file.name, url: '' });
    //         });

    //         const remarkModel: RemarkRequest = {
    //             auth: {
    //                 email: accessToken.email,
    //                 provider: 'focusonelink'
    //             },
    //             message: '',
    //             file_list: fileList
    //         };
    //         formData.append('remarks_request', JSON.stringify(remarkModel));

    //         // console.log(formData)

    //         const data = (
    //             await axios.post<CreateTaskResponse>('v1/link/task', formData, {
    //                 headers: {
    //                     Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
    //                     'Content-Type': 'multipart/form-data'
    //                 },
    //                 params: {
    //                     user_id: userCompanyStore.citizenId
    //                 }
    //             })
    //         ).data;

    //         if (data.a_object_link) {
    //             await Swal.fire({
    //                 title: 'Success!',
    //                 text: `Create task success`,
    //                 icon: 'success',
    //                 showCancelButton: false,
    //                 confirmButtonColor: '#3085d6',
    //                 confirmButtonText: 'Ok',
    //                 allowOutsideClick: false
    //             });
    //         }
    //     } catch (error) {
    //         await Swal.fire({
    //             title: 'Error!',
    //             text: (error as any).message,
    //             icon: 'error',
    //             showCancelButton: false,
    //             confirmButtonColor: '#3085d6',
    //             confirmButtonText: 'Yes',
    //             allowOutsideClick: false
    //         });
    //     } finally {
    //         loader.hide();

    //         onClose && onClose();
    //         onRefresh && onRefresh();
    //     }
    // };

    return {
        profileInputRef,
        profileUpload,
        setProfileUpload,
        handleChangeProfile,
        task,
        handleChangeTask,
        taskGroupList,
        selectedTaskGroup,
        setSelectedTaskGroup,
        model,
        setModel,
        fileInputRef,
        filesUpload,
        handleDeleteFile,
        handleChangeFile,
        // handleClickAddTask,
        getNormalFormData
    };
}

export default NormalModeViewModel;
