import { parseResponseError } from '../../../common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from '../../../models';
import { Log, LogRequest } from '../schemas';
import { getAll } from '../datasources/log.datasource';

export async function getLog(
    params: LogRequest
): Promise<DatasourceReturnType<ResponseError, Log[]>> {
    try {
        const data = (await getAll(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
