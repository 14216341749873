import React, { useState, useEffect, useCallback, useDeferredValue } from 'react';
//import axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Marquee from 'react-fast-marquee';
import { useWindowSize } from 'usehooks-ts';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    IconButton,
    useBoolean
} from '@chakra-ui/react';

// Icons
import { MdOutlineMenu } from 'react-icons/md';
import { getCurrentDateTime, parseDateString, parseTimeString } from 'common/helpers';
import { useStore } from 'common/hooks';
import logo from 'images/f1-new-logo.png';
import { TaskStore as TaskStoreType } from 'models/taskStore/Task';

import ApplicationAccordions from './ApplicationAccordions';
import MenuIcons from './TopNavigation/MenuIcons';
import F1Logo from './TopNavigation/F1Logo';
import RightDrawer from './TopNavigation/RightDrawer';

//import { UserInformationResponse } from 'models/citizen/Citizen';

function TopNavigation() {
    const dimensions = useDeferredValue(useWindowSize());

    const [showMenuDrawer, setShowMenuDrawer] = useBoolean();

    const { versionStore, userCompanyStore, companyMasterStore, taskStore, userApplicationStore } =
        useStore();
    const { userName, citizenId, userRole, email } = userCompanyStore;
    const { applicationVersion } = versionStore;
    const taskScheduleList = taskStore.taskScheduleList;

    useEffect(() => {
        fetchVersion();
        getUserInformation('', userName, 'active');
        fetchCompanies();
        fetchTasks();
        fetchUserAppAllData();

        //fetchCitizen();
    }, []);

    const fetchVersion = useCallback(async () => {
        await versionStore.fetchApplicationBuildVersion();
    }, []);

    const getUserInformation = useCallback(
        async (sid: string, citizenId: string, status: string) => {
            await userCompanyStore.fetchUserCompany(sid, citizenId, status);
        },
        []
    );

    const fetchCompanies = async () => {
        await companyMasterStore.fetchCompanyMasterStore('', 'active');
    };

    const fetchTasks = async () => {
        const dateTimeNow = getCurrentDateTime();
        await taskStore.fetchTasksSchedule(dateTimeNow, false);
    };

    const createTaskAnnounce = (task: TaskStoreType) => {
        const startDateTime =
            parseDateString(task.start_date_time.substring(0, 8)) +
            ' ' +
            parseTimeString(task.start_date_time.substring(8, 14));
        const endDateTime =
            parseDateString(task.end_date_time.substring(0, 8)) +
            ' ' +
            parseTimeString(task.end_date_time.substring(8, 14));

        /*return task.subject + ' เริ่มต้น ' + startDateTime + ' สิ้นสุด ' + endDateTime;*/
        return task.subject;
    };

    const fetchUserAppAllData = async () => {
        await userApplicationStore.fetchUserApplications('');
    };

    return (
        <header className="flex">
            {/* Brand */}
            <F1Logo />

            {/* Content */}
            <div className="flex flex-1 items-center justify-between bg-[#1C2F36] px-[15px]">
                <Link
                    to="/"
                    className="flex flex-col gap-x-6 lg:flex-row lg:items-center"
                >
                    <span className="text-[20px] font-bold text-white">
                        focus<span className="text-[#f39267]">O</span>ne ERP
                    </span>

                    <span className="text-[14px] font-bold text-[#E55C00]">
                        version {applicationVersion}
                    </span>
                </Link>

                <div className="mx-10 hidden flex-1 xl:block">
                    <Marquee
                        className="rounded-[5px] border border-neutral-700 py-1"
                        speed={30}
                        gradient={false}
                        pauseOnHover
                    >
                        {(!taskScheduleList || taskScheduleList.length === 0) && (
                            <span className="text-white">-</span>
                        )}

                        {taskScheduleList &&
                            taskScheduleList.length > 0 &&
                            taskScheduleList.map((task, index) => {
                                if (task.type !== 'announcement') {
                                    return <React.Fragment key={index}></React.Fragment>;
                                }

                                return (
                                    <span
                                        key={index}
                                        className="mr-14 text-red-400"
                                    >
                                        {createTaskAnnounce(task)}
                                    </span>
                                );
                            })}
                    </Marquee>
                </div>

                {/* Right icons */}
                {dimensions.width < 1024 ? (
                    <React.Fragment>
                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Main Menu"
                            icon={<MdOutlineMenu size={32} />}
                            onClick={setShowMenuDrawer.on}
                            className="relative left-2 text-white"
                        />
                        <RightDrawer
                            isOpen={showMenuDrawer}
                            appVersionLabel={applicationVersion}
                            onClose={setShowMenuDrawer.off}
                        />
                    </React.Fragment>
                ) : (
                    <MenuIcons />
                )}
            </div>
        </header>
    );
}

export default observer(TopNavigation);
