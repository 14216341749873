import './index.css';

import { createRoot } from 'react-dom/client';

import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
    console.log = () => void 0;
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(<App />);

//registerServiceWorker();
serviceWorker.unregister();
