import { parseResponseError } from 'common/helpers';
import { DatasourceReturnType, ResponseError, ResponseList } from 'models';
import {
    ChangeEmployeePasswordRequest,
    Employee,
    EmployeeBranch,
    EmployeePrefix,
    GetEmployeeBranchParams,
    GetEmployeePrefixParams,
    GetEmployeeRequestParams,
    SaveEmployeeRequest
} from 'models/user/Employee';

import {
    requestCanChangePassword,
    requestChangePassword,
    requestGetEmployee,
    requestGetEmployeeBranch,
    requestGetEmployeePrefix,
    requestSaveEmployee
} from '../datasources/employee.datasource';

export async function getEmployee(
    params: GetEmployeeRequestParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<Employee>>> {
    try {
        const data = (await requestGetEmployee(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getEmployeePrefix(
    params: GetEmployeePrefixParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<EmployeePrefix>>> {
    try {
        const data = (await requestGetEmployeePrefix(params)).data;

        // Fix empty title name
        if (data.total_count > 0) {
            const found = data.result_list.find(prefix => prefix.TitleName === '');
            if (found) found.TitleName = 'ไม่ระบุ';
        }

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function getEmployeeBranch(
    params: GetEmployeeBranchParams
): Promise<DatasourceReturnType<ResponseError, ResponseList<EmployeeBranch>>> {
    try {
        const data = (await requestGetEmployeeBranch(params)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function saveEmployee(
    employee: SaveEmployeeRequest
): Promise<DatasourceReturnType<ResponseError, ResponseList<Employee>>> {
    try {
        const data = (await requestSaveEmployee(employee)).data;

        return [null!, data];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function canChangePassword(): Promise<DatasourceReturnType<ResponseError, void>> {
    try {
        await requestCanChangePassword();

        return [null!, void 0];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}

export async function changePassword(
    request: ChangeEmployeePasswordRequest
): Promise<DatasourceReturnType<ResponseError, void>> {
    try {
        await requestChangePassword(request);

        return [null!, void 0];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
