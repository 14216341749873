import React, { useState } from 'react';
import Swal from 'sweetalert2';

import { reqAddUserCompany } from '../../../models/user/UserCompany';
import { CompanyMaster } from '../../../models/company/Company';
import LoadingScreen from '../../../components/_helpers/LoadingScreen';
import { Input, Button, Label, ModalFooter, ModalBody } from '../../../common/materials';

interface Props {
    openModal: (isOpen: boolean) => void;
    userId: string;
    companyMaster: Array<CompanyMaster>;
}

interface States {
    isLoading: boolean;
    //sid: string,
    textValue: string;
    selectedCompany: Option;
    companyListOption: Array<Option>;
}

interface Option {
    readonly label: string;
    readonly value: string;
}

type ResponseMessage = { message: string };

const initialSelectedCompany: Option = { label: '', value: '' };

class UserCompanyMappingComponent extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            textValue: '',
            // sid: '',
            selectedCompany: initialSelectedCompany,
            companyListOption: this.props.companyMaster.map(
                company =>
                    ({
                        label: company.company_name,
                        value: company.sid
                    }) as Option
            )
        };
    }

    public async callAddUserCompanyMapping() {
        try {
            this.setState({
                isLoading: true
            });

            if (!this.state.textValue) {
                const result = await Swal.fire({
                    title: 'Error!',
                    text: 'sid not found in this company',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) this.props.openModal(false);
            }

            const reqBody: reqAddUserCompany = {
                citizen_id: this.props.userId,
                sid: this.state.textValue
            };

            const response = await fetch(`v2/user/company`, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            });

            const data: ResponseMessage = await response.json();

            if (!response.ok) throw new Error(data.message);

            if (data.message && data.message === 'success') {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });

                if (result.isConfirmed) this.props.openModal(false);
            }
        } catch (e) {
            const result = await Swal.fire({
                title: 'Error!',
                text: (e as any).message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            if (result.isConfirmed) this.props.openModal(false);
        } finally {
            this.setState({
                isLoading: false
            });
        }
    }

    updateInputValue(evt) {
        const val = evt.target.value;
        // ...
        this.setState({
            textValue: val
        });
    }

    render() {
        const headerStyles: React.CSSProperties = {
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        };

        const inputGroupText: React.CSSProperties = {
            minWidth: '101.7px',
            display: 'block',
            textAlign: 'center'
        };

        return (
            <React.Fragment>
                <LoadingScreen loading={this.state.isLoading} />
                {/* temporary fix. should be refactor later. */}
                <ModalBody className="border">
                    <span className="text-md font-semibold">Send Request</span>
                    <div className="flex flex-col gap-1 p-2">
                        <Label>User</Label>
                        <Input
                            disabled
                            value={this.props.userId}
                            className="text-gray-500"
                        />
                        <Label>Company Token</Label>
                        <Input
                            placeholder={'Company Token'}
                            value={this.state.textValue}
                            onChange={evt => this.updateInputValue(evt)}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        size="md"
                        outline
                        className="text-gray-400"
                        onClick={() => this.props.openModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="md"
                        className="cursor-pointer bg-primary-900 text-white"
                        onClick={() => this.callAddUserCompanyMapping()}
                        disabled={this.state.textValue === ''}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

export default UserCompanyMappingComponent;
