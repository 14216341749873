import { DatasourceReturnType, ResponseError } from "models";
import { MasterEmployeeReq } from "../schemas/workflow.shema";
import { putInsertOrUpdateWorkflowUser } from "../datasources/workflow.datasource";
import { parseResponseError } from "common/helpers";

export async function InsertOrUpdateWorkflowUser(body: MasterEmployeeReq):
    Promise<DatasourceReturnType<ResponseError, { message: string }>> {
    try {
        const response = (await putInsertOrUpdateWorkflowUser(body)).data;

        return [null!, response];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
