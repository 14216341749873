import { forwardRef, memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    label: string;
    value: string;
    active?: boolean;
    onClick: (value: string) => void;
};

const Tab = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { label, value, active, onClick } = props;

    const handleClick = useCallback(() => onClick(value), [value, onClick]);

    return (
        <button
            ref={ref}
            onClick={handleClick}
            className={twMerge(
                'rounded-sm px-2 py-1.5 font-medium transition-colors active:bg-neutral-50',
                !active && 'text-stone-300'
            )}
        >
            {label}
        </button>
    );
});

export default memo(Tab);
