import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';

import { UserInformationResponse } from 'models/citizen/Citizen';
import { extractAccessToken } from 'common/helpers/local-storage';

import RootStore from '../Root.store';

class ProfileStore {
    private _userInformation?: UserInformationResponse = undefined;

    public get userInformation() {
        return this._userInformation;
    }

    constructor(private rootStore: RootStore) {
        makeAutoObservable(this);
    }

    public async fetchCitizen() {
        const data = (
            await axios.get<UserInformationResponse>('v1/citizen', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
                },
                params: {
                    sid: 'SSO',
                    user_id: extractAccessToken().citizen_id
                }
            })
        ).data;

        runInAction(() => (this._userInformation = data));

        // For those who's want to access data early as possible
        return data;
    }
}

export default ProfileStore;
