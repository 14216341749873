import React, { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';
import Swal from 'sweetalert2';

import { useLoader } from 'common/hooks';
import {
    addProfileObjectMapping,
    getMainProgram,
    getProfileObjectMapping
} from 'data/role/repositories/role.repository';
import { ResponseError } from 'models';
import { RoleRequest } from 'models/role/RoleRequest';
import {
    ProfileObjectMapping,
    ProfileObjectMappingRequest
} from 'models/role/ProfileObjectMapping';
import { Button, Input, Label } from 'common/materials';
import { RoleProfileMapping } from 'models/role/RoleProfileMapping';
import { MainProgram } from 'models/role/MainProgram';
import { UserCompany } from 'models/user/UserCompany';
import { CompanyMaster } from 'models/company/Company';
import { CompanyPlatformMapping } from 'data/company/schemas';

import MoveInteractionBar from './MoveInteractionBar';
import RoleList from './RoleList';
import RoleObjectMappingModal from './RoleObjectMappingModal';
import DeleteRoleAlertDialog from './DeleteRoleAlertDialog';

export function getRoleLabel(role: RoleProfileMapping) {
    return role.roleid + ((role.rolename && ` - ${role.rolename}`) ?? '');
}

type Props = {
    isOpen: boolean;
    roleProfileMapping: RoleProfileMapping[];
    userRoleProfileMapping: RoleProfileMapping[];
    selectedCompany?: CompanyMaster;
    selectedPlatform?: CompanyPlatformMapping;
    selectedUsers?: UserCompany;
    onClose: () => void;
    onClickSaveRoles: (selectedRoles: RoleProfileMapping[]) => void;
};

function UserRoleMappingModal(props: Props) {
    const {
        isOpen,
        roleProfileMapping,
        userRoleProfileMapping,
        selectedCompany,
        selectedPlatform,
        selectedUsers,
        onClose,
        onClickSaveRoles
    } = props;

    const loader = useLoader();

    // Filter only roles the user did not select.
    const remainingRoleProfileMapping = useMemo(() => {
        // Skip computing if `roleProfileMapping` is empty
        if (roleProfileMapping.length === 0) {
            return [];
        }

        const userRoles = new Set(
            userRoleProfileMapping.map(userRoleProfile => userRoleProfile.roleid)
        );
        return roleProfileMapping.filter(roleProfile => !userRoles.has(roleProfile.roleid));
    }, [userRoleProfileMapping, roleProfileMapping]);

    const [availableRoles, setAvailableRoles] = useState<RoleProfileMapping[]>([]);
    const [userSelectedRoles, setUserSelectedRoles] = useState<RoleProfileMapping[]>([]);

    // Track checked roles
    const [selectedRoles, setSelectedRoles] = useState<Set<RoleProfileMapping>>(new Set());
    const [searchAvailableRoles, setSearchAvailableRoles] = useState('');
    const deferredSearchAvailableRoles = useDeferredValue(searchAvailableRoles);
    // Use this one to display roles instead of pure `availableRoles` state
    const filteredAvailableRoles = useMemo(() => {
        const searchTerm = deferredSearchAvailableRoles.toLowerCase();

        return availableRoles.filter(
            role => selectedRoles.has(role) || getRoleLabel(role).toLowerCase().includes(searchTerm)
        );
    }, [deferredSearchAvailableRoles, availableRoles]);

    // Use for open `DeleteRoleAlertDialog`
    const [selectedDeleteRole, setSelectedDeleteRole] = useState<RoleProfileMapping>();
    const showDeleteRoleAlertDialog = !!selectedDeleteRole;

    // Use for open `RoleObjectMappingModal`
    const [mode, setMode] = useState<'add' | 'edit'>();
    const [selectedRoleDetail, setSelectedRoleDetail] = useState<RoleProfileMapping>();
    const showRoleObjectMappingModal = !!selectedRoleDetail || !!mode;

    // Use in `RoleObjectMappingModal` after click role detail
    const [mainPrograms, setMainPrograms] = useState<MainProgram[]>([]);
    const [profileObjectMappings, setProfileObjectMappings] = useState<ProfileObjectMapping[]>([]);

    // Assign roles into state when modal is open
    useEffect(() => {
        if (!isOpen) {
            return;
        }

        setAvailableRoles(remainingRoleProfileMapping);
        setUserSelectedRoles(userRoleProfileMapping);
        setSelectedRoles(new Set());
    }, [isOpen, remainingRoleProfileMapping, userRoleProfileMapping]);

    // Clear array when `RoleObjectMappingModal` closed (security reason)
    useEffect(() => {
        if (!showRoleObjectMappingModal) {
            setMainPrograms([]);
            setProfileObjectMappings([]);
        }
    }, [showRoleObjectMappingModal]);

    const fetchMainProgram = async (params: RoleRequest) => {
        try {
            loader.show();

            const [error, data] = await getMainProgram(params);
            if (error) {
                throw error;
            }

            setMainPrograms(data.result_list);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    };

    // This should be call after click role detail
    const fetchMainProgramsAndProfiles = async (params: RoleRequest) => {
        try {
            loader.show();

            const [mainProgramResponse, profilesResponse] = await Promise.allSettled([
                getMainProgram(params),
                getProfileObjectMapping(params)
            ]);

            if (
                mainProgramResponse.status === 'rejected' ||
                profilesResponse.status === 'rejected'
            ) {
                throw Error('Cannot fetch main programs.');
            }

            const [mainProgramError, mainProgramData] = mainProgramResponse.value;
            if (mainProgramError) {
                throw mainProgramError;
            }
            setMainPrograms(mainProgramData.result_list);

            const [profilesError, profilesData] = profilesResponse.value;
            if (profilesError) {
                throw profilesError;
            }
            setProfileObjectMappings(profilesData.result_list);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchAvailableRoles(event.target.value);
    }, []);

    const handleSelectRole = useCallback(
        (role: RoleProfileMapping) => {
            if (!selectedRoles.has(role)) {
                selectedRoles.add(role);
            } else {
                selectedRoles.delete(role);
            }

            setSelectedRoles(new Set(selectedRoles));
        },
        [selectedRoles]
    );

    const handleMove = useCallback(
        (to: 'left' | 'right') => {
            const [methodA, methodB] = to === 'left' ? ['add', 'delete'] : ['delete', 'add'];

            const availableRolesSet = new Set(availableRoles);
            const userSelectedRolesSet = new Set(userSelectedRoles);

            selectedRoles.forEach(selectedRole => {
                availableRolesSet[methodA](selectedRole);
                userSelectedRolesSet[methodB](selectedRole);
            });
            selectedRoles.clear();

            setAvailableRoles(sortRolesByLabel(Array.from(availableRolesSet)));
            setUserSelectedRoles(sortRolesByLabel(Array.from(userSelectedRolesSet)));
            setSelectedRoles(new Set(selectedRoles));
        },
        [availableRoles, userSelectedRoles, selectedRoles]
    );

    const handleMoveAll = useCallback(
        (to: 'left' | 'right') => {
            const [setterA, setterB] =
                to === 'left'
                    ? [setUserSelectedRoles, setAvailableRoles]
                    : [setAvailableRoles, setUserSelectedRoles];

            setterA([]);
            setterB(sortRolesByLabel(userSelectedRoles.concat(availableRoles)));
        },
        [availableRoles, userSelectedRoles]
    );

    const handleClickDeleteRole = useCallback((role: RoleProfileMapping) => {
        // Open delete modal (look derived state above)
        setSelectedDeleteRole(role);
    }, []);

    const handleClickAddRole = useCallback(() => {
        setMode('add');

        if (selectedCompany && selectedPlatform) {
            fetchMainProgram({
                sid: selectedCompany.sid,
                platform_id: selectedPlatform.platform_id
            });
        }
    }, [selectedCompany, selectedPlatform]);

    const handleClickRoleDetail = useCallback(
        (role: RoleProfileMapping) => {
            setMode('edit');
            setSelectedRoleDetail(role);

            if (selectedCompany && selectedPlatform) {
                fetchMainProgramsAndProfiles({
                    sid: selectedCompany.sid,
                    platform_id: selectedPlatform.platform_id,
                    profile_id: role.profileid
                });
            }
        },
        [selectedCompany, selectedPlatform]
    );

    const handleCloseDeleteRoleAlertDialog = useCallback(
        (deletedRole?: RoleProfileMapping) => {
            // If submit delete. Get rid of it
            if (deletedRole) {
                setAvailableRoles(
                    sortRolesByLabel(availableRoles.filter(role => role !== selectedDeleteRole))
                );
            }

            setSelectedDeleteRole(undefined);
        },
        [availableRoles]
    );

    const handleCloseRoleObjectMappingModal = useCallback(() => {
        setMode(undefined);
        setSelectedRoleDetail(undefined);
    }, []);

    const handleClickSaveRoles = useCallback(() => {
        onClickSaveRoles(userSelectedRoles);
        onClose();
    }, [userSelectedRoles, onClickSaveRoles, onClose]);

    // This should be call when click save in `RoleObjectMappingModal` component
    const handleClickSaveProfiles = useCallback(
        async (
            objectList: ProfileObjectMappingRequest['object_list'],
            changedRole?: RoleProfileMapping
        ) => {
            try {
                loader.show();

                if (!selectedCompany || !selectedPlatform || !changedRole) {
                    return;
                }

                const [error, data] = await addProfileObjectMapping({
                    sid: selectedCompany.sid,
                    platform_id: selectedPlatform.platform_id,
                    role_id: changedRole?.roleid,
                    profile_id: changedRole?.profileid,
                    role_name: changedRole?.rolename,
                    description: changedRole?.description,
                    object_list: objectList
                });
                if (error) {
                    throw error;
                }

                await Swal.fire('Success!', '', 'success');

                setAvailableRoles(
                    sortRolesByLabel(
                        // If save on edit mode. Find old one, remove it and append new one
                        selectedRoleDetail
                            ? availableRoles
                                  .filter(
                                      role =>
                                          role.roleid !== selectedRoleDetail.roleid &&
                                          role.rolename !== selectedRoleDetail.rolename
                                  )
                                  .concat(data.result_list)
                            : availableRoles.concat(data.result_list)
                    )
                );
                handleCloseRoleObjectMappingModal();
            } catch (error) {
                Swal.fire('Error!', (error as ResponseError).message ?? error, 'error');
            } finally {
                loader.hide();
            }
        },
        [selectedCompany, selectedPlatform, selectedRoleDetail]
    );

    // * PRIVATE
    const sortRolesByLabel = (roles: RoleProfileMapping[]) => {
        return roles.sort((a, b) => {
            const labelA = a.roleid + a.rolename;
            const labelB = b.roleid + b.rolename;

            return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent
                className={twMerge(
                    'max-w-[95dvw] transition-transform',
                    showRoleObjectMappingModal && 'translate-y-16 scale-90'
                )}
            >
                <ModalHeader>
                    Manage <span className="text-primary-900">{selectedUsers?.citizen_id}</span>{' '}
                    role
                </ModalHeader>
                <ModalBody className="flex flex-1 flex-col p-6 pt-0">
                    <div className="flex items-center gap-2">
                        <Label>Find Role : </Label>
                        <Input
                            className="w-[350px]"
                            placeholder="Search ..."
                            value={searchAvailableRoles}
                            onChange={handleChangeSearch}
                        />
                    </div>

                    <p className="mt-6 font-medium text-neutral-600">Select System ERP</p>

                    <div className="mt-3 flex flex-1 gap-4">
                        {/* Available roles */}
                        <RoleList
                            title="Role List"
                            roles={filteredAvailableRoles}
                            selectedRoles={selectedRoles}
                            getRoleLabel={getRoleLabel}
                            onSelectRole={handleSelectRole}
                            onClickAddRole={handleClickAddRole}
                            onClickDeleteRole={handleClickDeleteRole}
                            onClickRoleDetail={handleClickRoleDetail}
                            className="h-[60dvh]"
                        />

                        {/* Interactions */}
                        <MoveInteractionBar
                            onMoveSelectedToRight={useCallback(
                                () => handleMove('right'),
                                [handleMove]
                            )}
                            onMoveSelectedToLeft={useCallback(
                                () => handleMove('left'),
                                [handleMove]
                            )}
                            onMoveToRightAll={useCallback(
                                () => handleMoveAll('right'),
                                [handleMoveAll]
                            )}
                            onMoveToLeftAll={useCallback(
                                () => handleMoveAll('left'),
                                [handleMoveAll]
                            )}
                        />

                        {/* User selected */}
                        <RoleList
                            noAddButton
                            title="Selected Role"
                            roles={userSelectedRoles}
                            selectedRoles={selectedRoles}
                            getRoleLabel={getRoleLabel}
                            onSelectRole={handleSelectRole}
                            onClickRoleDetail={handleClickRoleDetail}
                            className="h-[60dvh]"
                        />
                    </div>

                    <DeleteRoleAlertDialog
                        isOpen={showDeleteRoleAlertDialog}
                        selectedCompany={selectedCompany}
                        selectedPlatform={selectedPlatform}
                        selectedRole={selectedDeleteRole}
                        onClose={handleCloseDeleteRoleAlertDialog}
                    />

                    <RoleObjectMappingModal
                        mode={mode}
                        isOpen={showRoleObjectMappingModal}
                        selectedRole={selectedRoleDetail}
                        mainPrograms={mainPrograms}
                        profileObjectMappings={profileObjectMappings}
                        onClose={handleCloseRoleObjectMappingModal}
                        onClickSave={handleClickSaveProfiles}
                    />
                </ModalBody>
                <ModalFooter className="gap-2">
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        className="bg-primary-900 text-white"
                        onClick={handleClickSaveRoles}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default UserRoleMappingModal;
