import React from 'react';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

import { CreateTaskResponse, CreateTaskRequest } from '../../../models/link/Task';
import LoadingScreen from '../../_helpers/LoadingScreen';
import { Label, Input, Button, TextArea, ModalBody, ModalFooter } from '../../../common/materials';

import './DateTimePicker.css';

interface Props {
    userId: string;
    activityTypeProp?: string;
    openModal: (isOpen: boolean, isReloadWindow: boolean) => void;
    mode?: 'TaskScheduler' | undefined;
    onClose?: () => void;
}

interface States {
    startDateTime: Date;
    endDateTime: Date;
    subject: string;
    activityType: string;
    description: string;
    isLoading: boolean;
}

class CreateTaskComponent extends React.Component<Props, States> {
    constructor(props: Props) {
        super(props);

        this.state = {
            startDateTime: new Date(),
            endDateTime: new Date(),
            subject: '',
            activityType: '',
            description: '',
            isLoading: false
        };
    }

    public async callAddTask() {
        try {
            this.setState({ isLoading: true });

            const { startDateTime, endDateTime } = this.state;

            const options: Intl.DateTimeFormatOptions = {
                timeZone: 'Asia/Bangkok',
                hour12: false,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            };

            // ---- Start Date Time ----
            const startDateTimeFormat = startDateTime.toLocaleString('en-US', options).split(', ');

            let startDate = startDateTimeFormat[0];
            startDate = startDate.replace('/', '').replace('/', '');
            //const year = startDate.substring(4, 8);
            //const month = startDate.substring(0, 2);
            //const date = startDate.substring(2, 4);
            startDate =
                startDate.substring(4, 8) + startDate.substring(0, 2) + startDate.substring(2, 4);

            const startTime = startDateTimeFormat[1]
                .substring(0, 8)
                .replace(':', '')
                .replace(':', '');

            // ---- End Date Time ----
            const endDateTimeFormat = endDateTime.toLocaleString('en-US', options).split(', ');

            let endDate = endDateTimeFormat[0];
            endDate = endDate.replace('/', '').replace('/', '');
            endDate = endDate.substring(4, 8) + endDate.substring(0, 2) + endDate.substring(2, 4);

            const endTime = endDateTimeFormat[1].substring(0, 8).replace(':', '').replace(':', '');

            const formData = new FormData();
            formData.append('subject', this.state.subject);
            formData.append('detail', this.state.description);
            formData.append('start_date', startDate);
            formData.append('end_date', endDate);
            formData.append('start_time', startTime);
            formData.append('end_time', endTime);
            formData.append('activity_type', 'annouce');

            const data = (
                await axios.post<CreateTaskResponse>('v1/link/task', formData, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        user_id: this.props.userId
                    }
                })
            ).data;

            this.setState({ isLoading: false });

            if (data.a_object_link) {
                const result = await Swal.fire({
                    title: 'Success!',
                    text: `Create task success`,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                });
                if (result.isConfirmed) this.props.openModal(false, true);
            }
        } catch (e) {
            console.log(e);

            const result = await Swal.fire({
                title: 'Error!',
                text: (e as any).response.data.message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                allowOutsideClick: false
            });

            if (result.isConfirmed) this.props.openModal(false, true);
        }
    }

    render() {
        const headerStyles: React.CSSProperties = {
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        };

        const inputGroupText: React.CSSProperties = {
            minWidth: '140px',
            display: 'block',
            textAlign: 'center'
        };

        return (
            <React.Fragment>
                <LoadingScreen loading={this.state.isLoading} />
                {/* temporary fix. should be refactor later. */}
                <ModalBody className="flex flex-1 flex-col overflow-y-auto">
                    <span className="font-semibold text-gray-500">Add Information</span>
                    <div className="flex flex-col gap-2 p-2">
                        <Label>Subject</Label>
                        <Input
                            disabled
                            placeholder="subject name"
                            onChange={e => this.setState({ subject: e.target.value })}
                        />
                    </div>
                    <div className="flex gap-2 p-2">
                        <div className="flex-1">
                            <Label>Start Date Time</Label>
                            <DatePicker
                                selected={this.state.startDateTime}
                                onChange={date =>
                                    this.setState({
                                        startDateTime: date!
                                    })
                                }
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                timeCaption="time"
                                dateFormat="dd/MM/yyyy HH:mm"
                                customInput={<Input />}
                            />
                        </div>
                        <div className="flex-1">
                            <Label>End Date Time</Label>
                            <DatePicker
                                selected={this.state.endDateTime}
                                onChange={date =>
                                    this.setState({
                                        endDateTime: date!
                                    })
                                }
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                timeCaption="time"
                                dateFormat="dd/MM/yyyy HH:mm"
                                customInput={<Input />}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 p-2">
                        <Label>Activity Type</Label>
                        <Input
                            disabled={this.props.mode === 'TaskScheduler'}
                            placeholder="announce"
                            onChange={e => this.setState({ activityType: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-1 flex-col gap-2 p-2">
                        <Label>Description</Label>
                        <TextArea
                            className="flex-1"
                            onChange={e => this.setState({ description: e.target.value })}
                        />
                    </div>
                </ModalBody>
                <ModalFooter className="mt-auto flex justify-end gap-4 px-2">
                    <Button
                        size="md"
                        outline
                        className="text-gray-400"
                        onClick={() => this.props.onClose && this.props.onClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="md"
                        className="bg-primary-900 text-white"
                        onClick={() => this.callAddTask()}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </React.Fragment>
        );
    }
}

export default CreateTaskComponent;
