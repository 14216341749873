import axios from 'axios';
import { GetTokenRequest, GetTokenResponse } from '../schemas';

export async function post(body: GetTokenRequest) {
    return axios.post<GetTokenResponse>('/v1/token', body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
