import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import * as PlatformInAppModel from '../../models/group/PlatformInApplication';

interface RequestFunctionAction {
    type: 'REQUEST_PLATFORMINAPP';
    isRegister: boolean;
}

interface ReceiveFunctionAction {
    type: 'RECEIVE_PLATFORMINAPP';
    model: PlatformInAppModel.ResponsePlatformInApplication;
    isRegister: boolean;
    message: string;
}

type KnownAction = RequestFunctionAction | ReceiveFunctionAction

export const actionCreators = {
    requestFunction: (pageload: boolean, appUUID: string, platformId: string, method: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.platformInApplication && pageload == true && method == "GET") {

            fetch(`/v1/` + appUUID + `/sonarPlatforms`, {
                method: 'GET'
            })
                .then(response => {
                    return response.json() as Promise<PlatformInAppModel.ResponsePlatformInApplication>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORMINAPP', model: data, isRegister: false, message: "" });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORMINAPP', isRegister: false });
        }

        if (appState && appState.platformInApplication && pageload == true && method == "POST") {

            const body = {
                'app_uuid': appUUID,
                'platform_id': platformId
            }

            fetch(`/v1/apps/platform/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
                .then(response => {
                    return response.json() as Promise<PlatformInAppModel.ResponsePlatformInApplication>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORMINAPP', model: data, isRegister: true, message: "success" });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORMINAPP', isRegister: true });
        }

        if (appState && appState.platformInApplication && pageload == true && method == "PUT") {
            console.log("PUT");
            fetch(`/v1/apps/platform/accept?uuid=` + appUUID + `&serId=` + platformId, {
                method: 'PUT'
            })
                .then(response => {
                    return response.json() as Promise<PlatformInAppModel.ResponsePlatformInApplication>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORMINAPP', model: data, isRegister: true, message: "success" });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORMINAPP', isRegister: true });
        }

        if (appState && appState.platformInApplication && pageload == true && method == "DELETE") {
            console.log("DELETE");
            fetch(`/v1/apps/platform/reject?uuid=` + appUUID + `&serId=` + platformId, {
                method: 'DELETE'
            })
                .then(response => {
                    return response.json() as Promise<PlatformInAppModel.ResponsePlatformInApplication>
                })
                .then(data => {
                    dispatch({ type: 'RECEIVE_PLATFORMINAPP', model: data, isRegister: true, message: "success" });
                })
                .catch(error => {

                });

            dispatch({ type: 'REQUEST_PLATFORMINAPP', isRegister: true });
        }
    }
};

const unloadedState: PlatformInAppModel.PlatformInApplicationState = { isLoading: false, response: undefined, message: "", isRegister: false };

export const reducer: Reducer<PlatformInAppModel.PlatformInApplicationState> = (state: PlatformInAppModel.PlatformInApplicationState | undefined, incomingAction: Action): PlatformInAppModel.PlatformInApplicationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REQUEST_PLATFORMINAPP':
            return {
                isLoading: false,
                message: "",
                isRegister: action.isRegister
            };
        case 'RECEIVE_PLATFORMINAPP':
            return {
                isLoading: true,
                response: action.model,
                message: action.message,
                isRegister: action.isRegister
            };

        default: return state;
    }
};