import React, { useMemo } from 'react';

import { Button } from 'common/materials';
import { UserInformationResponse } from 'models/citizen/Citizen';

import { useWindowSize } from 'usehooks-ts';
import { twMerge } from 'tailwind-merge';
import { ImArrowDown, ImArrowRight } from 'react-icons/im';
import { Step1, Step2, Step3, Step4 } from 'common/components/LineOnboarding';

type Props = {
    userInformation: UserInformationResponse;
    copyUserInformation: UserInformationResponse;
    onChangeOtpOrNotificationType: (type: 'otp' | 'notify', value: 'email' | 'line') => void;
};

function OTPType(props: Props) {
    const { userInformation, onChangeOtpOrNotificationType } = props;

    const { width } = useWindowSize();
    const sm = 640;

    const stepContainerTwClasses =
        'relative flex flex-col border border-neutral-200 rounded-md p-4 max-w-md';
    const stepBaseTwClasses =
        'absolute top-[calc(100%_+_2px)] left-1/2 -translate-x-1/2 translate-y-0';
    const stepEvenTwClasses =
        'sm:hidden 2xl:block 2xl:top-1/2 2xl:translate-x-0 2xl:-translate-y-1/2 2xl:left-[calc(100%_+_6px)]';
    const stepOddTwClasses =
        'sm:top-1/2 sm:translate-x-0 sm:-translate-y-1/2 sm:left-[calc(100%_+_6px)]';

    const ArrowIcon = width > sm ? ImArrowRight : ImArrowDown;

    const { sign_type, setting_list } = userInformation;

    const settingListMap = new Map(setting_list?.map(setting => [setting.key, setting]));

    const signTypeRadioButtons = useMemo(
        () => [
            {
                ref: React.createRef<HTMLInputElement>(),
                label: 'OTP ไปยัง E-mail',
                defaultChecked: settingListMap.get('otp')?.value === 'email',
                value: 'email'
            },
            {
                ref: React.createRef<HTMLInputElement>(),
                label: 'OTP ไปยัง Appication Line',
                defaultChecked: settingListMap.get('otp')?.value === 'line',
                value: 'line'
            }
        ],
        [setting_list]
    );

    const notifyRadioButtons = useMemo(
        () => [
            {
                ref: React.createRef<HTMLInputElement>(),
                label: 'Notification ไปยัง E-mail',
                defaultChecked: settingListMap.get('notify')?.value === 'email',
                value: 'email'
            },
            {
                ref: React.createRef<HTMLInputElement>(),
                label: 'Notification ไปยัง Appication Line',
                defaultChecked: settingListMap.get('notify')?.value === 'line',
                value: 'line'
            }
        ],
        [setting_list]
    );

    return (
        <React.Fragment>
            <p className="text-lg font-medium">ประเภทการรับ OTP</p>
            <hr />

            <div className="flex flex-col">
                <p>ขั้นตอนการขอ OTP ผ่าน Line</p>

                <div className="grid grid-cols-1 gap-10 sm:grid-cols-1 2xl:grid-cols-1">
                    <div className={stepContainerTwClasses}>
                        <Step1 />

                        {/*<ArrowIcon*/}
                        {/*    size={32}*/}
                        {/*    color="#F7980A"*/}
                        {/*    className={twMerge(stepBaseTwClasses, stepOddTwClasses)}*/}
                        {/*/>*/}
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step2 />

                        {/*<ArrowIcon*/}
                        {/*    size={32}*/}
                        {/*    color="#F7980A"*/}
                        {/*    className={twMerge(stepBaseTwClasses, stepEvenTwClasses)}*/}
                        {/*/>*/}
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step3 />

                        {/*<ArrowIcon*/}
                        {/*    size={32}*/}
                        {/*    color="#F7980A"*/}
                        {/*    className={twMerge(stepBaseTwClasses, stepOddTwClasses)}*/}
                        {/*/>*/}
                    </div>
                    <div className={stepContainerTwClasses}>
                        <Step4 />
                    </div>
                </div>

                <p className="mt-2">เลือกประเภทที่จะขอ OTP</p>
                <div className="px-4">
                    {signTypeRadioButtons.map(button => (
                        <div
                            key={button.label}
                            className="flex gap-2"
                        >
                            <input
                                type="radio"
                                name="otp_type"
                                defaultChecked={button.defaultChecked}
                                value={button.value}
                                onChange={() =>
                                    onChangeOtpOrNotificationType(
                                        'otp',
                                        button.value as 'line' | 'email'
                                    )
                                }
                            />
                            <p>{button.label}</p>
                        </div>
                    ))}
                </div>

                <p className="mt-2">เลือกประเภทที่จะขอ Notification</p>
                <div className="px-4">
                    {notifyRadioButtons.map(button => (
                        <div
                            key={button.label}
                            className="flex gap-2"
                        >
                            <input
                                type="radio"
                                name="notify_type"
                                defaultChecked={button.defaultChecked}
                                value={button.value}
                                onChange={() =>
                                    onChangeOtpOrNotificationType(
                                        'notify',
                                        button.value as 'line' | 'email'
                                    )
                                }
                            />
                            <p>{button.label}</p>
                        </div>
                    ))}
                </div>

                {/*<p className="mt-2">1. เพิ่มเพื่อน Line FocusOne</p>*/}
                {/*<div className="px-4">*/}
                {/*    <img*/}
                {/*        src="https://onlineform.focusonesoftware.com/images/qr-code-add-friend.png"*/}
                {/*        alt="LINE-QR"*/}
                {/*        className="h-[150px] w-[150px]"*/}
                {/*    />*/}
                {/*    <p>*/}
                {/*        Add Line FocusOne Official Account*/}
                {/*        <br />*/}
                {/*        เพื่อรับ Notification หรือ*/}
                {/*        <br />*/}
                {/*        เพิ่มเพื่อนปุ่มด้านขวา{' '}*/}
                {/*        <span className="rounded-sm bg-[#00C854] px-4 py-1 text-white">*/}
                {/*            เพิ่มเพื่อน*/}
                {/*        </span>*/}
                {/*    </p>*/}
                {/*</div>*/}

                {/*<p className="mt-2">2. ผูก ID line กับ Online Form</p>*/}
                {/*<div className="px-4">*/}
                {/*    <Button*/}
                {/*        outline*/}
                {/*        className="w-fit bg-[#00C854] text-white"*/}
                {/*    >*/}
                {/*        ผูก ID Line*/}
                {/*    </Button>*/}
                {/*</div>*/}

                {/*<p className="mt-2">3. เลือกประเภทที่จะขอ OTP</p>*/}
                {/*<div className="px-4">*/}
                {/*    {signTypeRadioButtons.map(button => (*/}
                {/*        <div*/}
                {/*            key={button.label}*/}
                {/*            className="flex gap-2"*/}
                {/*        >*/}
                {/*            <input*/}
                {/*                type="radio"*/}
                {/*                name="otp_type"*/}
                {/*                defaultChecked={button.defaultChecked}*/}
                {/*                value={button.value}*/}
                {/*            />*/}
                {/*            <p>{button.label}</p>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </React.Fragment>
    );
}

export default OTPType;
