export const FULL_MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const MONTHS = FULL_MONTHS.map(months => months.substring(0, 3));

export function formatDateToDateTimeDisplay(rawDate: string) {
    const dateDisplay = formatDate2DateDisplay(rawDate);
    const timeDisplay = formatDate2TimeDisplay(rawDate);

    return `${dateDisplay}, ${timeDisplay}`;
}

export function formatDate2DateDisplay(rawDate: string) {
    if (rawDate.length < 8) {
        return '';
    }

    const year = rawDate.substring(0, 4);
    const month = MONTHS[Number(rawDate.substring(4, 6)) - 1] ?? '';
    const date = rawDate.substring(6, 8);

    return `${date} ${month} ${year}`;
}

export function formatDate2TimeDisplay(rawDate: string) {
    if (rawDate.length > 14 || rawDate.length < 14) {
        return '';
    }

    const hours = rawDate.substring(8, 10);
    const minutes = rawDate.substring(10, 12);

    return `${hours}:${minutes} น.`;
}
