import React, { memo } from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay
} from '@chakra-ui/react';

import MenuIcons from './MenuIcons';
import ApplicationAccordions from '../ApplicationAccordions';

type Props = {
    isOpen: boolean;
    appVersionLabel?: string;
    onClose: () => void;
};

function RightDrawer(props: Props) {
    const { isOpen, appVersionLabel, onClose } = props;

    return (
        <Drawer
            size="md"
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton className="text-white" />
                <DrawerHeader className="bg-[#1C2F36] py-0">
                    <div className="flex items-center gap-4">
                        <span className="text-xl font-bold text-white">MENU</span>
                        <div className="h-8 w-[2px] bg-white" />
                        <div className="flex flex-col">
                            <span className="text-[20px] font-bold text-white">
                                focus<span className="text-[#f39267]">O</span>ne ERP
                            </span>
                            <span className="text-[14px] font-bold text-[#E55C00]">
                                version {appVersionLabel}
                            </span>
                        </div>
                    </div>
                </DrawerHeader>
                <DrawerBody className="p-0">
                    <div className="bg-[#1C2F36] px-6">
                        <MenuIcons />
                    </div>
                    <ApplicationAccordions onClickApplication={onClose} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

export default memo(RightDrawer);
