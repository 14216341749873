import axios from 'axios';
import {
    GetTaskSchedulResponse,
    GetTaskScheduleRequest,
    TaskActivity,
    TaskActivityRequest
} from '../schemas';
import { ResponseList } from '../../../models/common/Response';
import { TaskInbox } from '../../../models/link/Task';
import { Task } from '../../../models/link/Link';

export async function getAll(params: GetTaskScheduleRequest) {
    return axios.get<GetTaskSchedulResponse>('/v2/link/task', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}

export async function requestTaskActivity(params: TaskActivityRequest) {
    return axios.get<TaskActivity>('v1/link/task-activity', { params });
}

export async function reqestTaskInbox(filter: string) {
    return axios.post<ResponseList<TaskInbox>>('v1/link/task-inbox', null, {
        params: {
            filter
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'application/json'
        }
    });
}

export async function requestLinkTask(email: string) {
    return axios.get<Task[]>('/v1/task', {
        params: { email },
        headers: { Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH') }
    });
}

export async function requestCloseTask(object_link: string) {
    return axios.post<{ message: string }>('v1/link/close-task', null, {
        params: {
            object_link
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'application/json'
        }
    });
}

export async function requestCancelTask(object_link: string) {
    return axios.post<{ message: string }>('v1/link/cancel-task', null, {
        params: {
            object_link
        },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH'),
            'Content-Type': 'application/json'
        }
    });
}
