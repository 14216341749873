import { useLoader } from 'common/hooks';
import React, { forwardRef } from 'react';

export type WithLoaderProps = {
    loader: ReturnType<typeof useLoader>;
};

export default function withLoader<P extends {}>(Component: React.ComponentType<P>) {
    return forwardRef((props: any, ref: any) => {
        const loader = useLoader();

        return (
            <Component
                ref={ref}
                {...props}
                loader={loader}
            />
        );
    });
}
