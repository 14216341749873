import React, { forwardRef, useImperativeHandle } from 'react';

import { TaskStore } from '../../models/taskStore/Task';

import useReleaseTaskViewModel from './ReleaseTask.viewmodel';

export type ReleaseTaskHandle = {
    handleClickReleaseTask: () => Promise<void>;
};

export type ReleaseTaskViewProps = {
    onClose?: () => void;
    onRefresh?: () => void;
    selectedTask?: TaskStore;
};

const ReleaseTaskView = forwardRef<ReleaseTaskHandle, ReleaseTaskViewProps>((props, ref) => {
    const { handleClickReleaseTask } = useReleaseTaskViewModel(props);
    const { selectedTask } = props;

    useImperativeHandle(ref, () => {
        return {
            handleClickReleaseTask
        };
    });

    return (
        <React.Fragment>
            {selectedTask && (
                <p>
                    หากต้องการ{' '}
                    <span className="text-black underline">
                        {' '}
                        {selectedTask.is_release ? 'Unreleased' : 'Release'}
                    </span>{' '}
                    <span className="font-bold text-orange-500">{selectedTask.subject}</span>{' '}
                    กรุณากด Confirm
                </p>
            )}
        </React.Fragment>
    );
});

export default ReleaseTaskView;
