import dayjs from 'dayjs';
import { memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { TbCalendarEvent } from 'react-icons/tb';

import { Task } from 'models/link/Link';

type Props = {
    ticket: Task;
    onClickTicketDetail: (task: Task) => void;
};

function TicketCard(props: Props) {
    const { ticket, onClickTicketDetail } = props;

    const displayCheckInDate = useMemo(
        () =>
            dayjs(ticket.checkIn_Date + ticket.checkIn_Date, 'YYYYMMDDHHmmss').format(
                'DD/MM/MMMM, HH:mm น.'
            ),
        [ticket]
    );

    return (
        <div className="flex flex-col overflow-hidden rounded border px-4 py-4 shadow-lg">
            <p className="rounded-full border border-[#979797] px-2">
                Work Group : {ticket.projectcategory}
            </p>

            <div className="mt-2.5 border-b" />

            <div className="mt-2.5 flex flex-1 flex-col gap-2.5">
                <p>
                    <span className="font-semibold">Ticket No. : </span>
                    {ticket.ticketNO}
                </p>

                <p>
                    <span className="font-semibold">Task Name : </span>
                    {ticket.jobdescription}
                </p>

                {ticket.ticketDetail && (
                    <p>
                        <span className="font-semibold">Task Detail : </span>
                        {ticket.ticketDetail}
                    </p>
                )}

                <p>
                    <span className="font-semibold">Progress : </span>
                    <span
                        className={twMerge(
                            'rounded-full bg-zinc-300 px-4 py-0.5 font-bold',
                            ticket.activityOverviewStatus === 'ถูกยกเลิก'
                                ? 'border-red-600 bg-red-50 text-red-600'
                                : ticket.activityOverviewStatus === 'ปิดงานแล้ว'
                                ? 'border-green-600 bg-green-50 text-green-600'
                                : ticket.activityOverviewStatus === 'กำลังดำเนินการ'
                                ? 'border-blue-600 bg-blue-50 text-blue-600'
                                : ''
                        )}
                    >
                        {ticket.taskStatusDesc}
                    </span>
                </p>

                <p>
                    <span className="font-semibold">Creator : </span>
                    {ticket.creator}
                </p>

                <div className="mt-auto border-b" />

                <div className="flex flex-wrap items-center gap-2 text-[#828282]">
                    <TbCalendarEvent size={20} />
                    <span className="relative top-[1px]">{displayCheckInDate}</span>

                    <span
                        onClick={() => onClickTicketDetail(ticket)}
                        className="ml-auto cursor-pointer text-cyan-500 underline"
                    >
                        TICKET DETAILS
                    </span>
                </div>
            </div>
        </div>
    );
}

export default memo(TicketCard);
