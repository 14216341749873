import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Stores from '../../store/group/PlatformManage';
import * as Models from '../../models/group/Platform';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Swal from 'sweetalert2';

type Store = Models.PlatformState & typeof Stores.actionCreators;

class ModalConfirmDeletePlatformComponent extends React.PureComponent<any, { isOpen: boolean, serId: string, user: string, pass: string, isSave: boolean, classSpinner: string }, Store> {

    constructor(props: any) {
        super(props);

        this.state = {
            isOpen: false,
            serId: "",
            user: "",
            pass: "",
            isSave: false,
            classSpinner: "d-none"
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.SaveClick = this.SaveClick.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
    }

    private toggleModal() {
        this.setState({
            isOpen: !this.state.isOpen,
            serId: this.props.platform_id
        });
    }

    private handleChangeUser(event: any) {
        this.setState({ user: event.target.value });
    }

    private handleChangePass(event: any) {
        this.setState({ pass: event.target.value });
    }

    private SaveClick() {
        if (this.state.user != "sonarinno" || this.state.pass != "123456") {
            Swal.fire(
                'Error',
                'Username or Password incorrect.',
                'error'
            )

            return;
        }

        this.setState({
            isSave: true,
            classSpinner: "d-inline-block"
        });

        this.props.requestFunction(this.state.serId, "", "", "DELETE");
    }

    render() {

        if (this.props.isOpenModalDelete) {
            this.props.afterOpenModalDelete();

            this.toggleModal();
        }

        if (this.props.isLoading && this.state.isSave) {
            this.props.BindData();

            this.toggleModal();
            this.setState({
                isSave: false
            });
        }

        return (
            <div>
                <Modal isOpen={this.state.isOpen} >
                    <ModalHeader toggle={this.toggleModal}>Delete Platform <span style={{ color: "red" }}>{this.props.platformSelected.platform_name}</span></ModalHeader>
                    <ModalBody>
                        <label htmlFor="txtUser"><b>User Name</b></label>
                        <input type="text" id="txtUser" className="form-control" value={this.state.user} onChange={this.handleChangeUser}></input>
                        <label htmlFor="txtPass"><b>Password</b></label>
                        <input type="text" id="txtPass" className="form-control" value={this.state.pass} onChange={this.handleChangePass}></input>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.SaveClick} >
                            <Spinner className={this.state.classSpinner} style={{ width: '20px', height: '20px' }} />
                            {' '}Delete
                            </Button>{' '}
                        <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default connect((state: ApplicationState) => state.platform,
    Stores.actionCreators
)(ModalConfirmDeletePlatformComponent);
