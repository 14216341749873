import React from 'react';
import { twMerge } from 'tailwind-merge';

import { sizeTwClasses } from './constant';

type OwnProps = {
    outline?: boolean;
    size?: MaterialSize;
};

type Props = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> &
    OwnProps;

function Button(props: Props) {
    const { size = 'sm', outline, type = 'button', className, children, ...buttonProps } = props;

    const buttonClasses = !outline
        ? 'bg-neutral-100'
        : 'bg-transparent border border-neutral-300 disabled:bg-gray-50';

    return (
        <button
            {...buttonProps}
            type={type}
            className={twMerge(
                'inline-flex items-center justify-center rounded-[4px] px-8',
                buttonClasses,
                sizeTwClasses.get(size) ?? '',
                className
            )}
        >
            {children}
        </button>
    );
}

export default Button;
