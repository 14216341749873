import axios from 'axios';

import { RoleRequest } from 'models/role/RoleRequest';
import { ProfileObjectMappingRequest } from 'models/role/ProfileObjectMapping';
import {
    DeleteRoleProfileMappingRequestParams,
    GetUserProfileMappingRequestParams
} from 'models/role/RoleProfileMapping';

import {
    MainProgramResponse,
    RoleProfileMappingResponse,
    UserResponse,
    UserProfileMappingResponse,
    ProfileObjectMappingResponse,
    SaveUserProfileMappingRequest
} from '../schemas';

export async function getMainProgramFromRemote(params: RoleRequest) {
    return axios.get<MainProgramResponse>('/v1/role/main_program', {
        params
    });
}

export async function getRoleProfileMappingFromRemote(params: RoleRequest) {
    return axios.get<RoleProfileMappingResponse>('/v1/role/role_profile_mapping', {
        params
    });
}

export async function getUserFromRemote(params: RoleRequest) {
    return axios.get<UserResponse>('/v1/role/user', {
        params
    });
}

export async function getUserProfileMappingFromRemote(params: GetUserProfileMappingRequestParams) {
    return axios.get<UserProfileMappingResponse>('/v1/role/user_profile_mapping', {
        params
    });
}

export async function getProfileObjectMappingFromRemote(params: RoleRequest) {
    return axios.get<ProfileObjectMappingResponse>('/v1/role/profile_object_mapping', {
        params
    });
}

export async function addRoleProfileMappingFromRemote(body: SaveUserProfileMappingRequest) {
    return axios.post('/v1/role/add_role_profile', body);
}

export async function addProfileObjectMappingFromRemote(body: ProfileObjectMappingRequest) {
    return axios.post<RoleProfileMappingResponse>('/v1/role/profile_object_mapping', body);
}

export async function deleteRoleProfileMappingFromRemote(
    params: DeleteRoleProfileMappingRequestParams
) {
    return axios.delete<void>('/v1/role/role_profile_mapping', {
        params
    });
}
