import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Button, ModalBody } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter } from '@chakra-ui/react';
import Swal from 'sweetalert2';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onClickChooseFile: (file: File) => void;
};

const Camera = (props: Props) => {
    const { onClose, isOpen, onClickChooseFile } = props;
    const videoRef = useRef<HTMLVideoElement>(null);
    const streamRef = useRef<MediaStream>();

    const [snapImage, setSnapImage] = useState<string>();

    useEffect(() => {
        if (!isOpen) {
            streamRef.current?.getTracks().forEach(track => track.stop());
            setSnapImage(undefined);

            return;
        }

        setTimeout(() => getVideoFromCamera(), 0);
    }, [isOpen]);

    const getVideoFromCamera = async () => {
        const video = videoRef.current;
        if (!video) {
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { width: 450, height: 240 }
            });
            streamRef.current = stream;
            video.srcObject = stream;
            video.play();
        } catch (error) {
            Swal.fire('Cannot open camera!', 'Please check your device.');
        }
    };

    const takePhoto = useCallback(() => {
        const width = 300;
        const height = width / (16 / 9);

        const video = videoRef.current;
        if (!video) {
            return;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
            return;
        }

        context.drawImage(video, 0, 0, width, height);
        canvas.toBlob(blob => {
            if (!blob) {
                return;
            }

            const file = new File([blob], 'profile-image.jpg', { type: 'image/jpeg' });
            const url = URL.createObjectURL(file);

            setSnapImage(url);
        });
    }, []);

    const handleClickSnap = useCallback(async () => {
        if (!snapImage) {
            return;
        }

        const response = await fetch(snapImage);
        const blob = await response.blob();
        const file = new File([blob], 'profile-image.jpg', { type: 'image/jpeg' });

        onClickChooseFile(file);
    }, [snapImage, onClickChooseFile]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader className="text-center">Camera</ModalHeader>
                <ModalBody className="flex flex-col items-center">
                    <video
                        ref={videoRef}
                        className="rounded-md"
                    />
                    <Button
                        size="md"
                        colorScheme="red"
                        onClick={takePhoto}
                        className="mt-2 rounded-full"
                    >
                        Snap
                    </Button>

                    {snapImage && (
                        <div className="mt-6 flex flex-col gap-2">
                            <span className="text-center text-lg font-bold">Result</span>
                            <img
                                className="rounded-md"
                                src={snapImage}
                            />
                        </div>
                    )}
                </ModalBody>
                <ModalFooter className="gap-2">
                    {snapImage && (
                        <Button
                            onClick={handleClickSnap}
                            className="bg-primary-900 text-white"
                        >
                            Choose
                        </Button>
                    )}
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default Camera;
